import React, { useEffect, useState } from "react";
import Life from "./Life";
import High from "./High";
import Self from "./Self";
import Design from "./Design";
import { db } from "../../../firebase";
import { doc, getDoc } from "firebase/firestore";
import HomeEvent from "./HomeEvent";

function Events() {
  const [data, setData] = useState(null);
  const getMissions = async (docId) => {
    try {
      const docRef = doc(db, "HomePage", docId);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        setData(docSnapshot.data());
        // console.log(data, "akakkak")
        // console.log(docSnapshot.data() , "kkkkk");
      } else {
        console.log("Document not found");
      }
    } catch (error) {
      console.log("Error getting document:", error);
    }
  };

  useEffect(() => {
    getMissions("Events");
  }, []);


  return (
    <div>
      <article className="min-h-screen flex items-center flex-col md:px-[2%] sm:py-5 ">
        {data && data.events && data.events.length ? (
          <div>
            {data.events.map((data, index) => {
              return <HomeEvent key={index} data={data} />;
            })}
          </div>
        ) : null}
        
      </article>
    </div>
  );
}

export default Events;
