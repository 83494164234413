import React, { useState, useEffect } from "react";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  setDoc,
} from "firebase/firestore";
import { Button, Input, Textarea } from "@material-tailwind/react";
import { app, db, storage } from "../../firebase";
import { v4 as uuidv4 } from "uuid";
import { AiOutlineDelete } from "react-icons/ai";
import { uploadBytes, ref } from "firebase/storage";

import PeopleCard from "../HomePage/WhatPeopleSay/PeopleCard";
import { uploader } from "./test";

function TestimonialEdit() {
  const [testimonials, setTestimonials] = useState([]);
  const [name, setName] = useState();
  const [profession, setProfession] = useState();
  const [testimonial, setTestimonial] = useState();
  const [image, setImage] = useState();
  const [editState, setEditState] = useState(false);
  const [reload, setReload] = useState(false);
  const [url, setUrl] = useState("");
  const storageRef = ref(storage, "imagehere");
  const [progress, setProgress] = useState(0);

  const getTestimonials = async () => {
    const querySnapshot = await getDocs(collection(db, "Testimonials"));
    const array = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      array.push(data);
      console.log(doc.data(), "doc");
    });
    setTestimonials(array);
  };

  useEffect(() => {
    getTestimonials();
  }, [reload]);

  const deleteTestimonial = async (id) => {
    await deleteDoc(doc(db, "Testimonials", id));
    setReload((prev) => !prev);
  };

  const handleSubmit = async (data) => {
    const id = uuidv4();
    // const docRef = doc(db, "ytLinks");
    const docRef = doc(db, "Testimonials", id);
    const docData = {
      name: name,
      profession: profession,
      testimonial: testimonial,
      image: url,
      id: id,
    };
    console.log(docData, "doc");
    await setDoc(docRef, docData, { merge: true })
      .then((doc) => {
        setReload((prev) => !prev);
        setName("");
        setProfession("");
        setTestimonial("");
        console.log(doc, "update");
      })
      .catch((error) => {
        console.log(error);
      });

    console.log("Document written with ID: ", docRef.id);
  };

  return (
    <div className="w-full h-full bg-gray-200 flex flex-col py-20 sm:py-32 items-center  gap-4">
      <div className="border p-6 sm:p-8  border-primary">
        <form className="flex flex-col justify-center items-center gap-2 ">
          <div className="font-semibold text-xl flex justify-center items-center text-center">
            Add Testimonial Card
          </div>

          <Input
            size="sm"
            label="Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            name="name"
          />
          <Input
            size="sm"
            label="Profession"
            value={profession}
            onChange={(e) => {
              setProfession(e.target.value);
            }}
            name="profession"
          />
          <Textarea
            size="lg"
            label="Testimonial"
            value={testimonial}
            onChange={(e) => {
              setTestimonial(e.target.value);
            }}
            name="testimonial/"
          />
          <Input
            size="lg"
            label="Image"
            type="file"
            onChange={(e) => {
              const images = "testimonial/";
              if (e.target.files[0])
                uploader(e.target.files[0], setUrl, setProgress, images);
            }}
            name="image"
          />
          <div>Image Uploaded {progress} %</div>

          <Button
            typ="submit"
            onClick={(e) => {
              e.preventDefault();
              console.log(name, profession, testimonial, image);
              handleSubmit({
                name: name,
                profession: profession,
                testimonial: testimonial,
                image: image,
              });
            }}
          >
            Submit
          </Button>
        </form>
      </div>
      <div className="flex flex-col  justify-center items-center sm:grid sm:grid-cols-2 sm:place-items-center   lg:grid-cols-3 gap-8 w-full">
        {testimonials &&
          testimonials.length > 0 &&
          testimonials.map((data) => {
            console.log(data);
            return (
              <div
                className=" flex flex-col items-center w-full "
                key={data._id}
              >
                {editState === data._id ? (
                  <div></div>
                ) : (
                  <PeopleCard
                    testimonial={data.testimonial}
                    name={data.name}
                    profession={data.profession}
                    img={data.image}
                  />
                )}
                <div
                  className="flex items-center gap-2 border cursor-pointer "
                  onClick={() => deleteTestimonial(data.id)}
                >
                  <AiOutlineDelete size={32}></AiOutlineDelete>
                  Delete Card
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default TestimonialEdit;
