import ReachOut from "../../Components/Footer/ReachOut";
import Links from "../../Components/Footer/Links";
import Foot from "../../Components/Footer/Foot";
import DeepSleepTop from "../../Components/programs/DeepSleep/DeepSleepTop";
import DeepSleepVideo from "../../Components/programs/DeepSleep/DeepSleepVideo";
import DeepSleepDesc from "../../Components/programs/DeepSleep/DeepSleepDesc";
import DeepSleepExplore from "../../Components/programs/DeepSleep/DeepSleepExplore";
// import "./deepsleep.css"

const deepSleep =() =>{
    return (
        
<html lang="en">
  
  <body>
   
    <div class="wrapper">
      
      <DeepSleepTop />

      <DeepSleepVideo />

      <DeepSleepDesc />
      
      <DeepSleepExplore />
      
      <div>
          <ReachOut />
          <Links />
          <Foot />
          </div>
      
    </div>
    
   
    {/* <script>
      $(document).ready(function () {
        $(".menu-icon").on("click", function () {
          $("nav ul").toggleClass("showing");
        });
      });

      $(window).on("scroll", function () {
        if ($(window).scrollTop()) {
          $("nav").addClass("black");
        } else {
          $("nav").removeClass("black");
        }
      });
    </script> */}
  </body>
</html>

    )
};

export default deepSleep;