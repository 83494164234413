import React, { useState } from "react";
import ReactPlayer from "react-player";
import VedioPlayer from "../Common/VedioPlayer";

function ViedoPreview({ url }) {
  const [preview, setPreview] = useState(false);
  return (
    <div className="items-center flex text-center justify-center p-1 text-primary cursor-pointer">
      {preview === true ? (
        <div>
          <VedioPlayer url={url}></VedioPlayer>
        </div>
      ) : (
        <div
          className="items-center flex text-center justify-center p-1 text-primary cursor-pointer"
          onClick={() => {
            setPreview(true);
          }}
        >
          Click To Preview
        </div>
      )}
    </div>
  );
}

export default ViedoPreview;
