import React from 'react'

function ContactUs() {
  return (
    <div className='pt-14 flex flex-col gap-4'>
    
      
        <div class="h-[50vh]  lg:h-[70vh]  flex flex-col items-center justify-end bg-center bg-cover bg-image-3">
          
        </div>
      
    <h1 className="flex justify-center  font-poppins md:text-5xl">Contact Us</h1>
    
    
    
    <div className='text-base lg:text-lg text-center    px-[8%] lg:px-[15%] pb-5 font-poppins '>
    Unlock your potential and achieve greatness with me Bodhie Vik. Whether you
    have questions, ideas, testimonials, or feedback to share, don't hesitate to
    reach out. There's always room for improvement, no matter where you are in
    life. Join our life-changing programs, retreats, and tours to be part of a
    supportive community that propels you toward transformative growth.
    Connect with me today and embark on a journey of personal empowerment
    and fulfilment.</div>
    </div>
    
  )
}

export default ContactUs