import React from "react";
import { useNavigate } from "react-router-dom";

function Bottom() {
  const navigate = useNavigate();
  return (
    <article
      class="flex text-white relative flex-col container mt-20 bg-no-repeat bg-cover bg-bottom md:rounded-3xl px-2 py-10 mb-10 md:p-20 bg-image-1"
      // style="background-image: url(assets/events/events-bg.png);"
    >
      <div class="z-[1]">
        <h2 class="text-xl md:text-3xl">Join us at</h2>
        <h4 class="text-4xl md:text-7xl">Live Your Destiny 2022</h4>
      </div>
      <div class="flex flex-row z-[1] mt-5">
        <div class="flex-1">
          <label class="time-label">APRIL 19 - 22, 2022</label>
        </div>
        <button
          className="  bg-white text-blue-700 text-base sm:text-xl  font-bold rounded-full px-4 py-2 shadow-none hover:shadow-2xl shadow-white   "
          onClick={() => {
            navigate("/form/live-your-destiny");
          }}
        >
          Apply Now
        </button>
      </div>
    </article>
  );
}

export default Bottom;
