import React, { useEffect, useState } from "react";
import { uploader } from "../test";
import { Button, Input } from "@material-tailwind/react";
import { db } from "../../../firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { getDocs, collection } from "firebase/firestore";
import { notify } from "../../Common/Notification";

function EditMyStoryMission() {
  const [data, setData] = useState();
  const [missions, setMissions] = useState([]);
  const [progress, setProgress] = useState();
  const [image, setImage] = useState();
  const [mainHeading, setMainHeading] = useState();
  const [heading, setHeading] = useState();
  const [content, setContent] = useState();
  const [index, setIndex] = useState();
  const [reload, setReload] = useState(false);

  const getMissions = async () => {
    const docRef = doc(db, "MyStoryPage", "Mission");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      if (docSnap.data().missions) setMissions(docSnap.data().missions);
    } else {
      console.log("No such document!");
    }
  };
  useEffect(() => {
    getMissions();
  }, [reload]);

  const handleSubmit = async (data) => {
    const docRef = doc(db, "MyStoryPage", "Mission");
    const docData = {
      missions: missions,
    };
    await setDoc(docRef, docData, { merge: true })
      .then((doc) => {
        setReload((prev) => !prev);
        notify({ type: "success", message: "success" });
      })
      .catch((error) => {
        console.error(error);
      });
    console.log("Document Written with ID : ", docRef.id);
  };

  useEffect(() => {
    getMissions();
  }, [reload]);

  return (
    <div className="h-full bg-gray-200 flex flex-col py-20 sm:py-32 items-center  gap-4 w-full">
      <div className=" flex flex-col gap-4 items-center justify-center w-full px-10  max-w-md ">
        <div className="flex flex-col gap-4  items-center justify-center  p-2 w-full">
          <Input
            size="lg"
            label="Main Heading"
            value={mainHeading}
            type="text"
            onChange={(e) => {
              setMainHeading(e.target.value);
            }}
            name="mainHeading"
          />
          <Input
            size="lg"
            label="Index"
            value={index}
            type="number"
            onChange={(e) => {
              setIndex(e.target.value);
            }}
            name="index"
          />
          <Input
            size="lg"
            label="Heading"
            value={heading}
            type="text"
            onChange={(e) => {
              setHeading(e.target.value);
            }}
            name="heading"
          />
          <Input
            size="lg"
            label="content"
            value={content}
            type="text"
            onChange={(e) => {
              setContent(e.target.value);
            }}
            name="content"
          />

          <Input
            size="lg"
            label="Image"
            type="file"
            onChange={(e) => {
              const images = "myStoryImage/mission/";
              if (e.target.files[0])
                uploader(e.target.files[0], setImage, setProgress, images);
            }}
            name="image"
          />

          <div>Image Uploaded {progress} %</div>
          <Button
            onClick={() => {
              // console.log(mainHeading, content, heading, index);
              if (heading && mainHeading && index && content && image) {
                setMissions((prev) => [
                  ...prev,
                  {
                    mainHeading: mainHeading,
                    index: index,
                    heading: heading,
                    content: content,
                    image: image,
                  },
                ]);
                console.log(missions);
                setContent("");
                setHeading("");
                setIndex("");
                setMainHeading("");
              } else {
                notify({
                  type: "error",
                  message: "Please fill all the fields",
                });
              }
            }}
          >
            Add Mission
          </Button>
        </div>
        <div className="border-2 border-black p-4 ">
          {missions && missions.length ? (
            <div className="grid grid-cols-2 w-[90vw] mx-auto gap-4 justify-center ">
              {missions.map((data, index) => {
                return (
                  <div className="flex flex-col gap-2 border-2 border-blue-500 p-4">
                    <div className="font-semibold">Index : {data.index}</div>
                    <div className="flex font-semibold ">
                      Main Heading :{data.mainHeading}
                    </div>
                    <div className="flex font-semibold ">{data.heading}</div>
                    <div>{data.content}</div>
                    <img src={data.image} alt="" />
                    <Button
                      onClick={() => {
                        setMissions((prev) =>
                          prev.filter((item) => item !== data)
                        );
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>

        <div className="my-6">
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleSubmit({
                missions: missions,
              });
            }}
          >
            Submit{" "}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default EditMyStoryMission;
