import React from 'react'

function ExperiencePoint(props) {
  return (
    <div className=" ">
            <div class=" my-6">
            <div className='w-full flex justify-center  my-2 '>

              <div className='flex w-76 justify-center px-4  text-md font-semibold  text-center  sm:text-base   py-2 bg-gray-200 rounded-2xl '>{props.heading}</div>
            </div>
              <div className='font-poppins font-normal text-base leading-tight  sm:leading-snug lg:leading-normal  text-center   '>
                {props.description}
              </div>
            </div>
          </div>
  )
}

export default ExperiencePoint