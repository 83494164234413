const LifeCoachingDesc = () =>{
    return (
        <div>
            <div className="text-center text-base font-poppins leading-tight  sm:leading-snug lg:leading-normal font-medium">
                In the same way a person requires financial advice, business
                consultations and medical inputs for the different aspects of
                his/her life, everyone requires a life coach. Why? <br /><br />
                <span className=" font-bold">Because life coaches help people get better…..at life.</span> <br /><br />
                How? And in what way you may ask? <br /><br />
                Put simply, it’s in your relationships, in your day-to-day living,
                in your career and the list goes on. To get to the right place, you
                need to ask the right questions. Am I maximizing my potential in my
                workplace? Are my interests aligned with my career goals? What steps
                should I take to make my spouse know he/she is loved? But our
                ability to introspect is heavily marred by personal biases and
                behavioral patterns that prevent us from undertaking the journey of
                personal growth. <br /><br />
                There are few ways to effectively grow as an individual; Self-help
                books and motivational seminars can only take you so far.
                <br /><br />
                Life coaching bridges this gap with full-time mentorship, creating
                mutual partnerships with trained life coaches – solely aimed towards
                achieving personal transformation in your life. With a life coach,
                you can expect to maximize your potential, develop your skills and
                foster a healthy creative mindset that can spear through any
                challenge that pops up in your life. <br /><br />
                Can this be done? Well, at least, on paper. The average life
                coaching initiative struggles to achieve the promises it claims it
                can fulfill with mentorships waning out due to a lack of commitment
                or individual care towards those in need. There isn’t much effort or
                drive to take the individual to the other side of living beyond
                daily words of wisdom and mundane non-professional advice.
                <br /><br />

                
              </div>
        </div>

    )
};

export default LifeCoachingDesc;