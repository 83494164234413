import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";

function EventImage2() {
    const [frontData, setFrontData] = useState();
    const getWelcomePage = async () => {
      const querySnapshot = await getDocs(collection(db, "eventsFrontPage"));
      console.log(querySnapshot);
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        setFrontData(data);
      });
    };

    useEffect(() => {
      getWelcomePage();
    }, []);
  return (
    <>
      <div className=" flex-col w-full flex items-center mb-5 px-[5%] gap-2 sm:grid grid-cols-4">
        {frontData ? (
          frontData.images ? (
            frontData.images.map((data) => {
              return (
                <img
                  className="  object-cover    h-56 w-full rounded-lg"
                  src={data.image}
                  alt="noImage"
                />
              );
            })
          ) : null
        ) : (
          <div>Loading</div>
        )}
      </div>
    </>
  );
}

export default EventImage2;
