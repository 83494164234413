import { useContext, createContext, useEffect, useState } from "react";
import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { auth, db } from "../firebase";
import { setDoc, doc, getDoc } from "firebase/firestore";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [userId, setUserId] = useState();
  const [dbUser, setDbUser] = useState();
  const [superAdmin, setSuperAdmin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider);
  };

  const logOut = () => {
    signOut(auth);
  };

  const setDbUserData = async (userId) => {
    const docRef = doc(db, "users", userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setDbUser(docSnap.data());
      console.log("Document data:", docSnap.data());
    } else {
      console.log("No such document!");
    }
  };

  const addUser = async (data) => {
    const docRef = doc(db, "users", data.email);
    const docData = {
      name: data.displayName,
      email: data.email,
    };
    await setDoc(docRef, docData, { merge: true })
      .then((doc) => {
        console.log(doc, "update");
      })
      .catch((error) => {
        console.log(error);
      });

    console.log("Document written with ID: ", docRef.id);
    setUserId(docRef.id);

    /* const querySnapshot = await getDocs(collection(db, "users"));
    console.log(querySnapshot, "quer");
    querySnapshot.forEach((doc) => {
      console.log(doc, "doc");
      console.log(doc.data(), "doc");
    }); */
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        if (currentUser) {
          const name = currentUser;
          if (name && name.email) {
            addUser(name);
          }
        }
      }
      console.log("User", currentUser);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    // console.log(userId, "userId");
    setDbUserData(userId);
  }, [userId]);
  useEffect(() => {
    if (dbUser && dbUser.isAdmin && dbUser.isAdmin === true) {
      setIsAdmin(true);
    }
    if (dbUser && dbUser.superAdmin && dbUser.superAdmin === true) {
      setIsAdmin(true);
      setSuperAdmin(true);
    }
  }, [dbUser]);

  return (
    <AuthContext.Provider
      value={{ googleSignIn, logOut, user, dbUser, superAdmin, isAdmin }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AuthContext);
};
