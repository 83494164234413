import React from 'react'

function YogaTop() {
  return (
    <div class="title-div">
        
        
        <div className=" text-3xl sm:text-6xl font-black pb-2 font-poppins">YOGA FOR MIND AND BODY</div>
        <p class="tagline ">
          Here is a powerful kundalini yoga set designed to integrate your body,
          mind and soul and to awaken you to your true potential
        </p>
      </div>
  )
}

export default YogaTop