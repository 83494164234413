import React, { useState, useEffect } from "react";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  setDoc,
} from "firebase/firestore";
import { Button, Input, Textarea } from "@material-tailwind/react";
import { app, db } from "../../firebase";

import { uploader } from "./test";

import { notify } from "../Common/Notification";
import { slugify } from "../Common/CommonFunction";

function EditEventsPage() {
  const [events, setEvents] = useState([]);
  const [currentImage, setCurrentImage] = useState();
  const [hotelInformationHeading, setHotelInformationHeading] = useState();
  const [currentText, setCurrentText] = useState();
  const [currentExperienceText, setCurrentExperienceText] = useState();
  const [currentExperienceDescription, setCurrentExperienceDescription] =
    useState();
  const [currentFaqQuestion, setCurrentFaqQuestion] = useState();
  const [currentFaqAnswer, setCurrentFaqAnswer] = useState();

  const [name, setName] = useState();
  const [homeImage, setHomeImage] = useState("");
  const [date, setDate] = useState("");
  const [location, setLocation] = useState("");
  const [subHeading, setSubHeading] = useState();
  const [description1, setDescription1] = useState();
  const [description2, setDescription2] = useState();
  const [description3, setDescription3] = useState();
  const [bannerPic, setBannerPic] = useState();
  const [aboutPic, setAboutPic] = useState();
  const [programHeading, setProgramHeading] = useState();
  const [programDescription, setProgramDescription] = useState();
  const [programImages, setProgramImages] = useState([]);
  const [experienceHeading, setExperienceHeading] = useState();
  const [experienceArray, setExperienceArray] = useState([]);
  const [hotelInformation, setHotelInformation] = useState([]);
  const [faq, setFaq] = useState([]);
  const [hotelInformationImage, setHotelInformationImage] = useState([]);
  const [hotelInformationText, setHotelInformationText] = useState([]);
  const [tribe, setTribe] = useState();
  const [editState, setEditState] = useState(false);
  const [reload, setReload] = useState(false);

  const [progress, setProgress] = useState(0);
  const getEvents = async () => {
    const querySnapshot = await getDocs(collection(db, "events"));
    const array = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      array.push(data);
    });
    setEvents(array);
    console.log(array);
  };

  useEffect(() => {
    getEvents();
  }, [reload]);

  const deleteEvent = async (id) => {
    await deleteDoc(doc(db, "events", id));
    console.log("res");
    setReload((prev) => !prev);
    notify({ type: "success", message: "success" });
  };

  const handleSubmit = async (data) => {
    const docRef = doc(db, "events", slugify(name));
    const docData = {
      name: name,
      slug: slugify(name),
      homeImage: homeImage,
      subHeading: subHeading,
      location: location,
      date: date,
      description1: description1,
      description2: description2,
      description3: description3,
      bannerPic: bannerPic,
      aboutPic: aboutPic,
      programHeading: programHeading,
      programDescription: programDescription,
      programImages: programImages,
      experienceHeading: experienceHeading,
      hotelInformationHeading: hotelInformationHeading,
      experienceArray: experienceArray,
      hotelInformation: hotelInformation,
      faq: faq,
      tribe: tribe,
      hotelInformationImage: hotelInformationImage,
      id: slugify(name),
    };

    await setDoc(docRef, docData, { merge: true })
      .then((doc) => {
        setReload((prev) => !prev);
        notify({ type: "success", message: "success" });
      })
      .catch((error) => {
        console.log(error);
      });
    console.log("Document written with ID: ", docRef.id);
  };
  useEffect(() => {
    if (editState === "-1") {
      setName("");
      setHomeImage("");
      setDate("");
      setLocation("");
      setSubHeading("");
      setDescription1("");
      setDescription2("");
      setDescription3("");
      setBannerPic("");
      setAboutPic("");
      setProgramHeading("");
      setProgramDescription("");
      setExperienceHeading("");
      setHotelInformationHeading("");
      setProgramImages([]);
      setExperienceArray([]);
      setHotelInformation([]);
      setHotelInformationImage([]);
      setCurrentExperienceDescription("");
      setCurrentExperienceDescription("");
      setCurrentText("");
      setTribe("");
      setCurrentImage("");
    }
  }, [editState]);

  return (
    <div className="py-32">
      <div
        className={`w-full px-5 ${
          editState !== false ? "  grid grid-cols-2 gap-5  " : ""
        }`}
      >
        {" "}
        <div className="flex flex-col gap-2 w-full  items-center  ">
          <Button
            onClick={() => {
              setEditState("-1");
              window.scrollTo(0, 0);
            }}
          >
            Add Event
          </Button>

          {events &&
            events.length > 0 &&
            events.map((data) => {
              console.log(data);
              return (
                <div className=" p-5 border border-purple-50  flex flex-row gap-2 items-center justify-center w-fit">
                  <div>{data.name}</div>
                  <div
                    onClick={() => {
                      setEditState(data.id);
                      setName(data.name);
                      setDate(data.date);
                      setLocation(data.location);
                      setHomeImage(data.homeImage);
                      setSubHeading(data.subHeading);
                      setDescription1(data.description1);
                      setDescription2(data.description2);
                      setDescription3(data.description3);
                      setBannerPic(data.bannerPic);
                      setAboutPic(data.aboutPic);
                      setProgramHeading(data.programHeading);
                      setProgramDescription(data.programDescription);
                      setProgramImages(data.programImages);
                      setExperienceHeading(data.experienceHeading);
                      setHotelInformationHeading(data.hotelInformationHeading);
                      setExperienceArray(data.experienceArray);
                      setTribe(data.tribe);
                      setHotelInformation(data.hotelInformation);
                      setFaq(data.faq);
                      setHotelInformationImage(data.hotelInformationImage);
                      window.scrollTo(0, 0);
                    }}
                  >
                    <Button variant="text">Edit Event</Button>
                    <Button
                      variant="text"
                      color="red"
                      onClick={() => {
                        deleteEvent(data.id);
                      }}
                    >
                      Delete Event
                    </Button>
                  </div>
                </div>
              );
            })}
        </div>
        {editState !== false ? (
          <div className="border p-6 sm:p-8  border-primary w-full">
            <form className="flex flex-col justify-center items-center gap-2 ">
              <div className="font-semibold text-xl flex justify-center items-center text-center">
                {name || "Add Event"}
              </div>
              <Input
                size="sm"
                label="Name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                name="name"
              />
              <Input
                size="sm"
                label="subHeading"
                value={subHeading}
                onChange={(e) => {
                  setSubHeading(e.target.value);
                }}
                name="profession"
              />
              <Input
                size="sm"
                label="date"
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                }}
                name="profession"
              />
              <Input
                size="sm"
                label="location"
                value={location}
                onChange={(e) => {
                  setLocation(e.target.value);
                }}
                name="profession"
              />
              <div className="p-3 border border-black text-center w-full rounded-md">
                <Input
                  size="lg"
                  label="Home Image"
                  type="file"
                  onChange={(e) => {
                    const images = "events/";
                    uploader(
                      e.target.files[0],
                      setHomeImage,
                      setProgress,
                      images
                    );
                  }}
                  name="image"
                />
                <div className="text-center">Image Uploaded {progress} %</div>
              </div>
              <Textarea
                size="lg"
                label="description1"
                value={description1}
                onChange={(e) => {
                  setDescription1(e.target.value);
                }}
                name="events/"
              />
              <Textarea
                size="lg"
                label="description2"
                value={description2}
                onChange={(e) => {
                  setDescription2(e.target.value);
                }}
                name="events/"
              />
              <Textarea
                size="lg"
                label="description3"
                value={description3}
                onChange={(e) => {
                  setDescription3(e.target.value);
                }}
                name="events/"
              />{" "}
              <div className="p-3 border border-black text-center w-full rounded-md">
                <Input
                  size="lg"
                  label="Banner Pic"
                  type="file"
                  onChange={(e) => {
                    const images = "events/";
                    uploader(
                      e.target.files[0],
                      setBannerPic,
                      setProgress,
                      images
                    );
                  }}
                  name="image"
                />
                <div className="text-center">Image Uploaded {progress} %</div>
              </div>
              <div className="p-3 border border-black text-center w-full rounded-md">
                <Input
                  size="lg"
                  label="aboutPic"
                  type="file"
                  onChange={(e) => {
                    const images = "events/";
                    uploader(
                      e.target.files[0],
                      setAboutPic,
                      setProgress,
                      images
                    );
                  }}
                  name="image"
                />
                <div className="text-center">Image Uploaded {progress} %</div>
              </div>
              <Input
                size="sm"
                label="program Heading"
                value={programHeading}
                onChange={(e) => {
                  setProgramHeading(e.target.value);
                }}
                name="profession"
              />
              <Textarea
                size="lg"
                label="program Description"
                value={programDescription}
                onChange={(e) => {
                  setProgramDescription(e.target.value);
                }}
                name="events/"
              />
              <div className="flex flex-col gap-2 justify-center items-center border border-black p-2">
                {programImages && programImages.length ? (
                  <div className="flex flex-row flex-wrap gap-2 justify-center items-center">
                    {programImages.map((data, index) => {
                      return (
                        <div className="flex flex-col border p-1 border-black rounded-md w-full justify-center items-center">
                          <div className="text-center   w-full">
                            {index + 1}
                          </div>
                          <div className="">
                            {data.text}
                            <img
                              className="w-32"
                              src={data.image}
                              alt="error"
                            ></img>
                          </div>
                          <Button
                            onClick={() => {
                              setProgramImages((prev) =>
                                prev.filter((item) => item !== data)
                              );
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      );
                    })}
                  </div>
                ) : null}

                <Input
                  size="sm"
                  label="image Text"
                  value={currentText}
                  onChange={(e) => {
                    setCurrentText(e.target.value);
                  }}
                  name="profession"
                />
                <Input
                  size="lg"
                  label="Program Image"
                  type="file"
                  onChange={(e) => {
                    const images = "events/";
                    uploader(
                      e.target.files[0],
                      setCurrentImage,
                      setProgress,
                      images
                    );
                  }}
                  name="image"
                />
                <div>Image Uploaded {progress} %</div>
                <Button
                  onClick={() => {
                    if (currentImage && currentText) {
                      setProgramImages((prev) => [
                        ...prev,
                        {
                          image: currentImage,
                          text: currentText,
                        },
                      ]);
                      setCurrentText(null);
                    } else {
                      notify({
                        type: "error",
                        message: "please upload image and change text",
                      });
                    }
                  }}
                >
                  Add Program Image
                </Button>
              </div>
              <Input
                size="sm"
                label="experience Heading"
                value={experienceHeading}
                onChange={(e) => {
                  setExperienceHeading(e.target.value);
                }}
                name="profession"
              />
              <div className="flex flex-row flex-wrap w-full gap-2 justify-center items-center border border-black p-2">
                {experienceArray && experienceArray.length ? (
                  <div className="flex flex-col gap-2 w-full items-center justify-center">
                    {experienceArray.map((data, index) => {
                      return (
                        <div className="flex flex-col border p-1 border-black rounded-md text-center gap-1 w-full ">
                          <div> {index + 1} </div>
                          <div className="text-lg font-semibold">
                            {data.heading}
                          </div>
                          <div>{data.description}</div>

                          <Button
                            onClick={() => {
                              setExperienceArray((prev) =>
                                prev.filter((item) => item !== data)
                              );
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      );
                    })}
                  </div>
                ) : null}

                <Input
                  size="sm"
                  label="Heading"
                  value={currentExperienceText}
                  onChange={(e) => {
                    setCurrentExperienceText(e.target.value);
                  }}
                  name="profession"
                />
                <Textarea
                  size="sm"
                  label="Description"
                  value={currentExperienceDescription}
                  onChange={(e) => {
                    setCurrentExperienceDescription(e.target.value);
                  }}
                  name="profession"
                />
                <Button
                  onClick={() => {
                    if (
                      currentExperienceText.length &&
                      currentExperienceDescription.length
                    ) {
                      setExperienceArray((prev) => [
                        ...prev,
                        {
                          heading: currentExperienceText,
                          description: currentExperienceDescription,
                        },
                      ]);
                      setCurrentExperienceText("");
                      setCurrentExperienceDescription("");
                    } else {
                      notify({
                        type: "error",
                        message: "please type both the text",
                      });
                    }
                  }}
                >
                  Add experience
                </Button>
              </div>
              <Input
                size="sm"
                label="Hotel Information Heading"
                value={hotelInformationHeading}
                onChange={(e) => {
                  setHotelInformationHeading(e.target.value);
                }}
                name="profession"
              />
              <div className="flex flex-col gap-2 justify-center items-center border border-black p-2">
                {hotelInformation && hotelInformation.length ? (
                  <div className="flex flex-row flex-wrap gap-2 justify-center items-center">
                    {hotelInformation.map((data, index) => {
                      return (
                        <div className="flex flex-col border p-1 border-black rounded-md w-full justify-center items-center ">
                          <div className="text-center   w-full">
                            {index + 1}
                          </div>
                          <div className="text-center   w-full">
                            {data.text}
                          </div>

                          <Button
                            onClick={() => {
                              setHotelInformation((prev) =>
                                prev.filter((item) => item !== data)
                              );
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      );
                    })}
                  </div>
                ) : null}

                <Input
                  size="sm"
                  label=" Hotel Information "
                  value={hotelInformationText}
                  onChange={(e) => {
                    setHotelInformationText(e.target.value);
                  }}
                  name="profession"
                />

                <Button
                  onClick={() => {
                    if (hotelInformationText.length) {
                      setHotelInformation((prev) => [
                        ...prev,
                        {
                          text: hotelInformationText,
                        },
                      ]);
                      setHotelInformationText("");
                    } else {
                      notify({
                        type: "error",
                        message: "please type both the text",
                      });
                    }
                  }}
                >
                  Add hotel Information text
                </Button>
              </div>
              <div className="flex flex-col gap-2 justify-center items-center border border-black p-2">
                {hotelInformationImage && hotelInformationImage.length ? (
                  <div className="flex flex-row flex-wrap gap-2 justify-center items-center">
                    {hotelInformationImage.map((data, index) => {
                      return (
                        <div className="flex flex-col border p-1 border-black rounded-md w-full justify-center items-center ">
                          <div className="text-center   w-full">
                            {index + 1}
                          </div>
                          <div>
                            <img
                              className="w-32"
                              src={data.image}
                              alt="error"
                            ></img>
                          </div>
                          <Button
                            onClick={() => {
                              setHotelInformationImage((prev) =>
                                prev.filter((item) => item !== data)
                              );
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      );
                    })}
                  </div>
                ) : null}

                <Input
                  size="lg"
                  label="aboutPic"
                  type="file"
                  onChange={(e) => {
                    const images = "events/hotelInformation/";
                    uploader(
                      e.target.files[0],
                      setCurrentImage,
                      setProgress,
                      images
                    );
                  }}
                  name="image"
                />
                <div>Image Uploaded {progress} %</div>
                <Button
                  onClick={() => {
                    if (currentImage) {
                      console.log(hotelInformation, "jj");
                      setHotelInformationImage((prev) => [
                        ...prev,
                        {
                          image: currentImage,
                        },
                      ]);
                    } else {
                      notify({
                        type: "error",
                        message: "please upload image and change text",
                      });
                    }
                  }}
                >
                  Add Hotel Information Image
                </Button>
              </div>
              <Textarea
                size="sm"
                label="Tribe Text"
                value={tribe}
                onChange={(e) => {
                  setTribe(e.target.value);
                }}
                name="tribe"
              />
              <div className="flex flex-row flex-wrap w-full gap-2 justify-center items-center border border-black p-2">
                {faq && faq.length ? (
                  <div className="flex flex-col gap-2 w-full items-center justify-center ">
                    {faq.map((data, index) => {
                      return (
                        <div className="flex flex-col border p-1 border-black rounded-md text-center gap-1 w-full">
                          <div> {index + 1} </div>
                          <div className="text-lg font-semibold">
                            {data.question}
                          </div>
                          <div>{data.answer}</div>
                          <Button
                            onClick={() => {
                              setFaq((prev) =>
                                prev.filter((item) => item !== data)
                              );
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      );
                    })}
                  </div>
                ) : null}

                <Input
                  size="sm"
                  label="Faq Question"
                  value={currentFaqQuestion}
                  onChange={(e) => {
                    setCurrentFaqQuestion(e.target.value);
                  }}
                  name="profession"
                />
                <Input
                  size="sm"
                  label="Faq Answer"
                  value={currentFaqAnswer}
                  onChange={(e) => {
                    setCurrentFaqAnswer(e.target.value);
                  }}
                  name="profession"
                />

                <Button
                  onClick={() => {
                    if (currentFaqQuestion.length && currentFaqAnswer.length) {
                      setFaq((prev) => [
                        ...prev,
                        {
                          question: currentFaqQuestion,
                          answer: currentFaqAnswer,
                        },
                      ]);
                      setCurrentFaqAnswer("");
                      setCurrentFaqQuestion("");
                    } else {
                      notify({
                        type: "error",
                        message: "please type both the text",
                      });
                    }
                  }}
                >
                  Add Faq
                </Button>
              </div>
              <Button
                typ="submit"
                onClick={(e) => {
                  e.preventDefault();

                  handleSubmit({
                    name: name,
                    subHeading: subHeading,
                    homeImage: homeImage,
                    date: date,
                    location: location,
                    description1: description1,
                    description2: description2,
                    description3: description3,
                    bannerPic: bannerPic,
                    aboutPic: aboutPic,
                    programHeading: programHeading,
                    programDescription: programDescription,
                    programImages: programImages,
                    experienceHeading: experienceHeading,
                    hotelInformationHeading: hotelInformationHeading,
                    experienceArray: experienceArray,
                    hotelInformation: hotelInformation,
                    hotelInformationImage: hotelInformationImage,
                    id: name,
                  });
                }}
              >
                Submit
              </Button>
            </form>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default EditEventsPage;
