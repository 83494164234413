import ReachOut from "../../Components/Footer/ReachOut";
import Links from "../../Components/Footer/Links";
import Foot from "../../Components/Footer/Foot";
import YogaTop from "../../Components/programs/Yoga/YogaTop";
import YogaVideo from "../../Components/programs/Yoga/YogaVideo";
import YogaPoints from "../../Components/programs/Yoga/YogaPoints";
import YogaExplore from "../../Components/programs/Yoga/YogaExplore";
// import "./yoga.css";

const yoga =() =>{
    return (
        
<html lang="en">
  
  <body>
    
    <div class="wrapper">
      
      <YogaTop />

      <YogaVideo />

      <YogaPoints />

     <YogaExplore />
     
      <div>
          <ReachOut />
          <Links />
          <Foot />
          </div>
    </div>
    
    {/* <script>
      $(document).ready(function () {
        $(".menu-icon").on("click", function () {
          $("nav ul").toggleClass("showing");
        });
      });

      $(window).on("scroll", function () {
        if ($(window).scrollTop()) {
          $("nav").addClass("black");
        } else {
          $("nav").removeClass("black");
        }
      });
    </script> */}
  </body>
</html>

    )
};

export default yoga;