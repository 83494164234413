import VedioPlayer from "../Common/VedioPlayer";

const vid = () =>{
    return (


      <div className="flex flex-col sm:flex-row mt-2 md:mt-4 sm:gap-4 lg:gap-12 ">

<div class="video-card">
              
              <div
              class="flex justify-center "
              src="https://youtu.be/YbGQbaSbjf0"
            >
              {/* <img src={require("../../assets/home/videos/v1.png")} alt="" /> */}
              <VedioPlayer
                url="https://youtu.be/YbGQbaSbjf0"
                playing={true}
                muted={true}
              ></VedioPlayer>
            </div>
                <div className="video-card-heading  ">
                  <div className="text-lg  sm:text-xl font-poppins font-semibold">Life Coaching Video 1</div>
                </div>
              </div>

              <div class="video-card">
              <div
              className="flex justify-center w-[100%]"
              src="https://youtu.be/5zMgJQ9iZ0g"
            >
              {/* <img src={require("../../assets/home/videos/v1.png")} alt="" /> */}
              <VedioPlayer
                url="https://youtu.be/5zMgJQ9iZ0g"
                playing={true}
                muted={true}
                
              ></VedioPlayer>
            </div>
                
                <div class="video-card-heading">
                <div className="text-lg  sm:text-xl font-poppins font-semibold">Life Coaching Video 2</div>
                  
                </div>
              </div> 



      </div>
        
              



              
           
    )
};

export default vid;