import React from 'react'

function OvercomeAnxietyDesc() {
  return (
    <div class="description-box">
        <div className=" font-poppins text-center text-base leading-tight  sm:leading-snug lg:leading-normal mb-10">
          <div>
          Breaking the Cycle of Anxiety Because anxiety comes and goes, people
            tend to overreact when it appears, only to forget about it when it
            isn't present. In order to find an answer to anxiety, you must start
            by dealing with anxiety through this effective and powerful guided
            meditation. This process will address the underlying issues and also
            heal their causes. Many people, especially men, don't like to admit
            that they feel anxious. But in reality, everyone's life has
            nail-biting moments. The demand to meet deadlines and quotas at work
            can trigger stress, and under stress, the brain triggers the release
            of stress hormones that induce two reactions: to fight back or to
            run away. Therefore, we are chemically designed with courage and
            fear intertwined. Anxiety is a biological option in everyone, from
            warriors to wallflowers. <br />
            The first step to getting control of your anxiety is to admit that
            there is nothing abnormal about feeling anxious; the issue is how
            much stress you experience and when you experience it. There are
            three states of anxiety to consider.
          </div>
        </div>
      </div>
  )
}

export default OvercomeAnxietyDesc