const SelfMasteryDesc = () =>{
    return (
        <div>
            <div className=" text-center text-base leading-tight  sm:leading-snug lg:leading-normal font-medium font-poppins ">
            Self-mastery is the complete mastery of self; to be fully in control
            of your life and approach day-to-day activities from a rational and
            enlightened mindset. You are in the driver’s seat, maintaining final
            authority over your choices, cravings, strengths and weaknesses.
            <br /><br />
            Put simply, you are fully in control of yourself. A slave to nothing
            and no one.<br /><br />
            A self-mastered mindset is infinitely more productive, a fertile
            ground for ideas – an MVP in the office and a man/woman of empathy
            at home. <br /><br />
            But Bodhie Vik has identified self-mastery as a valuable trait that
            runs deeper into the heart of Self, beyond the surface qualities of
            ideation and self-control. The character trait of self-mastery is
            the unity of Self. It is to be perceptive to the Light within you
            and recognize your value in Body and Spirit. <br /><br />
            This recognition of One’s Soul is not a methodology that treads the
            lines of one’s religion and spirituality. It is syncretic with all
            faiths, seeking to enable the powerhouse of productive potential and
            emotional maturity that lies within you. <br /><br />
            Nothing less. Nothing more. <br /><br />
            
          </div >
        </div>

    )
};

export default SelfMasteryDesc;