/* Status: Incomplete
Libraries to consider:
https://naver.github.io/egjs-flicking/Demos
https://keen-slider.io/examples#examples
https://ethanselzer.github.io/react-image-magnify/#/external
*/

import React from "react";
import { SwiperSlide } from "swiper/react";
import _ from "underscore";
import CustomSwiper from "./CustomSwiper";
function Carousel({ items, className }) {
  const buildItems = function (items) {
    if (!_.isEmpty(items)) {
      return items.map((thisElement, eIndex) => (
        <div className="w-96 ">
          <SwiperSlide
            className=""
            key={eIndex}
            style={{ backgroundColor: "#F5F5F5", boxShadow: "none" }}
          >
            <div className="">{thisElement.div}</div>
          </SwiperSlide>
        </div>
      ));
    } else {
      return null;
    }
  };
  const buildClassName = function (defaultClass) {
    const appClass = "  ";
    var instanceClassName = appClass + " " + defaultClass;
    if (className) {
      instanceClassName += " " + className;
    }
    return instanceClassName;
  };

  return (
    <div className={buildClassName("")}>
      <CustomSwiper {...{ items: items, className: "", buildItems }} />
    </div>
  );
}

export default Carousel;
