import React, { useEffect, useState } from 'react'
import { Button, Input } from "@material-tailwind/react";
import { uploader } from './test';
import { db } from '../../firebase';
import { collection, doc, getDocs, setDoc } from 'firebase/firestore';
import { notify } from '../Common/Notification';


function EditBannerVideosPage() {

    const [progress, setProgress] = useState();
    const [imageUrl, setImageUrl] = useState();
    const [data, setData] = useState(null);
    const [reload, setReload] = useState(false);
    const [mainHeading, setMainHeading] = useState('');
    const [heading, setHeading] = useState('');

    const getBannerVideosPage = async () => {
        const querySnapshot = await getDocs(collection(db, 'VideosPage', 'Banner'));
        console.log('querySnapshot: ', querySnapshot)
        querySnapshot.forEach((doc) => {
            setData(doc.data());
            setImageUrl(doc.data().image);
            setMainHeading(doc.data().mainHeading);
            setHeading(doc.data().heading);
            console.log(doc.data(), data);
        });
    };
    
    useEffect(() => {
        getBannerVideosPage();
    }, [reload]);

    const handleSubmit = async () => {
        const docRef = doc(db, 'VideosPage', 'Banner');
        const docData = {
            image: imageUrl,
            mainHeading: mainHeading,
            heading: heading
        };
        console.log(docData, 'doc');
        await setDoc(docRef, docData, { merge: true })
            .then((doc) => {
                setReload((prev) => !prev);
                notify({ type: 'success', message: 'link Saved' });
                console.log(doc, 'update');
            })
            .catch((error) => {
                console.log(error, 'k');
                notify({ type: 'error', message: error.message });
            });
    };
    
    
  return (
      <div className="h-full bg-gray-200 flex flex-col py-20 sm:py-32 items-center  gap-4 w-full">
          
      <div className=" flex flex-col gap-4 items-center justify-center w-full px-10  max-w-md ">
        {" "}
        <div className="flex flex-col gap-4  items-center justify-center  p-2 w-full">
          <Input
            size="lg"
            label="Image"
            type="file"
            onChange={(e) => {
              const images = "videosPage/banner/";
              uploader(e.target.files[0], setImageUrl, setProgress, images);
            }}
            name="image"
          />
          <div>Image Uploaded {progress} %</div>
        </div>
        <Input
          size="lg"
          label="Main Heading"
          value={mainHeading}
          type="text"
          onChange={(e) => {
            setMainHeading(e.target.value);
          }}
          name="mainHeading"
        />
        <Input
          size="lg"
          label="Heading"
          value={heading}
          type="text"
          onChange={(e) => {
            setHeading(e.target.value);
          }}
          name="heading"
        />
        <Button
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          Edit
        </Button>
      </div>
    </div>
  );
}

export default EditBannerVideosPage