import React from 'react'

function Top() {
  return (
    
        <div class="text-center">
          <div className="text-4xl font-poppins font-extrabold">Your application to</div>
          <div class="mt-5 font-poppins font-normal">Live your destiny 2022</div>
        </div>
    
  )
}

export default Top