import React from 'react'
import Button from "../../Common/Button";
import { useNavigate } from "react-router-dom";


function Book({data}) {
    const navigate = useNavigate();

  return (
    <div className="event-card rounded-xl shadow-sm border-2 relative h-full   md:h-20">
      <div className="img-container w-4/5 hidden sm:block">
        <img
        src={data.image}
          // src={require("../../../assets/home/section-7-img-1.png")}
          alt="" />
              <h1 className="heading-s text-md ">{ data.heading}</h1>
      </div>
      <div className="sm:hidden">
              <img
                  src={data.image}
        //   src={require("../../../assets/home/section-7-img-1.png")}
          alt=""
          className="w-full h-48"
        />
      </div>
      <div className="sm:pl-[20%]  sm:pt-[7%] flex flex-col items-center sm:block my-2 sm:my-0">
        <div className="text-2xl font-poppins font-bold sm:text-left text-center   ">
          {data.heading}
        </div>
        <div className="text-sm sm:pr-[5%] text-center px-2 sm:text-left sm:px-0 ">
          {data.content}
        </div>
        <div
          className="sm:mt-[4%] my-2"
          onClick={() => {
            navigate(`${data.route}`);
          }}
        >
          <Button name="Know More" />
        </div>
      </div>
    </div>
  );
}

export default Book