const LifeCoachingPoints2 = () =>{
    return (
        <div>
            <div className="text-xl sm:text-2xl font-poppins font-bold text-center leading-tight sm:leading-snug lg:leading-normal ">How do Bodhie Vik’s life coaching programs work?</div><br /><br />
              <div className="text-xl sm:text-2xl font-poppins font-bold text-center">1. Identifying limiting beliefs</div>
              <br />
              <div className="text-center text-base font-poppins leading-tight  sm:leading-snug lg:leading-normal font-medium">
                The first step towards preparing a successful strategy towards life
                is identifying your flaws. Bodhie Vik will help you shed any
                personal unconscious biases in understanding what’s holding you back
                from a fulfilling life. This involves serious introspection and in
                depth understanding about your habits, attitudes and mental fitness.
              </div>
              <br /><br />
              <div className="text-xl sm:text-2xl font-poppins font-bold text-center">2. Recognizing patterns</div>
              <br />
              <div className="text-center text-base font-poppins leading-tight  sm:leading-snug lg:leading-normal font-medium">
                Through the first step, your life coach will help you recognize the
                patterns in your life that prevent you from reaching further and
                going the extra mile. Your life coach will pilot a continuous
                process of excavation and study on yourself.
              </div>
              <br /><br />
              <div className="text-xl sm:text-2xl font-poppins font-bold text-center">3. Change and creation of new patterns</div>
              <br />
              <div className="text-center text-base font-poppins leading-tight  sm:leading-snug lg:leading-normal font-medium">
                Now that you have understood what’s holding you back, your life
                coach will create an exhaustive list of strategies and tools to help
                you create new patterns to break the old ones. It will be a constant
                mutual collaboration towards achieving breakthroughs in your life.
                The journey may be tiring but the final outcome is all that matters.
                <br /><br />
                Bodhie Vik is fully committed to seeking the complete realization of
                your personal goals, the ultimate personal transformation that you
                need to get ahead in your life. <br /><br />
                So before you make up your mind, we leave you with a final quote:
                <strong>
                “The greatest investments that people can make are in
                  themselves.” </strong>
                <br /><br />
                What’s stopping you from taking a chance?</div>
        </div>

    )
};

export default LifeCoachingPoints2;