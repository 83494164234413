const HighPerfPoints =()=>{
    return (
        <div>

           <div className=" text-md sm:text-2xl font-poppins font-semibold">To slide into your High Performance mindset and achieve the state of
            flow, you need to:</div> 
          <br /><br />
          <div className="text-xl sm:text-2xl font-poppins font-bold text-center">1. Identify your flow timings</div>
          <br />
          <div className="text-center text-base font-poppins leading-tight  sm:leading-snug lg:leading-normal font-medium">
            Each individual has a time range within a day where they are
            naturally attuned to produce higher levels of output, to go the
            extra mile and achieve higher productivity. This calls for
            introspection to recognize when he/she works best. This can be done
            with professionals or hour-to-hour close monitoring by yourself,
            being keenly aware of the internal and external environment that
            surrounds you as you function in it.
          </div>
          <br /><br />
          <div className="text-xl sm:text-2xl font-poppins font-bold text-center">2. Ready yourself for your entry into the flow state</div>
          <br />
          <div className="text-center text-base font-poppins leading-tight  sm:leading-snug lg:leading-normal font-medium ">
            Now that you’ve understood your flow timings, it is now time for you
            prepare your mind and body for task ahead of you. Eliminate all
            distractions that can potentially disrupt your state of flow. Remove
            all mental barriers that prevent you from running the race. This can
            be done through breath movements, yoga, energy exercises and
            rituals.
          </div>
          <br /><br />
          <div className="text-xl sm:text-2xl font-poppins font-bold text-center">3. Use flow state to maximize potential</div>
          <br />
          <div className="text-center text-base font-poppins leading-tight  sm:leading-snug lg:leading-normal font-medium">
            Assuming that you are in the state of flow, there is no extra goal
            to be achieved. Focus on the task at hand and don’t let up. No
            timers or reminders are needed. Work at the job set in front of you
            and considering that you have followed the previous steps, you
            should expect a smooth transition into the high performance mindset
            with zero disruptions in productivity. <br /><br />
            Mckinsey reports that executives who perform under a High
            Performance mindset are estimated to be five times more productive
            in comparison to output levels of those executives who operate under
            a normal (unenhanced) mindset. <br /><br />
            In simpler terms, the output of a man who works 1 hour in the state
            of flow is equivalent to the man who works five hours in a non-flow
            mindset. <br /><br />
            Keeping this in mind, it is important to not be misled by the
            numerous advertisements of “10+ hours in flow per day” or other such
            unrealistic claims put out by trainers to net a financial windfall.
            As per our findings that have been backed up by numerous research
            studies, <br /><br />
            An individual can only be in a state of flow for an average of three
            hours in one working day. <br /><br />
            Looking at this truth, the task at hand for any organization or
            individual is to create an environment that is fertile for enabling
            High Performance among its employees. One level higher, the task is
            now to educate the employees on how to achieve the state of flow.
            One more level up the pyramid and the task is now to monitor and
            ensure that employees are harnessing the flow state daily and are
            above all invested in this program for higher productivity.
            <br /><br />
            <span className="font-bold font-poppins ">This is where we step in. </span><br /><br />
            Bodhie Vik is a master enabler of High Performance. Clocking in at
            over 15 years of experience in this field, our program identifies
            the need for a High Performance mindset in the workplace and outside
            of it as well. We collaborate with organizations and high-performing
            individuals (such as entrepreneurs and investors) to create and
            promote higher productivity levels using flow psychology.
            <br /><br />
            We prefer to blend mindfulness and syncretic spirituality across all
            faiths and beliefs with our time-tested strategies and exercises to
            serve as the foundation for personal growth and increased yield in
            work. Bodhie Vik does not look at the human body as a lifeless
            machine but rather the container of Self, your unique identity and
            signature that pervades in everything you do. A High-Performance
            mindset in partnership with Self brings increased levels of clarity
            and personal attention towards other spheres of your life.
            <br /><br />
            Put simply, it brings about all-round development. <br /><br />
            As we work with you, we will prepare personalized routines for each
            employee, imparting techniques related to limb movements, breathing
            methods, yoga exercises and more. All our research has been sourced
            from the field of neuroscience and journals related to psychology.
          </div>
        </div>

    )
};

export default HighPerfPoints;