import React from 'react'

function VideoExplore() {
  return (
   

    <div class="explore">
    <div className="explore-title"><h3>Explore More</h3></div>
    <div class="categories">
      <a
        href="https://www.youtube.com/channel/UCSlFzYGuJkm594OYNBQUPSw"
        target="_blank"
        rel="noopener noreferrer"
        class="exp-btn"
        >Youtube Videos</a>
      
    </div>
  </div>
  )


}

export default VideoExplore