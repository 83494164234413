import React from 'react'

function DeepSleepTop() {
  return (
    <div class="title-div">
            <div className=" text-4xl sm:text-6xl font-black pb-2 font-poppins">DEEP SLEEP</div>
        
        <p class="tagline ">
          Here is a guided meditation program to put in sleep within minutes
        </p>
      </div>
  )
}

export default DeepSleepTop