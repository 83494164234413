import React from "react";
import "./foot.css";

function ReachOut({ removeMargin }) {
  return (
    <article
      id="contact-us"
      className={`article-bottom ${
        removeMargin ? "mt-0" : "mt-0"
      } flex items-center flex-col  bg-no-repeat bg-cover text-white`}
    >
      <div className=" flex flex-col md:flex-row   my-2   ">
        <div className="my-10 flex-1 flex flex-col justify-between ">
          <div className="text-3xl font-bold  font-poppins text-center ">
            Reach Out To Bodhie Vik
          </div>

          <div className=" mt-2 text-base leading-tight font-poppins text-center lg:pb-14 sm:px-20 px-10">
            Join me on a journey of self-discovery and growth! No matter where
            you are in life, there's always room for improvement. Reach out to
            me for help, or connect with me for life-changing programs,
            retreats, and tours and be a part of a supportive community
          </div>
        </div>

        <div className="w-auto h-2  mx-0 md:h-auto md:w-1 bg-white items-center invisible sm:visible "></div>

        <form className="flex-1 flex flex-col justify-center sm:my-10 mb-10 w-full ">
          <div className="sm:px-20 px-10">
            <div className="text-3xl font-bold mb-5 font-poppins text-center ">
              Let's Talk
            </div>
            <form>
              <div className="flex flex-col items-center justify-center gap-2">
                <input
                  className=" p-3 w-full rounded-full  border-2 border-primary font-poppins  self-center "
                  name="fullname"
                  placeholder="Name"
                  type="text"
                />
                <input
                  className=" p-3 w-full  rounded-full  border-2 border-primary font-poppins self-center"
                  name="email"
                  placeholder="Email"
                  type="email"
                />
                <div className=" w-full primary-btn bg-primary rounded-full py-3 text-center font-poppins  cursor-pointer  ">
                  <a href="mailto:bodhievik@gmail.com" className="    ">
                    Connect
                  </a>
                </div>
              </div>
            </form>
          </div>
        </form>
      </div>
    </article>
  );
}

export default ReachOut;
