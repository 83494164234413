import React from "react";
import "./book.css";
// import Button from "../../Common/Button";
// import TextTruncate from "react-text-truncate";

import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import Book from "./Book";

function BookMeFor() {
  const [data, setData] = useState(null);
  const getBook = async (docId) => {
    try {
      const docRef = doc(db, "HomePage", docId);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        setData(docSnapshot.data());
        // console.log(data, "akakkak")
        // console.log(docSnapshot.data() , "kkkkk");
      } else {
        console.log("Document not found");
      }
    } catch (error) {
      console.log("Error getting document:", error);
    }
  };

  useEffect(() => {
    getBook("BookMeFor");
  }, []);

  return (
    <div>
      {data && data.book && data.book.length ? (
        <div className="book-me-section  container  items-center flex-col my-10 ">
          <div className=" text-center text-4xl font-bold font-poppins ">
            {data.book[0].mainHeading}
          </div>
          <div className="flex flex-col justify-center items-center lg:grid lg:grid-cols-2 lg:grid-rows-2 pt-8 gap-4">
           
              {data.book.map((data, index) => {
                return <Book key={index} data={data} />;
              })}
            
          </div>

          {/* <WorkshopsAndRetreat></WorkshopsAndRetreat>
        <LifeCoaching></LifeCoaching>
        <Speaking></Speaking>
        <CorporateCoaching></CorporateCoaching> */}
        </div>
      ) : null}
    </div>
  );
}

export default BookMeFor;
