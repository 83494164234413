const LifeCoachingTop = () =>{
    return (
        <div class="title-div">
            
            <div className=" text-4xl sm:text-6xl font-black pb-2 font-poppins  ">LIFE COACHING</div>
            <p className="tagline ">
              The quality of your life is a function of the quality of questions you
              ask yourself.
            </p>
          </div>

    )
};

export default LifeCoachingTop;