import React, { useState } from "react";

function Collapse({ heading, content }) {
  const [active, setActive] = useState(false);
  return (
    <div class="expandable-list-item">
      <div
        class="img-container"
        onClick={() => {
          setActive((prev) => !prev);
        }}
      >
        {active === false && (
          <img class="" src={require("../../assets/icons/plus.png")} alt="" />
        )}
        {active === true && (
          <img class="" src={require("../../assets/icons/minus.png")} alt="" />
        )}
      </div>
      <div class="">
        <div className="font-poppins font-semibold   text-xl ">{heading}</div>
        {active === true && content}
      </div>
    </div>
  );
}

export default Collapse;
