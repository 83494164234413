import React from "react";
import ReactPlayer from "react-player";

const _ = require("underscore");

function HomeVideoMob({ url, playing, muted }) {
  return (
    <ReactPlayer
      url={url}
      className="rounded-md w-auto "
      // width="472px"
      // height="267px"

      width="370px"
      height="210px"
      // width="400px"
      // height="250px"

      // width="400px"
      // height="225px"
      controls={true}
      playing={false}
      muted={true}
      light={true}
    />
  );
}

export default HomeVideoMob;
