import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { Button } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

function AdminForms() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState([]);
  const getForm = async () => {
    const querySnapshot = await getDocs(collection(db, "Form"));
    const array = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      array.push(data);
    });

    console.log(array);
    setFormData(array.sort((a, b) => b.time - a.time));
  };
  function convertTime(data) {
    if (data && data.seconds && data.nanoseconds) {
      const timestamp = data.seconds * 1000 + data.nanoseconds / 1000000;

      const date = new Date(timestamp);

      const formattedDate = date.toLocaleString(); // Customize this format as needed

      return formattedDate;
    }
  }
  useEffect(() => {
    getForm();
  }, []);

  return (
    <div className="h-full bg-gray-200 flex flex-col py-20 sm:py-32 items-center  gap-4 w-full ">
      {formData ? (
        formData.length ? (
          <div className="flex flex-col gap-5 items-center justify-center">
            {formData.map((data) => {
              return (
                <div className="grid grid-cols-4 gap-10 items-center p-2 border border-black rounded-md w-full">
                  <div>
                    {data.user
                      ? data.user.name
                        ? data.user.name
                        : "User was not signed in "
                      : "User was not signed in "}
                  </div>
                  <div>{data.type ? data.type : "invalid type"}</div>
                  <div>
                    {data.time ? convertTime(data.time) : "invalid time"}
                  </div>
                  <div>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        navigate(`/admin/forms/${data.id}`);
                      }}
                    >
                      Open
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        ) : null
      ) : (
        <div>Loading</div>
      )}
    </div>
  );
}

export default AdminForms;
