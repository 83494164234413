import React, { useRef } from "react";
import Logo from "./Logo";
import GuestInforamtion from "./GuestInforamtion";
import EmergencyInformation from "./EmergencyInformation";
import OtherInformation from "./OtherInformation";
import Agreement from "./Agreement";
import emailjs from "@emailjs/browser";
import { useParams } from "react-router-dom";
import { doc, setDoc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import { db } from "../../firebase";
import { UserAuth } from "../../context/AuthContext";
import { useState } from "react";
function CommonForm() {
  const [checkbox, setCheckbox] = useState(false);
  const { user, dbUser } = UserAuth();
  const form = useRef();
  const { slug } = useParams();
  console.log(slug,"dd")
  const handleSubmit = async (data) => {
    const id = uuidv4();
    const docRef = doc(db, "Form", id);
    const docData = {
      formData: data,
      id: id,
      type: data.formFilled,
      user: dbUser || "notLoggedInUser",
      time: new Date(),
    };
    console.log(docData, "doc");
    await setDoc(docRef, docData, { merge: true })
      .then((doc) => {
        console.log(doc, "update");
      })
      .catch((error) => {
        console.log(error);
      });
    console.log("Document written with ID: ", docRef.id);
  };
  return (
    <div className="pt-20 ">
      <Logo></Logo>
      <hr></hr>
      <form
        ref={form}
        onSubmit={(e) => {
          const formElements = form.current.elements;
          const formData = {};
          for (let i = 0; i < formElements.length; i++) {
            const element = formElements[i];
            if (element.name) {
              formData[element.name] = element.value;
            }
          }

          // Log the form data to the console
          console.log("Form Data:", formData);
          if (formData) {
            handleSubmit(formData);
          }
          e.preventDefault();
          /*    emailjs
            .sendForm(
              "service_346yu3r",
              "template_3ion8mn",
              form.current,
              "tGtH-i1Bu-XGtDRCg"
            )
            .then(
              (result) => {
                console.log(result.text);
              },
              (error) => {
                console.log(error.text);
              }
            ); */
        }}
      >
        <input className="hidden" value={slug} name="formFilled"></input>
        <GuestInforamtion></GuestInforamtion>
        <EmergencyInformation></EmergencyInformation>
        <OtherInformation></OtherInformation>
        <Agreement></Agreement>
        <div className="flex gap-1 justify-center ">
          <input type="checkbox" onClick={() => setCheckbox(!checkbox)}></input>
          By submitting this registration form, you confirm that you have read,
          understood, and agreed to the above terms and conditions.
        </div>

        

        <div className="flex flex-col w-[90vw] sm:w-[75vw] lg:w-[60vw] mx-auto my-8 gap-2">
          <button
            type="submit"
            className=" w-full primary-btn bg-primary rounded-full py-3 my-2 text-center font-poppins  text-white disabled:opacity-50 "
            disabled={!checkbox}
          >
            Submit Form
          </button>
        </div>
      </form>
    </div>
  );
}

export default CommonForm;
