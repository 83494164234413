import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { Spreadsheet } from "react-spreadsheet";
import { Button } from "@material-tailwind/react";
import { notify } from "../Common/Notification";

function UsersPage() {
  const [data, setData] = useState([[{ value: "Name" }, { value: "Email" }]]);
  const [reload, setReload] = useState(false);
  const [users, setUsers] = useState(null);
  const getUsers = async () => {
    const querySnapshot = await getDocs(collection(db, "users"));
    const array = [];
    const a2 = [[{ value: "Name" }, { value: "Email" }, { value: "isAdmin" }]];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      let admin = "false";
      if (data.isAdmin) {
        admin = data.isAdmin.toString();
      }
      array.push(data);
      a2.push([
        { value: data.name, readOnly: true },
        { value: data.email, readOnly: true },
        {
          value: admin,
          readOnly: true,
        },
      ]);
      console.log(doc.data(), "doc");
      console.log(users);
    });
    setUsers(array);
    setData(a2);
  };
  useEffect(() => {
    getUsers();
  }, [reload]);

  const giveOrRemoveAdminAccess = async (data) => {
    const docRef = doc(db, "users", data.email);
    const docData = {
      isAdmin: data.isAdmin,
    };
    if (data.superAdmin === true && !data.isAdmin === true) {
      notify({ type: "error", message: "user is super admin" });
    } else {
      await setDoc(docRef, docData, { merge: true })
        .then((doc) => {
          console.log(doc, "update");
          setReload((prev) => !prev);
          notify({ type: "success", message: "success" });
        })
        .catch((error) => {
          console.log(error);
          notify({ type: "error", message: error.message });
        });
    }
  };
  return (
    <div className="h-full bg-gray-200 flex flex-col py-20 sm:py-32 items-center  gap-4 w-full ">
      {users && users.length > 0 && (
        <div>
          <div className=" flex flex-col items-center border p-10 gap-2">
            <Spreadsheet data={data} setData={setData}></Spreadsheet>
          </div>
          <div className="flex flex-row flex-wrap items-center justify-center gap-2">
            {users.map((data) => {
              return (
                <div className=" flex flex-col items-center border p-10 gap-2 border-black flex-shrink-0  rounded-xl">
                  <div className="flex flex-row gap-2">
                    <div className="font-bold">Name: </div>
                    <div>{data.name ? data.name : null}</div>
                  </div>
                  <div className="flex flex-row gap-2">
                    <div className="font-bold">Email: </div>
                    <div>{data.email}</div>
                  </div>
                  <div className="flex flex-row gap-2">
                    <div className="font-bold">isAdmin: </div>
                    <div>
                      {data.isAdmin ? data.isAdmin.toString() : "false"}
                    </div>
                  </div>
                  <div className="flex flex-row gap-2">
                    <Button
                      className="btn-primary"
                      onClick={() => {
                        giveOrRemoveAdminAccess({
                          email: data.email,
                          isAdmin: !data.isAdmin,
                          superAdmin: data.superAdmin || false,
                        });
                      }}
                    >
                      {data.isAdmin
                        ? "Remove Admin Access"
                        : "Give Admin Access"}
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default UsersPage;
