import React from "react";

function DayTwo() {
  return (
    <>
      <div class="blog-heading-lg title">
        <div className="text-2xl sm:text-3xl font-poppins font-bold underline  underline-offset-[8px]  my-2">
          Day 2:
        </div>
        <div className="text-2xl sm:text-3xl font-poppins font-bold underline  underline-offset-[8px]  my-2">
          Morning Session
        </div>
        <img
          src={require("../../../../assets/workshop/8.jpeg")}
          alt="Supernova Remnant G54"
          className="rounded-md my-2"
        />
      </div>

      <div class="blog-para">
        <div className="text-xl sm:text-2xl font-poppins font-bold    my-2">
          Session 5: Creating a Better Future:
        </div>

        <div className="font-poppins text-base text-center leading-tight  sm:leading-snug lg:leading-normal ">
          Cultivating Presence and Living in the Present Moment Transforming
          Beliefs and Perceptions for Personal Growth Emotional Rehearsal
          Techniques: Reprogramming the Body and Mind
        </div>

        <div className="text-xl sm:text-2xl font-poppins font-bold   my-2">
          Session 6: Harnessing Inner Power:
        </div>

        <div className="font-poppins text-base text-center leading-tight  sm:leading-snug lg:leading-normal ">
          Activating and Energizing the Brain's Potential Liberating Emotional
          Energy for Transformation Opening the Heart to Deeper Love and
          Compassion
        </div>
      </div>

      <div className="text-2xl sm:text-3xl font-poppins font-bold underline  underline-offset-[8px]  my-2 blog-heading-lg title">
        Afternoon Session
      </div>
      <img
        src={require("../../../../assets/workshop/4.jpeg")}
        alt="Supernova Remnant G54"
        className="rounded-md my-2"
      />
      <div className="blog-para">
        <div className="text-xl sm:text-2xl font-poppins font-bold   my-2">
          Session 7: Manifesting New Opportunities:
        </div>

        <div className="font-poppins text-base text-center leading-tight  sm:leading-snug lg:leading-normal ">
          Utilizing Consciousness to Shape Reality Broadcasting New
          Electromagnetic Signatures for Attraction Surrendering to the Higher
          Mind: Accessing Inner Wisdom
        </div>

        <div className="text-xl sm:text-2xl font-poppins font-bold  my-2">
          Session 8: Integration and Continued Growth:
        </div>

        <div className="font-poppins text-base text-center leading-tight  sm:leading-snug lg:leading-normal ">
          Reflecting on Personal Transformations during the Retreat Equipping
          Yourself with Practical Tools for Ongoing Development Closing
          Ceremony: Embracing Your Unleashed Inner Power Please note that the
          workshop itinerary is subject to change and can be tailored to meet
          the specific needs and time constraints of the retreat.
        </div>
      </div>
      <div className="font-poppins text-base text-center leading-tight  sm:leading-snug lg:leading-normal ">
        Please note that the workshop itinerary is subject to change and can be
        tailored to meet the specific needs and time constraints of the retreat.
      </div>
    </>
  );
}

export default DayTwo;
