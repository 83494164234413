const SelfMasteryTop = () =>{
    return (
        <div class="title-div">
        
        <div className="text-4xl sm:text-6xl font-black pb-2 font-poppins   ">SELF MASTERY</div>
        <p class="tagline ">
          "One can have no smaller or greater mastery than mastery of oneself."
        </p>
      </div>
    )
};

export default SelfMasteryTop;