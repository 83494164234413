import React from "react";

import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";

function ConnectWithMe() {
  const [data, setData] = useState(null);
  const getBook = async (docId) => {
    try {
      const docRef = doc(db, "HomePage", docId);
      const docSnapshot = await getDoc(docRef);
      
      if (docSnapshot.exists()) {
         const sortedSocials = docSnapshot.data().socials.slice(); // Make a copy of the array
         sortedSocials.sort((a, b) => a.index - b.index); // Sort by index
         setData({ ...docSnapshot.data(), socials: sortedSocials });
      } else {
        console.log("Document not found");
      }
    } catch (error) {
      console.log("Error getting document:", error);
    }
  };
  useEffect(() => {
    getBook("SocialMedia");
  }, []);



  return (
    <div>
      {data && data.socials && data.socials.length ? (
        <article className=" flex justify-center items-center flex-col  my-10">
          <div className="text-2xl sm:text-4xl font-poppins font-bold sm:mt-10 text-center px-5">
            {data.socials[0].mainHeading}
          </div>

          <div className="grid grid-cols-2  md:flex pt-10 gap-4 md:gap-4 px-4">
            {data.socials.map((data, index) => {
              return (
                <div className="">
                  <a
                    className="socials-card "
                    href={data.link}
                    target="__blank"
                  >
                    <img
                      src={data.image}
                      // src={require("../../assets/home/section-8-fb.png")} 
                      
                      />
                  </a>
                </div>
              );
            })}

       
          </div>
        </article>
      ) : null}
    </div>
  );
}

export default ConnectWithMe;
