import ReachOut from "../../Components/Footer/ReachOut";
import Links from "../../Components/Footer/Links";
import Foot from "../../Components/Footer/Foot";
import MembershipTop from "../../Components/MembershipPage/MembershipTop";
import MembershipMid from "../../Components/MembershipPage/MembershipMid";

import Mind from "../../Components/MembershipPage/Mind/Mind";
import Body from "../../Components/MembershipPage/Body/Body";
import Soul from "../../Components/MembershipPage/Soul/Soul";
import "./membership.css";


const MembershipPage = () => {
  return (
    <html lang="en">
      <body class="overflow-x-hidden max-w-[100vw]">
        <MembershipTop />
        <MembershipMid />



        <Mind />
        <Body />
        <Soul />

        <div>
          <ReachOut />
          <Links />
          <Foot />
        </div>

        {/* <style>
        .mission-img {
            position: relative;
            transform: translateX(-200%) translateY(80px) scale(20%);
            opacity: 0;
            transition: 1s all ease;
        }

        .mission-img.active {
            transform: translateX(0) translateY(0);
            opacity: 1;
        }
    </style> */}
        {/* <script>
        let backdrop = $('.video-backdrop');

        function showBackdrop() {
            backdrop.addClass('active')
            backdrop.offset({ top: $(window).scrollTop() })

            $(document.body).css('overflow', 'hidden')
        }

        function hideBackdrop() {
            backdrop.removeClass('active')

            $(document.body).css('overflow', 'auto')
            $('iframe').attr('src', 'about:blank')
        }

        backdrop.click(e => {
            hideBackdrop()
        })

        $('.video-card').click(e => {
            showBackdrop()
            $('iframe').attr('src', $(e.currentTarget).attr('src'))
            console.log(e.currentTarget);
        })
    </script> */}

        {/* words , script , style for mission mene comment out ki h  */}
      </body>
    </html>
  );
};

export default MembershipPage;
