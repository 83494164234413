import { Input, Option, Select } from "@material-tailwind/react";
import React, { useRef, useState } from "react";
import Button from "./Button";
import emailjs from "@emailjs/browser";

function Form() {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const form = useRef();
  const sendEmail = (e) => {
    emailjs
      .sendForm(
        "service_la29she",
        "template_l8ulepm",
        form.current,
        "Y1bUhR7X5T1MFoZ_D"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="flex flex-col   gap-2  font-poppins justify-items-center items-center sm:pt-3  w-full mb-2  ">
      <div className="text-black  font-semibold   px-2 sm:px-0 text-sm sm:text-2xl text-center lg:my-4">
        Unlock your path to wellness and receive exclusive offers, tips, and
        inspiration straight to your inbox! Join our vibrant community of
        like-minded people like you.
      </div>

      <form ref={form} onSubmit={sendEmail}>
        <div className="w-[70vw]  sm:w-full flex sm:flex-row flex-col sm:gap-2  gap-1">
          <input className="hidden" name="typeOfForm" value="modal"></input>
          <Input
            size="sm"
            label="Your Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            name="username"
          />
          <Input
            size="sm"
            label="Phone (optional)"
            value={mobile}
            name="mobile"
            onChange={(e) => {
              setMobile(e.target.value);
            }}
          />
        </div>

        <div className="w-full sm:my-2 my-1">
          <Input
            size="sm"
            label="Email Address"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            name="email"
          />
        </div>

        <div className=" w-full sm:my-2 lg:mb-4 my-1">
          {" "}
          <Select
            value={message}
            onChange={(e) => setMessage(e)}
            name="message"
            color="blue"
            label="Where did you hear about us? "
          >
            <Option value="Youtube">Youtube</Option>
            <Option value="Friends or Family">Friends or Family</Option>
            <Option value="Social Media">Social Media</Option>
            <Option value="Other">Other</Option>
          </Select>
        </div>

        <div className="flex justify-center rounded-sm  lg:my-2 ">
          <Button name="Subscribe Now" onClick={() => sendEmail()}></Button>
        </div>
      </form>
      <div className="  text-[0.52rem] sm:text-sx  text-gray-400 text-center ">
        By submitting this form, you agree to our Privacy Policy.
        <br></br>
        We look forward to connecting with you and assisting you on your
        journey!
      </div>
    </div>
  );
}

export default Form;
