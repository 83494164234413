const AboutImage = ({data})=>{
    return (
        <div className="sm:p-0 flex-1 w-full">
          <img
            // src={require("../../../assets/home/section-2-img-1.png")}
          src={data.image}
            className="object-cover md:rounded-3xl w-full"
            alt=""
          />
        </div>
    )
};

export default AboutImage;