import React from 'react'

function EmergencyInformation() {
  return (
    <>
      <div className="flex flex-col w-[90vw] sm:w-[75vw] lg:w-[60vw] mx-auto my-8 gap-2">
        <div className="flex justify-center font-semibold gap-1">
          <span className="underline">Emergency Information</span>
          <span className="text-red-500">*</span>
        </div>
        <div className="flex font-semibold gap-1">
          <span className="underline">Emergency Contact Information</span>
          <span className="text-red-500">*</span>
        </div>

        <div className=" flex flex-col md:flex-row gap-2">
          <input
            className=" p-2 sm:p-3 w-full rounded-xl  border-2 border-primary font-poppins  self-center "
            name="emergencyFirstName"
            placeholder="First Name "
            type="text"
            required={true}
          />
          <input
            className="p-2 sm:p-3 w-full rounded-xl  border-2 border-primary font-poppins  self-center "
            name="emergencyLastName"
            placeholder="Last Name "
            type="text"
            required={true}
          />
        </div>

        <div className="flex font-semibold gap-1">
          <span className="underline">Emergency Contact Number</span>
          <span className="text-red-500">*</span>
        </div>
        
          
          <div className="flex gap-2">
            <input
              className=" p-2 sm:p-3  w-full basis-1/2 rounded-xl  border-2 border-primary font-poppins  self-center"
              name="emergencyAreaCode"
              placeholder="Country Code"
              type="number"
              required={false}
            />
            <input
              className=" p-2 sm:p-3 w-full  rounded-xl  border-2 border-primary font-poppins self-center"
              name="emergencyPhonNumber"
              placeholder="Phone Number"
              type="number"
              required={false}
            />
          </div>
      
        

        {/* <div className="flex font-semibold">Alternate Phone Number </div>
        <div className="flex gap-2">
          <input
            className=" p-2 sm:p-3 w-full basis-1/2 rounded-xl  border-2 border-primary font-poppins self-center"
            name="emergencyAlternateAreaCode"
            placeholder="Area Code"
            type="number"
            required={false}
          />
          <input
            className=" p-2 sm:p-3 w-full  rounded-xl  border-2 border-primary font-poppins self-center"
            name="emergencyAlternatePhoneNumber"
            placeholder="Phone Number"
            type="number"
            required={false}
          />
        </div> */}
      </div>
    </>
  );
}

export default EmergencyInformation