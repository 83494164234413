import React from 'react'
import { Button, Input, Textarea } from "@material-tailwind/react";
import { uploader } from "../test";
import { notify } from "../../Common/Notification";
import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { useState } from "react";
import { useEffect } from "react";


function EditBookMeForHome() {
    const [reload, setReload] = useState(false);
    const [book, setBook] = useState([]);
    const [image, setImage] = useState("");
    const [heading, setHeading] = useState("");
    const [content, setContent] = useState("");
    const [mainHeading, setMainHeading] = useState("");
    const [index, setIndex] = useState("");
    const [data, setData] = useState(null);
    const [progress, setProgress] = useState();
    const [route, setRoute] = useState("");
    
    const getBook = async () => {
        const docRef = doc(db, "HomePage", "BookMeFor");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            if (docSnap.data().book) setBook(docSnap.data().book);
        } else {
            console.log("No such document!");
        }
    };
    useEffect(() => {
        getBook();
    }, [reload]);

    const handleSubmit = async (data) => {
        const docRef = doc(db, "HomePage", "BookMeFor");
        const docData = {
            book: book,
        };
        await setDoc(docRef, docData, { merge: true })
            .then((doc) => {
                setReload((prev) => !prev);
                notify({ type: "success", message: "success" });
            })
            
            .catch((error) => {
                console.error(error);
            });
        console.log("Document Written with ID : ", docRef.id);
    };
    
    useEffect(() => {
        getBook();
    }, [reload]);



  return (
    <div className="h-full bg-gray-200 flex flex-col py-20 sm:py-32 items-center  gap-4 w-full">
      <div className=" flex flex-col gap-4 items-center justify-center w-full px-10  max-w-md ">
        <div className="h-full bg-gray-200 flex flex-col  items-center  gap-4 w-full">
          <div className="border p-2 text-xl border-primary rounded-md bg-white">
            Edit Book Me For Home Page
          </div>

          <div className="flex flex-col gap-4  items-center  justify-center  p-4 w-full">
            <Input
              size="lg"
              label="Main Heading"
              value={mainHeading}
              type="text"
              onChange={(e) => {
                setMainHeading(e.target.value);
              }}
              name="mainHeading"
            />
            <Input
              size="lg"
              label="Index"
              value={index}
              type="number"
              onChange={(e) => {
                setIndex(e.target.value);
              }}
              name="index"
            />
            <Input
              size="lg"
              label="Heading"
              value={heading}
              type="text"
              onChange={(e) => {
                setHeading(e.target.value);
              }}
              name="heading"
            />
            <Input
              size="lg"
              label="content"
              value={content}
              type="text"
              onChange={(e) => {
                setContent(e.target.value);
              }}
              name="content"
            />
            <Input
              size="lg"
              label="route"
              value={route}
              type="text"
              onChange={(e) => {
                setRoute(e.target.value);
              }}
              name="route"
            />

            <Input
              size="lg"
              label="Image"
              type="file"
              onChange={(e) => {
                const images = "HomePage/bookme/";
                if (e.target.files[0])
                  uploader(e.target.files[0], setImage, setProgress, images);
              }}
              name="image"
            />

            <div>Image Uploaded {progress} %</div>
            <Button
              onClick={() => {
                // console.log(mainHeading, content, heading, index);
                if (heading && mainHeading && index && content && image) {
                  setBook((prev) => [
                    ...prev,
                    {
                      mainHeading: mainHeading,
                      index: index,
                      heading: heading,
                      content: content,
                        image: image,
                        route: route,

                    },
                  ]);
                  console.log(book);
                  setContent("");
                  setHeading("");
                  setIndex("");
                    setMainHeading("");
                    setRoute("");
                } else {
                  notify({
                    type: "error",
                    message: "Please fill all the fields",
                  });
                }
              }}
            >
              Add Book Me For
            </Button>
          </div>

          <div className="border-2 border-black">
            {book && book.length ? (
              <div className="grid grid-cols-2 w-[80vw]  gap-4 p-4 ">
                {book.map((data, index) => {
                  return (
                    <div className="flex flex-col gap-2 border-2 border-blue-500 p-4">
                      <div className="font-semibold">Index : {data.index}</div>
                      <div className="flex font-semibold ">
                        Heading : <br></br>
                        {data.heading}
                      </div>
                      <div className="flex font-semibold ">
                        Route : <br></br>
                        {data.route}
                      </div>
                      <div>
                        <span className="font-semibold">Description : </span>{" "}
                        <br></br>
                        {data.content}
                      </div>

                      <img src={data.image} alt="" />
                      <Button
                        onClick={() => {
                          setBook((prev) =>
                            prev.filter((item) => item !== data)
                          );
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>

          <div className="my-6">
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleSubmit({
                  book: book,
                });
              }}
            >
              Submit{" "}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditBookMeForHome


