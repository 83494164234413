import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { db } from '../../firebase';

function WhoAmI() {
   const [data, setData] = useState(null);
   const getAboutMeHomePage = async (docId) => {
     try {
       const docRef = doc(db, "MyStoryPage", docId);
       const docSnapshot = await getDoc(docRef);

       if (docSnapshot.exists()) {
         setData(docSnapshot.data());
         console.log(data, "akakkak");
       } else {
         console.log("Document not found");
       }
     } catch (error) {
       console.log("Error getting document:", error);
     }
   };
   useEffect(() => {
     getAboutMeHomePage("whoAmI");
   }, []);

  return (
    <article class=" flex items-center flex-col py-10">
      <div class="container flex items-center flex-col gap-5">
        <div className="text-3xl md:text-5xl mb-4 text-center font-poppins font-bold">
          {data && data.heading}
        </div>
        <img
          src={data && data.image1}
          // src={require("../../assets/story/whoami.png")}
          alt=""
          class="object-cover md:rounded-3xl  md:w-[80%]"
        />
        <div className="text-center md:mx-[6%] mt-2 font-poppins leading-tight  sm:leading-snug lg:leading-normal text-base sm:mb-5">
          {data && data.content}
        </div>
      </div>
      <img
        src={data && data.image2}
        // src={require("../../assets/story/q1.png")}
        alt=""
        class="w-full hidden md:block"
      />
    </article>
  );
}

export default WhoAmI