import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { useParams } from "react-router-dom";
import { Spreadsheet } from "react-spreadsheet";

function AdminSpecificForm() {
  const [data, setData] = useState("");
  const [newData, setNewData] = useState([]);
  const params = useParams();
  const getForm = async () => {
    const docRef = doc(db, "Form", params.id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setData(docSnap.data());
    } else {
      console.log("No such document!");
    }
  };
  useEffect(() => {
    getForm();
  }, []);

  useEffect(() => {
    setNewData([[{ value: "Field" }, { value: "Value" }]]);
    if (data) {
      const formData = data.formData;

      Object.keys(formData).map((data) => {
        setNewData((prev) => [
          ...prev,
          [
            { value: data, readOnly: true },
            { value: formData[data], readOnly: true },
          ],
        ]);
      });
    }
  }, [data]);

  return (
    <div className="h-full bg-gray-200 flex flex-col py-20 sm:py-32 items-center  gap-4 w-full ">
      {data ? (
        <div className=" flex flex-col items-center border p-10 gap-2">
          {data.user ? (
            data.user.name ? (
              <div
                className="flex flex-row gap-2
              "
              >
                <div>{data.user.name}</div>
                <div>{data.user.email ? data.user.email : ""}</div>
              </div>
            ) : null
          ) : null}
          {<Spreadsheet data={newData} setData={setNewData}></Spreadsheet>}
        </div>
      ) : null}
    </div>
  );
}

export default AdminSpecificForm;
