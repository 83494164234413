import React from "react";

function Foot({ eventData }) {
  return (
    <article >
      {eventData !== null ? (
        <div className="flex pt-2 md:px-20 lg:px-40  flex-col md:gap-0 gap-2 gap-y-0  md:space-y-0 md:flex-row-reverse   justify-between border-t-2 mb:border-grey-700">
          {" "}
          <div className="icons flex items-center justify-center">
            <a
              href="https://www.facebook.com/bodhievik?mibextid=LQQJ4d"
              target="_blank"
            >
              <img src={require("../../assets/icons/fb.png")} alt="" />
            </a>
            <a
              href="https://www.linkedin.com/in/bodhie-vik-25b8b8208/"
              target="_blank"
            >
              <img src={require("../../assets/icons/li.png")} alt="" />
            </a>
            <a href="https://twitter.com/BodhieVik" target="_blank">
              <img src={require("../../assets/icons/tw.png")} alt="" />
            </a>
            <a href="https://youtube.com/@bodhievik" target="_blank">
              <img src={require("../../assets/icons/yt.png")} alt="" />
            </a>
            <a
              href="https://instagram.com/bodhievik?igshid=OGQ5ZDc2ODk2ZA=="
              target="_blank"
            >
              <img src={require("../../assets/icons/ig.png")} alt="" />
            </a>
          </div>
          <div className="flex flex-row items-center px-[6%] gap-4 font-poppins font-semibold text-sm sm:text-base justify-center">
            <div className=" ">© 2023 Bodhie Vik</div>
            <div className="flex space-x-2 md:ml-10 font-poppins">
              <a href="/terms" target="_blank" className="flex items-center">
                <span className="mr-1 text-[0.5rem]">●</span>Terms
              </a>
              <a href="/privacy" target="_blank" className="flex items-center">
                <span className="mr-1 text-[0.5rem]">●</span> Privacy
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-screen h-screen flex flex-col justify-center items-center  p-20 gap-4 bg-gray-200">
          Loading
        </div>
      )}
    </article>
  );
}

export default Foot;
