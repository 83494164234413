import React from "react";
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import Point from "./Point";

function MyStoryPoint() {
  const [data, setData] = useState(null);
  const getPoints = async (docId) => {
    try {
      const docRef = doc(db, "MyStoryPage", docId);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        setData(docSnapshot.data());
        console.log(docSnapshot.data(), "kkkkk");
      } else {
        console.log("Document not found");
      }
    } catch (error) {
      console.log("Error getting document:", error);
    }
  };

  useEffect(() => {
    getPoints("Points");
  }, []);

  return (
    <div class="container  z-100  ">
      {data && data.points && data.points.length ? (
        <div>
          {data.points.map((data, index) => {
            return <Point key={index} data={data} />;
          })}
        </div>
      ) : null}
    </div>
  );
}

export default MyStoryPoint;
