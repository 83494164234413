import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import Button from "../../Components/Common/Button";
function ContactForm() {
  const form = useRef();
  return (
    <article
      id="contact-us"
      className="article-bottom flex items-center flex-col  bg-no-repeat bg-cover text-white"
    >
      <div className=" flex  my-2   ">
        <div className="my-10 flex-1 flex flex-col justify-between ">
          <div className="text-3xl font-bold  font-poppins text-center ">
            Reach Out To Bodhie Vik
          </div>

          <div className=" mt-2 text-base leading-tight font-poppins text-center  lg:px-[16%] px-10">
            Join me on a journey of self-discovery and growth! No matter where
            you are in life, there's always room for improvement. Reach out to
            me for help, or connect with me for life-changing programs,
            retreats, and tours and be a part of a supportive community
          </div>
        </div>
      </div>
      <form
        onSubmit={(e) => {
          // e.preventDefault();
          emailjs
            .sendForm(
              "service_346yu3r",
              "template_3ion8mn",
              form.current,
              "tGtH-i1Bu-XGtDRCg"
            )
            .then(
              (result) => {
                console.log(result.text);
              },
              (error) => {
                console.log(error.text);
              }
            );
        }}
        className="flex flex-col justify-center w-[85vw] lg:w-[65vw] mx-auto mb-4 "
        ref={form}
      >
        <div className="flex flex-col items-center justify-center gap-3">
          <input
            className=" p-3 w-full rounded-xl  border-2 border-primary font-poppins  self-center "
            name="username"
            placeholder="Name"
            type="text"
            required={true}
          />
          <input
            className=" p-3 w-full  rounded-xl  border-2 border-primary font-poppins self-center"
            name="email"
            placeholder="Email"
            type="email"
            required={true}
          />
          <input
            className=" p-3 w-full h rounded-xl  border-2 border-primary font-poppins self-center"
            name="phone"
            placeholder="Phone (optional)"
            type="number"
            required={false}
          />
          <textarea
            className=" p-3 w-full h-24  rounded-xl  border-2 border-primary font-poppins self-center bg-transparent "
            name="message"
            placeholder="A message for us"
            type="text"
          />
          <div className=" w-full primary-btn bg-primary rounded-full py-3 text-center font-poppins  text-white  ">
            <button type="submit " className="font-semibold">Connect</button>
          </div>
        </div>
      </form>
    </article>
  );
}

export default ContactForm;
