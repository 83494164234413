const SelfMasteryEquip = ()=>{
    return (
        <div>
            <div className=" text-md sm:text-2xl font-poppins font-semibold mt-5 text-center">To equip yourself with a self-mastered mindset, you need:</div>
          <br /><br />
          <div className=" text-xl sm:text-2xl font-poppins font-bold text-center"> 1. CONTROL</div>
          <br />
          <div className="text-center text-base font-poppins leading-tight  sm:leading-snug lg:leading-normal">
            Seize authority over your body and mind. The human brain is a
            shape-shifting wave of highs and lows. To be able to restrain your
            anger when it needs to be restrained, to stay away from foods that
            do more harm than good, you need to be in the driver’s seat. Of
            course, we’re not here to make you lifeless, soulless robots. We’re
            here to help you set the bar for the life you want to live – and
            live up to it effortlessly and in control. To do so is to be aware
            of your shortcomings and create a vision for how you want to live
            your life. Then stick to it.
          </div>
          <br /><br />
          <div className=" text-xl sm:text-2xl font-poppins font-bold text-center"> 2. SURRENDER</div>
          <br />
          <div className="text-center text-base font-poppins leading-tight  sm:leading-snug lg:leading-normal">
            Asking yourself to surrender after seizing control of self may seem
            ludicrous and indeed so – if they were held in the same context. To
            surrender is to part ways with your over-indulgence and personal
            drawbacks. We need to let go of the things that don’t let go of us.
            Vices, addictions, and irrational fears need no place in a mindset
            of victory and infinite possibilities.
          </div>
          <br /><br />

          <div className=" text-xl sm:text-2xl font-poppins font-bold text-center"> 3. DISCIPLINE</div>
          <br />
          <div className="text-center text-base font-poppins leading-tight  sm:leading-snug lg:leading-normal">
            Now that you’ve surrendered your vices and taken command over
            yourself, you need to live in adherence to your vision. It’s
            important to identify the obstacles that come in the way of your
            life and immediately overcome them. Not a moment to linger, not a
            side-glance but a rapid overthrow of your inner demons. Commit 25
            minutes a day to investing in yourself, create rituals and habits
            that maintain your control and take every decision you come across
            per day as a step up or a step down in personal transformation of
            self.
          </div>
          <br /><br />
          <div className=" text-xl sm:text-2xl font-poppins font-bold text-center "> 4. ACTION</div>
          <br />
          <div className="text-center text-base font-poppins leading-tight  sm:leading-snug lg:leading-normal">
            Live in action. The passer-by is mere set décor to the hero in
            action on whom the spotlight is shown. Exercise your right to take
            control and be in charge every day. Take initiative in your
            workplace or at home, treating each task set before you with utmost
            responsibility. View each problem with a broadened horizon. And
            while you blaze through your workload and personal responsibilities
            while the whole world watches in awe, never forget to actuate your
            vision while maintaining your safe-guards to any internal or
            external threats.
          </div>
          <br /><br />
          <div className=" text-xl sm:text-2xl font-poppins font-bold text-center "> 5. FOCUS</div>
          <br />
          <div className="text-center text-base font-poppins leading-tight  sm:leading-snug lg:leading-normal">
            There is no point to living a self-mastered life if you’re not
            applying focus to what’s at hand. Once you’ve seized focus,
            surrendered your vices, disciplined yourself and practiced the art
            of personal leadership in your life, all that’s left is to never let
            your vision be blurry. A foggy windshield is a precursor to a
            mangled car wreck. Never relent and never lower your standards.
            <br /><br /><br/>
            </div>
            <div className="text-center text-base font-poppins leading-tight  sm:leading-snug lg:leading-normal">
            To make that first move in mastering yourself and living a fully
            realized life, join Bodhie Vik’s Self Mastery program. Clocking in
            at over 15 years of experience in this field, Bodhie Vik is intent
            on helping you get from Point A to your desired Point B. We conduct
            90-120 minute sessions done in groups or one-on-one private sessions
            based on your preference. We prefer to use techniques associated
            with mind-body intelligence, deep relaxation, visualization,
            meditation and brain optimization. <br /><br />
            In the end, self-mastery is not a universal goal. Every individual
            has their own breakthroughs. We have seen the process of
            self-mastery in each person to be one that’s entirely subjective. So
            don’t compare yourself to the other person! Each track leads to its
            own destination so own the path that lies ahead of you. <br /><br />
            And about that first move, take the first step in your journey of
            self-mastery with Bodhie Vik. You won’t regret it.

            </div>

           
          
        </div>

    )
};

export default SelfMasteryEquip;