import React, { useEffect, useState } from "react";
import "./index.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Events from "../../Components/HomePage/Events/Events";
import ConnectWithMe from "../../Components/Footer/ConnectWithMe";
import ReachOut from "../../Components/Footer/ReachOut";
import Links from "../../Components/Footer/Links";
import Foot from "../../Components/Footer/Foot";
import Welcome from "../../Components/HomePage/Welcome/Welcome";
import AboutMe from "../../Components/HomePage/AboutMe/AboutMe";
import Services from "../../Components/HomePage/Services/Services";
import VediosYoutube from "../../Components/HomePage/Vedios/VediosYoutube";
import Highlight from "../../Components/HomePage/Highlight/Highlight";
import WhatPeopleSay from "../../Components/HomePage/WhatPeopleSay/WhatPeopleSay";
import BookMeFor from "../../Components/HomePage/BookMeFor/BookMeFor";
import Modal from "../../Components/Common/Modal";

function Home() {
  const [popup, setPopup] = useState();

  useEffect(() => {
    setTimeout(() => {
      setPopup(true);
    }, 180000);
  }, []);
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="">
      {popup && <Modal popup={popup} setPopup={setPopup}></Modal>}
      <body className="overflow-x-hidden max-w-[100vw] text-justify ">
      
        <Welcome></Welcome>
        <AboutMe></AboutMe>
        <Services></Services>
        <Events></Events>
        <Highlight></Highlight>
        <VediosYoutube carousel={true}></VediosYoutube>
        <WhatPeopleSay></WhatPeopleSay>
        <BookMeFor></BookMeFor>
        <ConnectWithMe></ConnectWithMe>
        <ReachOut></ReachOut>
        <Links></Links>
        <div className="bg-gray-50 ">{/* <Form2></Form2> */}</div>
        <Foot></Foot>
        {/*    <Helmet>
          {" "}
          <script src="main_.js"></script>
          <script src="https://unpkg.com/aos@next/dist/aos.js"></script>
          <script>AOS.init();</script>
        </Helmet> */}
      </body>
    </div>
  );
}

export default Home;
