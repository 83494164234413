import React from 'react'
import { useEffect, useState } from "react";


function Point({ data }) {
    const [indexClass, setIndexClass] = useState();
    useEffect(() => {
      if (data.index % 2 === 0) {
        setIndexClass("sm:order-first");
      } else  {
        setIndexClass("sm:order-last");
      }
    }, [data.index]);


  return (
    <div class="grid grid-cols-1 sm:grid-cols-2  items-center justify-items-center  gap-5 sm:gap-4 lg:gap-0  my-8 sm:w-[90vw] mx-auto ">
          <div className={`flex-1  ${indexClass}`}>
        <img
          src={data.image1}
          // src={require("../../assets/story/journey-l.png")}
          alt=""
          class="object-cover md:rounded-3xl  w-[350px] lg:h-[30vw] "
        />
      </div>

      <div class="flex-1 ">
        <div className="text-3xl sm:text-4xl  text-center  font-poppins font-extrabold underline  underline-offset-[8px] decoration-blue-500 mb-5">
          {data.heading}
        </div>

        <div className="text-center   text-base leading-tight  sm:leading-snug lg:leading-normal ">
          {data.descriptioin}
        </div>
      </div>
    </div>
  );
}

export default Point