import { useNavigate } from "react-router-dom";
import Button from "../../Common/Button";

const Bodhie = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div className=" flex-1 flex items-center md:items-center  text-center    md:mt-0 justify-evenly  flex-col text-white ">
      { data.heading  && <div className="text-6xl font-bold font-poppins"> {data.heading}</div>} 
      {/* //<h2>Lorem ipsum dolor sit amet consectetur adipisicing elit.</h2> */}
      <div className="text-base sm:text-xl text-centre leading-tight  font-poppins mb-8 lg:mb-0 ">
        {data.content}
      </div>
      <div>
        <button
          className="bg-primary rounded-full hover:bg-blue-600  text-white text-lg font-bold py-2 px-10 w-full  "
          onClick={() => navigate("/" + data.route)}
        >
          {data.buttonText}
        </button>
      </div>
    </div>
  );
};

export default Bodhie;
