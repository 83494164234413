const Catch = () =>{
    return (
        <div>
            <div className="text-2xl sm:text-4xl font-poppins font-bold text-center my-5">The catch</div>
          <div className=" text-center text-base font-poppins leading-tight  sm:leading-snug lg:leading-normal">
            This three step process of designing your destiny may sound easy at
            face value; However, through our 15 years of experience in this
            field, we’ve understood that the amount of core energy and focus
            that’s required to break the mold, the immersive environment that
            one needs to be in to achieve personal breakthroughs – it’s simply a
            task that cannot be done irregularly and on an individual basis.
          </div>
        </div>
    )
};

export default Catch;