import React from "react";
import SelectCountry from "./SelectCountry";

function GuestInforamtion() {
  return (
    <div className="flex flex-col w-[90vw] sm:w-[75vw] lg:w-[60vw] mx-auto my-8 gap-2">
      <div className="flex justify-center font-semibold gap-1">
        <span className="underline">Guest Information</span>
        <span className="text-red-500">*</span>
      </div>

      <div className=" flex flex-col md:flex-row gap-2">
        <input
          className=" p-2 sm:p-3 w-full rounded-xl  border-2 border-primary font-poppins  self-center "
          name="firstname"
          placeholder="First Name * "
          type="text"
          required={true}
        />
        <input
          className="p-2 sm:p-3 w-full rounded-xl  border-2 border-primary font-poppins  self-center "
          name="lastname"
          placeholder="Last Name *"
          type="text"
          required={true}
        />
      </div>
      <div className="flex flex-col-reverse sm:flex-row gap-2">
        <div className="flex  items-center gap-2 justify-start ">
          <label className="font-medium flex items-center  ">
            Gender<span className="text-red-500 px-1">*</span>
          </label>
          <select
            className=" p-2 sm:p-3  rounded-xl  border-2 border-primary font-poppins  self-center "
            placeholder=""
            name="gender"
            type="select"
            required={true}
          >
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <input
          className=" p-2 sm:p-3 w-full  rounded-xl  border-2 border-primary font-poppins self-center"
          name="email"
          placeholder="Email "
          type="email"
          required={true}
        />
      </div>

      <div className="font-semibold">
        Birthdate<span className="text-red-500 px-1">*</span>
      </div>

      <input
        className=" p-2 sm:p-3 w-full  rounded-xl  border-2 border-primary font-poppins self-center"
        name="dob"
        placeholder="dd-mm-yy"
        type="date"
        required={true}
      />

      <div className="flex font-semibold">
        Phone Number<span className="text-red-500 px-1">*</span>
      </div>
      <div className="flex gap-2">
        <input
          className=" p-2 sm:p-3  w-full basis-1/2 rounded-xl  border-2 border-primary font-poppins  self-center"
          name="cellAreaCode"
          placeholder="Country Code"
          type="number"
          required={false}
        />
        <input
          className=" p-2 sm:p-3 w-full  rounded-xl  border-2 border-primary font-poppins self-center"
          name="cellPhonNumber"
          placeholder="Phone Number"
          type="number"
          required={false}
        />
      </div>

      {/* <div className="flex font-semibold">Home Number</div>
      <div className="flex gap-2">
        <input
          className=" p-2 sm:p-3 w-full  rounded-xl  border-2 border-primary font-poppins basis-1/2 self-center"
          name="homeAreaCode"
          placeholder="Country Code"
          type="number"
          required={true}
        />
        <input
          className=" p-2 sm:p-3 w-full  rounded-xl  border-2 border-primary font-poppins  self-center"
          name="homePhoneNumber"
          placeholder="Phone Number"
          type="number"
          required={true}
        />
      </div> */}

      <div className="flex font-semibold">
        Address<span className="text-red-500 px-1">*</span>
      </div>

      <input
        className=" p-2 sm:p-3 w-full rounded-xl  border-2 border-primary font-poppins  self-center "
        name="streetAddress"
        placeholder="Street Address"
        type="text"
        required={true}
      />
      <input
        className=" p-2 sm:p-3 w-full rounded-xl  border-2 border-primary font-poppins  self-center "
        name="streetAddressLine2"
        placeholder="Street Address Line 2 "
        type="text"
        required={false}
      />

      <div className="flex  gap-2">
        <input
          className=" p-2 sm:p-3 w-full rounded-xl  border-2 border-primary font-poppins  self-center "
          name="city"
          placeholder="City"
          type="text"
          required={true}
        />
        <input
          className=" p-2 sm:p-3 w-full rounded-xl  border-2 border-primary font-poppins  self-center "
          name="state"
          placeholder="State/Province"
          type="text"
          required={true}
        />
      </div>

      <div className="flex gap-2">
        <input
          className=" p-2 sm:p-3 w-full rounded-xl  border-2 border-primary font-poppins  self-center "
          name="zipCode"
          placeholder="Postal/Zip Code"
          type="text"
          required={true}
        />

        <SelectCountry></SelectCountry>
      </div>
    </div>
  );
}

export default GuestInforamtion;
