import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import { Button, Input, Textarea } from "@material-tailwind/react";
import { uploader } from "../test";
import { notify } from "../../Common/Notification";

function EditWhoAmIMyStory() {
  const [reload, setReload] = useState(false);
  const [data, setData] = useState(null);
  const [heading, setHeading] = useState("");
  const [content, setContent] = useState();
    const [imageUrl1, setImageUrl1] = useState();
    const [imageUrl2, setImageUrl2] = useState();
    const [progress, setProgress] = useState();
    
   const getWhoAmIMyStory = async (docId) => {
     try {
       const docRef = doc(db, "MyStoryPage", docId);
       const docSnapshot = await getDoc(docRef);

       if (docSnapshot.exists()) {
         setData(docSnapshot.data());
         setHeading(docSnapshot.data().heading);
           setContent(docSnapshot.data().content);
           setImageUrl1(docSnapshot.data().image1);
           setImageUrl2(docSnapshot.data().image2);
           
         console.log(docSnapshot.data(), data);
       } else {
         console.log("Document not found");
       }
     } catch (error) {
       console.log("Error getting document:", error);
     }
   };
   useEffect(() => {
     getWhoAmIMyStory("whoAmI");
   }, []);

   const handleSubmit = async () => {
     const docRef = doc(db, "MyStoryPage", "whoAmI");
     const docData = {
       heading: heading,
         content: content,
         image1: imageUrl1,
         image2: imageUrl2,
        
     };
     console.log(docData, "doc");
     await setDoc(docRef, docData, { merge: true })
       .then((doc) => {
         setReload((prev) => !prev);
         notify({ type: "success", message: "link Saved" });
         console.log(doc, "update");
       })
       .catch((error) => {
         console.log(error, "k");
         notify({ type: "error", message: error.message });
       });

     console.log("Document written with ID: ", docRef.id);
    };
    

  return (
    <div className="h-full bg-gray-200 flex flex-col py-20 sm:py-32 items-center  gap-4 w-full">
      <div className="border p-2 text-xl border-primary rounded-md bg-white">
        Edit Who Am I My Story Page
      </div>
      <div className=" flex flex-col gap-4 items-center justify-center w-full px-10  max-w-md ">
        {" "}
        <div className="flex flex-col gap-4  items-center justify-center  p-2 w-full">
          <Input
            size="lg"
            label="Image 1"
            type="file"
            onChange={(e) => {
              const images = "MyStoryPage/whoAmI/";
              uploader(e.target.files[0], setImageUrl1, setProgress, images);
            }}
            name="image 1"
          />
          <div>Image Uploaded {progress} %</div>
        </div>
        <div className="flex flex-col gap-4  items-center justify-center  p-2 w-full">
          <Input
            size="lg"
            label="Image 2"
            type="file"
            onChange={(e) => {
              const images = "MyStoryPage/whoAmI/";
              uploader(e.target.files[0], setImageUrl2, setProgress, images);
            }}
            name="image 2"
          />
          <div>Image Uploaded {progress} %</div>
        </div>
        <Input
          size="lg"
          label="Heading"
          value={heading}
          onChange={(e) => {
            setHeading(e.target.value);
          }}
          name="heading"
        />
        <Textarea
          className="w-full h-96  border bg-gray-200 border-gray-500 rounded-md p-2 "
          size="lg"
          label="Description"
          value={content}
          onChange={(e) => {
            setContent(e.target.value);
          }}
        />
       
        <Button
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          Edit
        </Button>
      </div>
    </div>
  );
}

export default EditWhoAmIMyStory;
