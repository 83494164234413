const Training = () => {
  return (
    <article class=" flex items-center flex-col py-10">
      <div class="container">
        <div className="text-4xl text-center font-poppins font-extrabold">Trainings</div>
        <div className="text-xl mt-2 text-center font-poppins ">
          Unlock your potential with these training activites
        </div>



        <div class="grid grid-cols-1 md:grid-cols-2 gap-5 mt-8 w-full ">

          <div class="training-card">
            <img className="rounded-lg" src={require("../../assets/home/events/lifeCoaching.JPG")} alt="" />
            <a href="/lifeCoaching">
              <div className="font-poppins font-semibold text-md md:text-lg text-center hover:text-blue-600">Life Coaching</div>
            </a>
          </div>
          <div class="training-card">
            <img className="rounded-lg" src={require("../../assets/home/events/selfMastery.jpg")} alt="" />
            <a href="/selfMastery">
              <div className="font-poppins font-semibold text-md md:text-lg text-center hover:text-blue-600">Self Mastery</div>
            </a>
          </div>
          <div class="training-card">
            <img className="rounded-lg" src={require("../../assets/home/events/liveYourDestiny.JPG")} alt="" />
            <a href="/designYourDestiny">
              <div className="font-poppins font-semibold text-md md:text-lg text-center hover:text-blue-600">Design Your Destiny</div>
            </a>
          </div>
          <div class="training-card">
            <img className="rounded-lg" src={require("../../assets/home/events/highPerformance.jpg")} alt="" />
            <a href="/highPerformance">
              <div className="font-poppins font-semibold text-md md:text-lg text-center hover:text-blue-600">High Performance</div>
            </a>
          </div>

        </div>


      </div>

    </article >
  );
};

export default Training;
