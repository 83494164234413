import React from "react";

function Tribe({ eventData }) {
  return (
    <div>
      {eventData ? (
        eventData.tribe ? (
          eventData.tribe.length ? (
            <article class="min-w-full flex items-center justify-center flex-col container my-8 ">
              <div className="text-3xl sm:text-4xl mb-7 font-poppins font-bold text-center ">
                The Tribe
              </div>
              <div className="text-center  max-w-full md:max-w-[70vw] lg:max-w-[60vw] font-poppins text-base leading-tight  sm:leading-snug lg:leading-normal">
                {eventData.tribe}
              </div>
            </article>
          ) : null
        ) : null
      ) : (
        <div>Loading</div>
      )}
    </div>
  );
}

export default Tribe;
