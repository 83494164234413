const Slideshow= () =>{
    return (
        <article className="relative h-[700px] lg:min-h-screen flex items-center justify-center flex-col bg-no-repeat bg-cover sm:flex hidden">
        <div className="slideshow-bg2 absolute h-[700px] lg:min-h-screen w-[100vw]"></div>
        <div
          id="slideshow"
          className="my-20 absolute h-[700px] lg:min-h-screen w-[100vw]"
        ></div>
        <div className="slideshow-bg1 absolute h-[100%] w-[100vw] opacity-[0.77]"></div>
        <div className="text-center text-white z-10">
          <h1 className="text-6xl md:text-7xl font-extrabold">
            Re-imagined to connect you
          </h1>
        </div>
      </article>
    )
};

export default Slideshow;