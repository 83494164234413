import "./liveyourdestiny.css";
import ReachOut from "../../Components/Footer/ReachOut";
import Links from "../../Components/Footer/Links";
import Foot from "../../Components/Footer/Foot";
import GrowthResources from "../../Components/Trainings/Growth";
import Vid from "../../Components/Trainings/Vid";
import LiveYourDestinyTop from "../../Components/Trainings/LiveYourDestiny/LiveYourDestinyTop";
import LiveYourDestinyDesc from "../../Components/Trainings/LiveYourDestiny/LiveYourDestinyDesc";
import LiveYourDestinyPrinciples from "../../Components/Trainings/LiveYourDestiny/LiveYourDestinyPrinciples";
import LiveYourDestinyVideo from "../../Components/Trainings/LiveYourDestiny/LiveYourDestinyVideo";
import Catch from "../../Components/Trainings/LiveYourDestiny/Catch";
import BodhieComes from "../../Components/Trainings/LiveYourDestiny/BodhieComes";
import OurExperience from "../../Components/Trainings/LiveYourDestiny/OurExperience";



const LiveYourDestiny = () =>{
    return (
        
<html lang="en">
  
  <body>
    
    <div class="">
    
      
      <LiveYourDestinyTop />
      
      <LiveYourDestinyVideo />

      <div class="description-box">
        <div class="desc-para">
          <LiveYourDestinyDesc />
          
          <LiveYourDestinyPrinciples />
          <Catch />
          <BodhieComes />
          <OurExperience />
          
        </div>
        
            
              
              <div className="text-2xl sm:text-4xl font-poppins font-extrabold text-center mb-2">More About Live Your Destiny</div>

            
            <Vid />
          
        
      </div>
      
      <GrowthResources />
      <div>
          <ReachOut />
          <Links />
          <Foot />
          </div>
    </div>
   
    {/* <script>
      $(document).ready(function () {
        $(".menu-icon").on("click", function () {
          $("nav ul").toggleClass("showing");
        });
      });

      $(window).on("scroll", function () {
        if ($(window).scrollTop()) {
          $("nav").addClass("black");
        } else {
          $("nav").removeClass("black");
        }
      });
    </script> */}
  </body>
</html>


    )
};

export default LiveYourDestiny;