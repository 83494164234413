import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
// import "./style.css";

import HomePage from "./pages/homePage/Home";
import MyStoryPage from "./pages/myStoryPage/MyStory";
import EventsPage from "./pages/eventsPage/Events";
import VideosPage from "./pages/videosPage/Videos";
import TrainingPage from "./pages/trainingPage/Training";
import MembershipPage from "./pages/membershipPage/Membership";
import ContactPage from "./pages/contactPage/Contact";
import "./App.css";
import Nav from "./Components/Nav/Nav";
import TermsPage from "./pages/TermsPages/Terms";
import PrivacyPage from "./pages/TermsPages/Privacy";
import LifeCoaching from "./pages/trainings/lifeCoaching";
import SelfMastery from "./pages/trainings/selfMastery";
import LiveYourDestiny from "./pages/trainings/liveYourDestiny";
import HighPerformance from "./pages/trainings/highPerformace";
import DeepSleep from "./pages/programs/deepSleep";
import Yoga from "./pages/programs/yoga";
import OvercomeAnxiety from "./pages/programs/overcomeAnxiety";

import Retreat from "./pages/eventsPage/Retreat/retreat";
import ScrollToTop from "./Components/Common/ScrollToTop";
import LiveDestiny from "./pages/eventsPage/liveDestiny/liveDestiny";
import { useEffect, useState } from "react";
// import ReactModal from "react-modal";
import Modal from "./Components/Common/Modal";
import { useAuthState } from "react-firebase-hooks/auth";

import CommonForm from "./Components/Form/CommonForm";

import { AuthContextProvider } from "./context/AuthContext";
import Protected from "./Components/Common/Protected/Protected";
import Login from "./Components/Login/Login";
import { auth, db } from "./firebase";
import { collection, getDocs, addDoc, doc, setDoc } from "firebase/firestore";
import UsersPage from "./Components/Users/UsersPage";
import SuperAdminProtected from "./Components/Common/Protected/SuperAdminProtected";
import AdminMain from "./Components/AdminPages/AdminMain";
import YtLinksEdit from "./Components/AdminPages/YtLinksEdit";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TestimonialEdit from "./Components/AdminPages/TestimonialEdit";
import EditWelcomeHomePage from "./Components/AdminPages/HomePage/EditWelcomeHomePage";
import AdminProtected from "./Components/Common/Protected/AdminProtected";
import EditAboutMeHomePage from "./Components/AdminPages/HomePage/EditAboutMeHomePage";
import EditEventsHomePage from "./Components/AdminPages/HomePage/EditEventsHomePage";
import EditEventsPage from "./Components/AdminPages/EditEventsPage";
import NewEventPage from "./pages/eventsPage/NewEventPage";
import CommonEventPage from "./pages/eventsPage/CommonEventPage";
import EditMyStoryBanner from "./Components/AdminPages/MyStoryPage/EditMyStoryBanner";
import EditMyStoryMission from "./Components/AdminPages/MyStoryPage/EditMyStoryMission";
import EditAboutMeMyStoryPage from "./Components/AdminPages/MyStoryPage/EditAboutMeMyStoryPage";
import EditEventsFrontPage from "./Components/AdminPages/EditEventsFrontPage";
import AdminForms from "./Components/Users/AdminForms";
import AdminSpecificForm from "./Components/Users/AdminSpecificForm";
import EditPointsMyStoryPage from "./Components/AdminPages/MyStoryPage/EditPointsMyStoryPage";
import EditWhoAmIMyStory from "./Components/AdminPages/MyStoryPage/EditWhoAmIMyStory";
import EditBookMeForHome from "./Components/AdminPages/HomePage/EditBookMeForHome";
import EditSocialHome from "./Components/AdminPages/HomePage/EditSocialHome";
import EditBannerVideosPage from "./Components/AdminPages/EditBannerVideosPage";
import EditAboutCoach from "./Components/AdminPages/EditAboutCoach";

function App() {
  return (
    <div className="App  font-poppins ">
      <AuthContextProvider>
        <BrowserRouter>
          <ScrollToTop>
            <ToastContainer />
            <Nav></Nav>
            <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route exact path="/my-story" element={<MyStoryPage />} />
              <Route exact path="/events" element={<EventsPage />} />
              <Route exact path="/videos" element={<VideosPage />} />
              <Route exact path="/training" element={<TrainingPage />} />
              <Route
                exact
                path="/admin"
                element={
                  <AdminProtected>
                    <AdminMain />
                  </AdminProtected>
                }
              />
              <Route
                exact
                path="/admin/youtube-links"
                element={
                  <AdminProtected>
                    <YtLinksEdit />
                  </AdminProtected>
                }
              />
              <Route
                exact
                path="/admin/testimonials"
                element={
                  <AdminProtected>
                    <TestimonialEdit />
                  </AdminProtected>
                }
              />
              <Route
                exact
                path="/admin/home/welcome"
                element={
                  <AdminProtected>
                    <EditWelcomeHomePage />
                  </AdminProtected>
                }
              />
              <Route
                exact
                path="/admin/home/about-me"
                element={
                  <AdminProtected>
                    <EditAboutMeHomePage />
                  </AdminProtected>
                }
              />

              <Route
                exact
                path="/admin/home/book-me-for"
                element={
                  <AdminProtected>
                    <EditBookMeForHome />
                  </AdminProtected>
                }
              />
              <Route
                exact
                path="/admin/home/social-media"
                element={
                  <AdminProtected>
                    <EditSocialHome />
                  </AdminProtected>
                }
              />

              <Route
                exact
                path="/admin/home/events"
                element={
                  <AdminProtected>
                    <EditEventsHomePage />
                  </AdminProtected>
                }
              />

              <Route
                exact
                path="/admin/events/events-main"
                element={
                  <AdminProtected>
                    <EditEventsPage />
                  </AdminProtected>
                }
              />
              <Route
                exact
                path="/admin/events/events-front"
                element={
                  <AdminProtected>
                    <EditEventsFrontPage />
                  </AdminProtected>
                }
              />
              <Route
                exact
                path="/admin/events/about-coach"
                element={
                  <AdminProtected>
                    <EditAboutCoach />
                  </AdminProtected>
                }
              />
              <Route
                exact
                path="/admin/my-story/banner"
                element={
                  <AdminProtected>
                    <EditMyStoryBanner />
                  </AdminProtected>
                }
              />

              <Route
                exact
                path="/admin/my-story/about-me"
                element={
                  <AdminProtected>
                    <EditAboutMeMyStoryPage />
                  </AdminProtected>
                }
              />

              <Route
                exact
                path="/admin/my-story/who-am-i"
                element={
                  <AdminProtected>
                    <EditWhoAmIMyStory />
                  </AdminProtected>
                }
              />

              <Route
                exact
                path="/admin/my-story/points"
                element={
                  <AdminProtected>
                    <EditPointsMyStoryPage />
                  </AdminProtected>
                }
              />

              <Route
                exact
                path="/admin/my-story/mission"
                element={
                  <AdminProtected>
                    <EditMyStoryMission />
                  </AdminProtected>
                }
              />
              <Route
                exact
                path="/admin/videos/banner"
                element={
                  <AdminProtected>
                    <EditBannerVideosPage />
                  </AdminProtected>
                }
              />
              <Route
                exact
                path="/admin/users"
                element={
                  <SuperAdminProtected>
                    <UsersPage />
                  </SuperAdminProtected>
                }
              />
              <Route
                exact
                path="/admin/forms"
                element={
                  <SuperAdminProtected>
                    <AdminForms />
                  </SuperAdminProtected>
                }
              />
              <Route
                exact
                path="/admin/forms/:id"
                element={
                  <SuperAdminProtected>
                    <AdminSpecificForm />
                  </SuperAdminProtected>
                }
              />
              <Route
                exact
                path="/membership"
                element={
                  <Protected>
                    <MembershipPage />
                  </Protected>
                }
              />
              <Route path="/login" element={<Login />} />
              <Route exact path="/contact" element={<ContactPage />} />
              <Route exact path="/terms" element={<TermsPage />} />
              <Route exact path="/privacy" element={<PrivacyPage />} />
              <Route exact path="/life-coaching" element={<LifeCoaching />} />
              <Route exact path="/self-mastery" element={<SelfMastery />} />
              <Route
                exact
                path="/live-your-destiny"
                element={<LiveYourDestiny />}
              />
              <Route
                exact
                path="/high-performance"
                element={<HighPerformance />}
              />
              <Route exact path="/deep-sleep" element={<DeepSleep />} />
              <Route exact path="/yoga" element={<Yoga />} />
              <Route
                exact
                path="/overcome-anxiety"
                element={<OvercomeAnxiety />}
              />
              <Route
                exact
                path="/event/:eventSlug"
                element={<CommonEventPage />}
              />
              <Route exact path="/form/:slug" element={<CommonForm />} />
            </Routes>
          </ScrollToTop>
        </BrowserRouter>
      </AuthContextProvider>
    </div>
  );
}

export default App;
