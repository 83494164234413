import React, { useEffect, useState } from "react";
import Button from "../../../Components/Common/Button";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase";

function AboutCoach() {


  const [data, setData] = useState(null);
  const getAuthor = async (docId) => {
    try {
      const docRef = doc(db, "HomePage", docId);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        setData(docSnapshot.data());
      } else {
        console.log("Document not found");
      }
    } catch (error) {
      console.log("Error getting document:", error);
    }
  }
  useEffect(() => {
    getAuthor("aboutCoach");
  }, []);

   if (data === null) {
     return <div>Loading...</div>;
   }


  return (
    <article class=" flex items-center flex-col  container gap-10 py-10">
      <div className="event-info w-full grid grid-cols-2 gap-20 ">
        <div>
          <img
            // src={require("../../../assets/home/section-2-img-1.png")}
            src={data.image ? data.image : ""}
            alt=""
          />
        </div>
        <div>
          <div className="font-poppins text-3xl font-extrabold">
            {data.heading}
          </div>
          <div className="font-poppins text-3xl font-bold">
            {data.coachName}
          </div>

          <div className="text-base leading-tight text-center sm:text-justify   py-[2.5%] font-poppins ">
           {data.content}
            <br />
          </div>
          <div className="flex justify-center md:justify-start">
            <Button name={data.buttonText} href="/my-story" />
          </div>
        </div>
      </div>
    </article>
  );
}

export default AboutCoach;
