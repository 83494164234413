const LiveYourDestinyTop = () => {
  return (
    <div class="title-div">
      <div className="text-4xl sm:text-6xl font-black pb-2 font-poppins  ">
        LIVE YOUR DESTINY
      </div>
      <p class="tagline ">
        It is in your moments of decision that your destiny is shaped.
      </p>
    </div>
  );
};

export default LiveYourDestinyTop;
