import Mission from "./Mission";
import "./animation.css";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";


import React from "react";

function MyStoryAnimation() {
  const [data, setData] = useState(null);
  const getMissions = async (docId) => {
    try {
      const docRef = doc(db, "MyStoryPage", docId);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        setData(docSnapshot.data());
        // console.log(data, "akakkak")
        // console.log(docSnapshot.data() , "kkkkk");
      } else {
        console.log("Document not found");
      }
    } catch (error) {
      console.log("Error getting document:", error);
    }
  };

  useEffect(() => {
    getMissions("Mission");
  }, []);

  return (
    <article class="min-h-screen flex items-center flex-col py-10  ">
      <div class="container flex items-center flex-col gap-6 sm:gap-12">
        <div class="flex flex-col items-center justify-center">
          <div class="text-3xl md:text-5xl text-center font-poppins font-bold">
            My Mission
          </div>
        </div>
        {data && data.missions && data.missions.length ? (
          <div>
            {data.missions.map((data, index) => {
              return (
                <Mission
                  key={index}
                 data={data}
                />
              );
            })}
          </div>
        ) : null}

      
      </div>
    </article>
  );
}

export default MyStoryAnimation;
