import React, { useEffect, useState } from 'react'
import { doc } from 'firebase/firestore';
import { db } from '../../firebase';
import { getDoc } from 'firebase/firestore';

function VideosTop() {

    const [data, setData] = useState(null);
    const getBannerVideosPage = async (docId) => {
      try {
        const docRef = doc(db, "VideosPage", docId);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          setData(docSnapshot.data());
          console.log(docSnapshot.data());
        } else {
          console.log("Document not found");
        }
      } catch (error) {
        console.log("Error getting document:", error);
      }
    };

    useEffect(() => {
      getBannerVideosPage("Banner");
    }, []);



  return (
    <article
      class="first-article-child relative flex  flex-col text-center bg-gray-200"
      style={{ "min-height": "fit-content" }}
    >
      <div className="h-[100vh] w-[100%] ">
        <img
          src={data && data.image}
          className="h-full w-full object-cover"
        ></img>
      </div>

      <div className='absolute left-[30%] top-[50%] flex flex-col justify-center items-center text-white '>
        <div className="text-6xl sm:text-7xl  font-poppins  font-extrabold mb-10">
          {data && data.mainHeading}
        </div>
        <div className="text-2xl md:text-4xl font-poppins font-medium ">
          {data && data.heading}
        </div>
      </div>
    </article>
  );
}

export default VideosTop