import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import Button from "../../Components/Common/Button";
import { slugify } from "../../Components/Common/CommonFunction";
import { useNavigate } from "react-router-dom";
import AboutCoach from "./AboutCoach/AboutCoach";

function NewEventPage() {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();
  const getEvents = async () => {
    const querySnapshot = await getDocs(collection(db, "events"));
    const array = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      array.push(data);
    });
    setEvents(array.reverse());
  };
  useEffect(() => {
    getEvents();
  }, []);

  return (
    <div>
      {events && events.length > 0 ? (
        events.map((data) => {
          return (
            <div>
              <article class=" flex items-center flex-col container gap-10 py-10">
                <div className="event-info w-full grid grid-cols-2 gap-20 ">
                  <div>
                    <img src={data.homeImage ? data.homeImage : ""} alt="" />
                  </div>
                  <div>
                    <div className="font-poppins text-3xl font-extrabold">
                      {data.name} 
                    </div>
                    <div className=" font-poppins font-normal text-xl leading-7 py-1 ">
                      {data.location
                        ? data.location
                        : "will be available soon "}
                    </div>
                    <label className="bg-gray-200 ">
                      {" "}
                      {data.date ? data.date : "will be available soon "}
                    </label>
                    <div className="text-base leading-tight text-center sm:text-justify   py-[2.5%] font-poppins ">
                      {data.description1}
                      <br />
                    </div>
                    <div className="flex justify-center md:justify-start">
                      <Button
                        name={data.name}
                        onClick={() => {
                          navigate(`/event/${slugify(data.name)}`, data);
                        }}
                      ></Button>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          );
        })
      ) : (
        <div>loading</div>
      )}
      <AboutCoach />
      
    </div>
  );
}

export default NewEventPage;
