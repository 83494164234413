import React from 'react'
import { useEffect, useState } from "react";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../../firebase";


function WhoWasI() {
  const [data, setData] = useState(null);
  const getAboutMe = async (docId) => {
    try {
      const docRef = doc(db, "MyStoryPage", docId);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        setData(docSnapshot.data());
        console.log(data, "akakkak")
      } else {
        console.log("Document not found");
      }
    } catch (error) {
      console.log("Error getting document:", error);
    }
  };

  useEffect(() => {
    getAboutMe("AboutMe");
  }, []);

  return (
    <div className="container flex flex-col md:flex-row gap-5 py-10 " >
        <div className="px-[2%] sm:px-[5%]">
        <img
          src={data && data.image}
              // src={require("../../assets/story/whowasi.png")}
              alt=""
              className="object-contain md:rounded-3xl h-[400px] "
            />
            </div>

            <div className="flex-1 flex items-center md:items-start text-center md:text-left  justify-center flex-col  font-poppins sm:pr-[10%]">
        <div className="text-3xl sm:text-4xl text-center md:text-left  justify-between font-poppins font-extrabold underline  underline-offset-[8px] decoration-blue-500 mb-5">{data && data.heading }</div>
              <div className=" md:text-left text-base leading-tight  sm:leading-snug lg:leading-normal text-center " >
{data && data.content1}                <br />
                <br />
{data && data.content2}              
              </div>
            </div>
          </div>
  )
}

export default WhoWasI