import React from "react";

function Agreement() {

  return (
    <>
      <div></div>
      <div className="flex flex-col w-[90vw] sm:w-[75vw] lg:w-[60vw] mx-auto my-8 gap-2">
        <div>
          <div className="flex font-semibold underline">
            Payment Information:
          </div>
          Payment Method (Bank Transfer)<br></br>
          <br></br>
          Name : Innersky<br></br>
          HDFC Bank Branch : BTM Layout, Bangalore.<br></br>
          IFSC code :HDFC0006429 <br></br>
          A/C NO : 50200075645436
          <br></br>
          <br></br>
          Other Payment methods accepted include Bank transfers, UPI, Credit
          Card, Debit Card and payment link.
          <br></br>
          <div className="flex font-semibold underline">
            <br></br>Agreement to the retreat fee and payment schedule.
            <br></br>
            Terms and Conditions:
          </div>
          <br></br>
          <span className="flex font-semibold underline">
            Reservation and Payment:
          </span>
          A non-refundable deposit is required to secure your reservation. Full
          payment must be received before the retreat commences to confirm your
          participation.
          <br></br>
          <br></br>
          <span className="flex font-semibold underline">
            Cancellation Policy:
          </span>
          Cancellations made 15 days or more before the retreat start date will
          receive a 50% refund.<br></br> Cancellations made within 3days of the
          retreat start date are non-refundable. In the event of cancellation,
          participants may transfer their reservation to another eligible
          participant or use it for the next retreat.
          <br></br>
          <br></br>
          <span className="flex font-semibold underline">
            Health and Wellness:
          </span>
          Participants are responsible for informing the retreat organizers of
          any medical conditions, allergies, or dietary restrictions.<br></br>{" "}
          The retreat organizers are not liable for any injury, illness, or
          other health-related issues during or after the retreat.
          <br></br>
          <br></br>
          <span className="flex font-semibold underline">Code of Conduct:</span>
          Participants are expected to respect the rules and guidelines set by
          the retreat organizers and the venue.<br></br> Disruptive behaviour
          may result in removal from the retreat without a refund.
          <br></br>
          <br></br>
          <span className="flex font-semibold underline">Liability:</span>
          The retreat organizers are not responsible for any loss, damage, or
          theft of personal belongings. <br></br>Participants are advised to
          secure travel insurance that covers cancellations and emergencies.
          <br></br>
          <br></br>
          <span className="flex font-semibold underline">
            Photography and Media:
          </span>
          Participants may be photographed or recorded during the retreat for
          promotional purposes.<br></br> Notify the organizers if you do not
          wish to be included in promotional materials.
          <br></br>
          <br></br>
          <span className="flex font-semibold underline">Agreement:</span>I have
          read and agree to the terms and conditions outlined above. I
          understand that failure to comply with these terms may result in my
          removal from the wellness retreat without a refund.
          <br></br>
          {/* <div className="flex flex-col sm:flex-row font-semibold underline items-center gap-2">
            <div className="">
              Signature: [Type your name as a digital signature]
            </div>
            <input
              className=" p-2 sm:p-3 flex-1  rounded-xl  border-2 border-primary font-poppins  self-center "
              name="signature"
              placeholder=""
              type="text"
              required={true}
            />
          </div> */}
          <br></br>
        </div>
        <div>
          Thank you for your registration. We look forward to embarking on this
          transformative journey together!
        </div>
      </div>
    </>
  );
}

export default Agreement;
