import React from "react";
import { useNavigate } from "react-router-dom";

function Button({ name, href, onClick, hrefProps }) {
  const navigate = useNavigate();

  return (
    <div
      className="bg-primary hover:bg-blue-400 text-white text-base  font-semibold py-2 px-6 rounded-full  max-w-max font-poppins cursor-pointer "
      onClick={() => {
        if (onClick) {
          onClick();
        } else if (href) {
          navigate(href, hrefProps);
        }
      }}
    >
      {name}
    </div>
  );
}

export default Button;
