import React from "react";
import ReactModal from "react-modal";
import Form from "./Form";
import { GrClose } from "react-icons/gr";

function Modal({ popup, setPopup }) {
  const customStyles = {
    content: {
      top: "55%",
      left: "50%",
      right: "auto",
      minwidth: "350px",
      bottom: "auto",
      marginRight: "-40%",
      transform: "translate(-50%, -50%)",
      padding: 0,
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "black ",
      opacity: "98%",
    },
  };
  return (
    <div className=" z-30 ">
      <ReactModal
        isOpen={popup}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => setPopup(false)}
        style={customStyles}
        const
        handleClick
        contentLabel="Example Modal"
      >
        <div className=" w-full flex justify-end sm:px-4  px-2 py-1 sm:py-0 sm:pt-1 items-center cursor-pointer ">
          <GrClose size="22" onClick={() => setPopup(false)}></GrClose>
        </div>

        <div className="flex flex-col    lg:flex-row md:justify-evenly lg:gap-4 sm:m-2 sm:p-0  w-[75vw] sm:w-[60vw] ">
          <div className=" flex flex-col-reverse   justify-between max-w-[450px]">
            <div className=" h-full   ">
              <img
                className=" w-full object-cover  sm:h-60  h-40 lg:h-64 "
                src={require("../../IMG/IMG/MODAL/ec440d57-2ff7-44e1-bce7-f77e39259a47 (2).jpg")}
                alt="i"
              ></img>
            </div>

            <div className=" hidden lg:flex ">
              <img
                className=" w-full object-cover sm:h-56 lg:h-64  "
                src={require("../../IMG/IMG/MODAL/9d0955e5-bd58-40f1-92c7-da4af73a8fef (1).jpg")}
                alt="i"
              ></img>
            </div>
            {/* <div className=" bg-green-300 ">
              <img
                className=" w-full object-fit   h-[20vh] "
                src={require("../../IMG/IMG/MODAL/IMG_0450.jpg")}
                alt="i"
              ></img>
            </div> */}
            {/* <div className="  ">
              <img
                className="w-full h-full"
                src={require("../../IMG/IMG/MODAL/Screen Shot 2019-04-16 at 11.47.33.jpg")}
                alt="i"
              ></img>
            </div> */}
          </div>
          <div className=" max-w-[450px] ">
            <Form></Form>
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

export default Modal;
