import React from "react";

function EventImage({ frontData }) {
  return (
    <>
      <div className=" flex-col w-full flex items-center mb-5 px-[5%] gap-2 sm:grid grid-cols-4">
        {frontData ? (
          frontData.images ? (
            frontData.images.map((data) => {
              return (
                <img
                  className="  object-cover    h-56 w-full rounded-lg"
                  src={data.image}
                  alt="noImage"
                />
              );
            })
          ) : null
        ) : (
          <div>Loading</div>
        )}
      </div>
    </>
  );
}

export default EventImage;
