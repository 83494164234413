import "./terms.css";

const TermsPage = () => {
    return (
        
<html lang="en">


<body class="overflow-x-hidden max-w-[100vw]">
    
    <article className="toc container ml-10 mt-[100px]">
        <h1 >Terms and Conditions</h1>

        <div className="font-poppins">Last updated: February 07, 2022 <br />
            Please read these terms and conditions carefully before using Our Service. <br />
            Interpretation and Definitions</div>
        <h2>Interpretation</h2>
        <div >The words of which the initial letter is capitalized have meanings defined under the following conditions.<br />
            The following definitions shall have the same meaningregardless of whether they appear in singular or in
            plural.
        </div>

        <h2>Definitions</h2>

        <div>Affiliate means an entity that controls, is controlled by or is under common control with a party, where<br />
            "control" means ownership of 50% or more of the shares, equityinterest or other securities entitled to vote<br />
            for election of directors or other managing authority.<br />
            Country refers to: Karnataka, India<br />
            Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to bodhievik.<br />
            Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.<br />
            Service refers to the Website.<br />
            Terms and Conditions (also referred as "Terms") mean these Terms and Conditions that form the entire<br />
            agreement between You and the Company regarding the use of the Service. This Terms and Conditions agreement<br />
            has been created with the help of the Terms and Conditions Generator.<br />
            Third-party Social Media Service means any services or content (including data, information, products <br />
            services) provided by a third-party that may be displayed, included or made available by the Service.<br />
            Website refers to bodhievik, accessible from <a href="http://www.bodhievik.com">http://www.bodhievik.com</a><br />
            You means the individual accessing or using the Service, or the company, or other legal entity on behalf of<br />
            which such individual is accessing or using the Service, as applicable.<br />
        </div>

        <h2>Acknowledgment</h2>
        <div>These are the Terms and Conditions governing the use of this Service and the agreement that operates between<br />
            You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding<br />
            the use of the Service.<br />
            Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms<br />
            and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the<br />
            Service.<br />
            By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with<br />
            any part of these Terms and Conditions then You may not access the Service.<br />
            You represent that you are over the age of 18. The Company does not permit those under 18 to use the<br />
            Service.<br />
            Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the<br />
            Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection,<br />
            use and disclosure of Your personal information when You use the Application or the Website and tells You<br />
            about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before<br />
            using Our Service.<br />
        </div>

        <h2>Links to Other Websites</h2>
        <div>Our Service may contain links to third-party web sites or services that are not owned or controlled by the<br />
            Company.<br />
            The Company has no control over, and assumes no responsibility for, the content, privacy policies, or<br />
            practices of any third party web sites or services. You further acknowledge and agree that the Company shall<br />
            not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused<br />
            by or in connection with the use of or reliance on any such content, goods or services available on or<br />
            through any such web sites or services.<br />
            We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or<br />
            services that You visit.<br />
        </div>
        <h2>Termination</h2>
        <div>We may terminate or suspend Your access immediately, without prior notice or liability, for any reason<br />
            whatsoever, including without limitation if You breach these Terms and Conditions.<br />
            Upon termination, Your right to use the Service will cease immediately.<br />
        </div>

        <h2>Limitation of Liability</h2>
        <div>Notwithstanding any damages that You might incur, the entire liability of the Company and any of its<br />
            suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be<br />
            limited to the amount actually paid by You through the Service or 100 USD if You haven't purchased anything<br />
            through the Service.<br />
            To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable<br />
            for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to,<br />
            damages for loss of profits, loss of data or other information, for business interruption, for personal<br />
            injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service,<br />
            third-party software and/or third-party hardware used with the Service, or otherwise in connection with any<br />
            provision of this Terms), even if the Company or any supplier has been advised of the possibility of such<br />
            damages and even if the remedy fails of its essential purpose.<br />
            Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or<br />
            consequential damages, which means that some of the above limitations may not apply. In these states, each<br />
            party's liability will be limited to the greatest extent permitted by law.<br />
        </div>

        <h2>
            "AS IS" and "AS AVAILABLE" Disclaimer
        </h2>
        <div>The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of<br />
            any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf<br />
            of its Affiliates and its and their respective licensors and service providers, expressly disclaims all<br />
            warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all<br />
            implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and<br />
            warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without<br />
            limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of<br />
            any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work<br />
            with any other software, applications, systems or services, operate without interruption, meet any<br />
            performance or reliability standards or be error free or that any errors or defects can or will be<br />
            corrected.<br />
            Without limiting the foregoing, neither the Company nor any of the company's provider makes any<br />
            representation or warranty of any kind, express or implied: (i) as to the operation or availability of the<br />
            Service, or the information, content, and materials or products included thereon; (ii) that the Service will<br />
            be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or<br />
            content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent<br />
            from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or<br />
            other harmful components.<br />
            Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable<br />
            statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You.<br />
            But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest<br />
            extenten forceable under applicable law.<br />
        </div>
        <h2>Governing Law</h2>
        <div>The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the<br />
            Service. Your use of the Application may also be subject to other local, state, national, or international<br />
            laws.<br />
        </div>
        <h2>Disputes Resolution</h2>
        <div>If You have any concern or dispute about the Service, You agree to first try to resolve the dispute<br />
            informally by contacting the Company.</div><br />
        <h2>For European Union (EU) Users</h2>
        <div>If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the<br />
            country in which you are resident in.</div><br />
        <h2>United States Legal Compliance</h2>
        <div>You represent and warrant that (i) You are not located in a country that is subject to the United States<br />
            government embargo, or that has been designated by the United States government as a "terrorist supporting"<br />
            country, and (ii) You are not listed on any United States government list of prohibited or restricted<br />
            parties.</div><br />
        <h2>Severability and Waiver</h2>
        <div>Severability<br />
            If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and<br />
            interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable<br />
            law and the remaining provisions will continue in full force and effect.<br />
            Waiver<br />
            Except as provided herein, the failure to exercise a right or to require performance of an obligation under<br />
            these Terms shall not effect a party's ability to exercise such right or require such performance at any<br />
            time thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach.<br />
        </div>

        <h2>Translation Interpretation</h2>
        <div>These Terms and Conditions may have been translated if We have made them available to You on our Service. You<br />
            agree that the original English text shall prevail in the case of a dispute.<br />
        </div>

        <h2>Changes to These Terms and Conditions</h2>
        <div>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is<br />
            material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking<br />
            effect. What constitutes a material change will be determined at Our sole discretion.<br />
            By continuing to access or use Our Service after those revisions become effective, You agree to be bound by<br />
            the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website<br />
            and the Service.<br />
        </div>

        <h2>
            Contact Us<br />
        </h2>
        <div>If you have any questions about these Terms and Conditions, You can contact us:<br />
            By email: bodhievik@gmail.com<br />
            By phone number: 00919886009007<br />
            By mail: 452, 22nd cross, 3rd block jayanagar, bangalore 560011<br /><br />
        </div>
    </article>

    

</body>

</html>
    )
};

export default TermsPage;