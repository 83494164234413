const growthResources = () =>{
    return (
        <div class="">
        <div className="text-center ">
          <div className="text-2xl sm:text-3xl  font-poppins font-bold">Growth Resources</div>
        </div>

        <div className="flex flex-col sm:flex-row  sm:mx-[8%] justify-evenly ">

        <div class=" mx-[2%] sm:w-full flex flex-col  items-center my-4 gap-4">
            <div class="">
              <a href="/selfMastery">
                <div className="text-2xl sm:text-3xl font-poppins text-center font-bold text-blue-700">Why do we need Self-Mastery?</div>
              </a>
            </div>
            <div class="">
              <img src={require("../../IMG/IMG/compressed/desig-min.jpeg")} alt="" />
            </div>
            <div class="">
              <div className="text-center text-base font-poppins leading-tight  sm:leading-snug lg:leading-normal  ">
                Self-Mastery is the mastery of self. To be in the driver’s seat,
                completely in control of your life. It is a need we recognize
                when we look in the mirror, when we pass established deadlines
                without completing the required work, when we fail to accomplish
                anything substantial with our passions, or on one of those days
                when we ponder on our lives and wonder how far we could have
                gotten if we had the self-control and determination to succeed
                at what we wanted to do.
              </div>
            </div>
          </div>


          <div class=" mx-[2%] sm:w-full flex flex-col items-center my-4 gap-4">
            <div class="">
              <a href="/selfMastery">
                <div className="text-2xl sm:text-3xl font-poppins text-center font-bold text-blue-700">What happens in the retreat?</div>
              </a>
            </div>
            <div class="">
              <img src={require("../../IMG/IMG/compressed/desig-min.jpeg")} alt="" />
            </div>
            <div class="">
              <div className="text-center text-base font-poppins  ">
              The first few things that may run through your mind as you read
                more into this retreat program is, what can I expect from
                attending this retreat program? How can my life be changed by
                attending this retreat? What does the average day in Design Your
                Destiny look like?
              </div>
              </div>
            </div>
          </div>


         
        
          

          
        
      </div>

    )
};

export default growthResources;