import React, { useEffect } from "react";

import { UserAuth } from "../../../context/AuthContext";
function AdminProtected({ children }) {
  const { user, dbUser, isAdmin } = UserAuth();

  if (!user || !dbUser || !isAdmin || !dbUser.isAdmin) {
    return (
      <div className="h-screen w-screen m-20 flex items-center justify-center  text-2xl text-red-500 ">
        Not Authorized !!
      </div>
    );
  } else if (
    dbUser &&
    dbUser.isAdmin &&
    dbUser.isAdmin === true &&
    isAdmin &&
    isAdmin === true
  ) {
    return children;
  } else {
    console.log(dbUser, isAdmin, "return <Na");
  }
}

export default AdminProtected;
