const BodhieComes =() =>{
    return (
        <div>
            <div className=" text-2xl sm:text-4xl font-poppins font-bold text-center my-5">Where Bodhie Vik comes in</div>
          <div className="text-center text-base font-poppins leading-tight  sm:leading-snug lg:leading-normal">
            From participation to training sessions to a balanced set of
            activities to foster personal transformation, right down to the
            nutritional diet, Bodhie Vik considers each and every factor that
            can be utilized to get you to the next level of your journey.
            <br /><br />
            In this retreat, we will train you in self-mastery, high performance
            and life coaching, supplemented by an array of activities such as
            yoga, group therapy, dance and many more pursuits that blend
            creative energy and logical growth. Post each retreat, there will be
            follow up sessions monthly for mentors and members to catch up and
            go through the changes they have made so far, and the results they
            have seen.
          </div>
        </div>
    )
};

export default BodhieComes;