import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import { Button, Input, Textarea } from "@material-tailwind/react";
import { uploader } from "../test";
import { notify } from "../../Common/Notification";


function EditAboutMeMyStoryPagePage() {
  const [reload, setReload] = useState(false);
  const [data, setData] = useState(null);
  const [heading, setHeading] = useState("");
  const [content1, setContent1] = useState();
  const [content2, setContent2] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [progress, setProgress] = useState();

  const getAboutMeMyStoryPage = async (docId) => {
    try {
      const docRef = doc(db, "MyStoryPage", docId);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        setData(docSnapshot.data());
        setHeading(docSnapshot.data().heading);
        setContent1(docSnapshot.data().content1);
        setContent2(docSnapshot.data().content2);
        setImageUrl(docSnapshot.data().image);
        console.log(docSnapshot.data(), data);
      } else {
        console.log("Document not found");
      }
    } catch (error) {
      console.log("Error getting document:", error);
    }
  };
  useEffect(() => {
    getAboutMeMyStoryPage("AboutMe");
  }, []);

  const handleSubmit = async () => {
    const docRef = doc(db, "MyStoryPage", "AboutMe");
    const docData = {
      heading: heading,
      content1: content1,
      content2: content2,
      image: imageUrl,
    };
    console.log(docData, "doc");
    await setDoc(docRef, docData, { merge: true })
      .then((doc) => {
        setReload((prev) => !prev);
        notify({ type: "success", message: "link Saved" });
        console.log(doc, "update");
      })
      .catch((error) => {
        console.log(error, "k");
        notify({ type: "error", message: error.message });
      });

    console.log("Document written with ID: ", docRef.id);
  };
  return (
    <div className="h-full bg-gray-200 flex flex-col py-20 sm:py-32 items-center  gap-4 w-full">
      <div className="border p-2 text-xl border-primary rounded-md bg-white">
        Edit About Me My Story Page
      </div>
      <div className=" flex flex-col gap-4 items-center justify-center w-full px-10  max-w-md ">
        {" "}
        <div className="flex flex-col gap-4  items-center justify-center  p-2 w-full">
          <Input
            size="lg"
            label="Image"
            type="file"
            onChange={(e) => {
              const images = "MyStoryPage/AboutMe/";
              uploader(e.target.files[0], setImageUrl, setProgress, images);
            }}
            name="image"
          />
          <div>Image Uploaded {progress} %</div>
        </div>
        <Input
          size="lg"
          label="Heading"
          value={heading}
          onChange={(e) => {
            setHeading(e.target.value);
          }}
          name="heading"
        />
        <Textarea
          className="w-full h-96  border bg-gray-200 border-gray-500 rounded-md p-2 "
          size="lg"
          label="Description 1"
          value={content1}
          onChange={(e) => {
            setContent1(e.target.value);
          }}
        />
        <Textarea
          className="w-full h-96  border bg-gray-200 border-gray-500 rounded-md p-2 "
          size="lg"
          label="Description 2"
          value={content2}
          onChange={(e) => {
            setContent2(e.target.value);
          }}
        />
        <Button
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          Edit
        </Button>
      </div>
    </div>
  );
}

export default EditAboutMeMyStoryPagePage;
