import React from "react";

function Hotel({ eventData }) {
  return (
    <div>
      {eventData ? (
        eventData.hotelInformation ? (
          <article class="min-w-full flex items-center justify-center flex-col container mt-5">
            <div className="text-3xl sm:text-4xl mb-7 font-poppins font-bold text-center">
              {eventData.hotelInformationHeading}
            </div>
            {eventData.hotelInformation && eventData.hotelInformation.length ? (
              <div className="max-w-full mb-10 md:max-w-[70vw] lg:max-w-[60vw] font-poppins text-center sm:text-start text-base leading-tight  sm:leading-snug lg:leading-normal">
                {eventData.hotelInformation.map((data) => {
                  return (
                    <div>
                      {data.text}
                      <br />
                      <br />
                    </div>
                  );
                })}
              </div>
            ) : null}

            {eventData.hotelInformationImage &&
            eventData.hotelInformationImage.length ? (
              <div class="grid md:grid-rows-2 md:grid-cols-2 gap-4  md:max-w-[80vw] lg:max-w-[70vw]">
                {eventData.hotelInformationImage.map((data) => {
                  return <img src={data.image} alt="noImage" />;
                })}
              </div>
            ) : null}
          </article>
        ) : null
      ) : (
        <div>Loading</div>
      )}
    </div>
  );
}

export default Hotel;
