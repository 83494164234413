const LifeCoachingPoints1 = () =>{
    return (
        <div className="font-poppins ">
            <div className="text-md sm:text-2xl font-poppins font-semibold text-center  leading-tight  sm:leading-snug lg:leading-normal  ">This is where Bodhie Vik’s life coaching program steps in. In a
                  world full of life coaching initiatives, mentorship schemes and the
                  occasional pamphlet on finding life’s purpose, Bodhie Vik’s life
                  coaching mentorship stands out for three simple reasons:</div>
              <br /><br />
              <div className="text-xl sm:text-2xl font-poppins font-bold text-center">1. We’re prepared.</div>
              <br />
              <div className="text-center text-base font-poppins leading-tight  sm:leading-snug lg:leading-normal font-medium">
                True guidance and personal transformation cannot fit within 140
                characters. Bodhie Vik is trained with over 15 years of experience
                in research studies on behavioural psychology, philosophy, ancient
                healing systems, yogic science, metaphysics, mysticism, neurology
                and psychotherapy. <br /><br />
                Because life isn’t a subject. It’s an entire syllabus.
              </div>
              <br /><br />
              <div className="text-xl sm:text-2xl font-poppins font-bold text-center">2. We prioritize future-oriented transformation</div>
              <br />
              <div className="text-center text-base font-poppins leading-tight  sm:leading-snug lg:leading-normal font-medium">
                Normal therapy diagnoses a person’s flaws and behavior based on past
                cause i.e: past trauma and pivotal experiences that have caused the
                person to behave and think the way he/she does. Bodhie Vik uses
                future-oriented therapeutic processes, the focus being on catalyzing
                the breakthrough in an individual’s life through one-on-one sessions
                supplemented by life hacks, breath mastery, state change techniques,
                daily strategies and more.
              </div>
              <br /><br />
              <div className="text-xl sm:text-2xl font-poppins font-bold text-center">3. We’re experienced.</div>
              <br />
              <div className="text-center text-base font-poppins leading-tight  sm:leading-snug lg:leading-normal font-medium">
                Bodhie Vik has been the catalyst in the personal transformation of
                over 50,000 men and women across 12 countries worldwide. We
                integrate several techniques and methods in a way that’s tailored to
                the individual’s personal growth. We know what you need and how to
                get it and through our experience, we’ve understood that every
                person requires a breakthrough. The materials for it and the final
                process of metamorphosis to a transformed life are witnessed,
                studied and applied by Bodhie Vik through each conversation we have
                with you. <br /><br />
                We prioritize you. Only you. Bodhie Vik life coaches are trained
                listeners, empathetic and compassionate towards your struggles,
                non-judgmental and welcoming as a friend would be, driven and fully
                committed towards your personal growth. We notice what you don’t say
                with the same weight as what you do say in the way you communicate
                with us. Our vast experience in this field helps us understand you
                and lead you onto the right path with the right tools and strategies
                required to take you all the way. <br />
              </div>
              <br /><br />
        </div>

    )
};

export default LifeCoachingPoints1;