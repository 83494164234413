import React, { useEffect, useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import { useAuthState } from "react-firebase-hooks/auth";

import ContactUs from "../Footer/ContactUs";
import { Button, Input } from "@material-tailwind/react";
import {
  GoogleLoginButton,
  TwitterLoginButton,
} from "react-social-login-buttons";
import { auth } from "../../firebase";
import {
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
} from "@firebase/auth";
import { useLocation, useNavigate } from "react-router";
function Login() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [email, setEmail] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState("");
  const { isAdmin, superAdmin } = UserAuth();
  const [infoMsg, setInfoMsg] = useState("");

  const [initialLoading, setInitialLoading] = useState(false);
  const [initialError, setInitialError] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const { search } = location;
  const { googleSignIn, user } = UserAuth();
  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user) {
      setLoggedIn(true);
    } else {
      // user is not signed in but the link is valid
      if (isSignInWithEmailLink(auth, window.location.href)) {
        // now in case user clicks the email link on a different device, we will ask for email confirmation
        let email = localStorage.getItem("email");
        if (!email) {
          email = window.prompt("Please provide your email");
          localStorage.setItem("email", email);
        }
        // after that we will complete the login process
        setInitialLoading(true);
        signInWithEmailLink(
          auth,
          localStorage.getItem("email"),
          window.location.href
        )
          .then((result) => {
            // we can get the user from result.user but no need in this case
            console.log(result.user);
            localStorage.removeItem("email");
            setInitialLoading(false);
            setInitialError("");
            navigate("/");
          })
          .catch((err) => {
            setInitialLoading(false);
            setInitialError(err.message);
            navigate("/login");
          });
      } else {
        console.log("enter email and sign in");
      }
    }
  }, [user, search, navigate]);

  const handleLogin = () => {
    setLoginLoading(true);
    sendSignInLinkToEmail(auth, email, {
      // this is the URL that we will redirect back to after clicking on the link in mailbox
      url: "http://localhost:3000/login",
      handleCodeInApp: true,
    })
      .then(() => {
        localStorage.setItem("email", email);
        setLoginLoading(false);
        setLoginError("");
        setInfoMsg("We have sent you an email with a link to sign in");
      })
      .catch((err) => {
        setLoginLoading(false);
        setLoginError(err.message);
      });
  };
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleLogout = () => {
    auth
      .signOut()
      .then(() => {
        console.log("successfully logged out");
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(user, "user");
  return (
    <div
      className="w-screen h-screen bg-gray-200 py-32 flex items-center justify-center "
      onClick={() => {}}
    >
      {user ? (
        <div className="flex flex-col gap-2 items-center border-2 rounded border-primary p-5">
          <div className="flex justify-center items-center text-xl font-semibold">
            Hello , {user.displayName || user.email}
          </div>

          {isAdmin === true && (
            <div>
              <Button
                onClick={() => {
                  navigate("/admin");
                }}
              >
                Admin
              </Button>
            </div>
          )}
          {superAdmin === true && (
            <div className="flex flex-col gap-2">
              <Button
                onClick={() => {
                  navigate("/admin/users");
                }}
              >
                Users
              </Button>
              <Button
                onClick={() => {
                  navigate("/admin/forms");
                }}
              >
                Forms
              </Button>
            </div>
          )}

          <div>
            <Button
              onClick={() => {
                handleLogout();
              }}
            >
              Logout
            </Button>
          </div>
        </div>
      ) : (
        <div className="border p-5 flex flex-col gap-2 items-center max-w-fit">
          <div className="text-center">Sign Up</div>
          <GoogleLoginButton label="" onClick={handleGoogleSignIn}>
            <span>Sign Up with google</span>
          </GoogleLoginButton>
          {/* <TwitterLoginButton onClick={() => alert("Hello")} /> */}

          <div>-----OR-----</div>

          <Input
            size="sm"
            label="Enter Email Address"
            value={email}
            type="email"
            onChange={(e) => {
              setEmail(e.target.value);
              if (validateEmail(email)) {
                setInfoMsg(null);
              }
            }}
            name="email"
          />
          <Button
            className="w-full py-2"
            onClick={() => {
              if (email !== null && validateEmail(email)) {
                setInfoMsg(null);
                handleLogin();
              } else {
                setInfoMsg("Invalid Email");
                console.log("invalid email");
              }
            }}
          >
            {loginLoading ? <span>Logging you in</span> : <span>Login</span>}
          </Button>
          {loginError !== "" && (
            <div className="  text-red-800 p-0">{loginError}</div>
          )}
          <div className="text-red-700">{infoMsg && infoMsg}</div>
        </div>
      )}
    </div>
  );
}

export default Login;
