import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { slugify } from "../../../Components/Common/CommonFunction";

function Landing({ eventData }) {
  const navigate = useNavigate();

  return (
    <article
      className="container min-w-full relative flex justify-center flex-col h-[100vh] sm:h-[110vh] sm:py-40  bg-cover bg-center bg-image-8  bg-no-repeat"

      // style={backgroundImage = url("../../assets/events/events-bg.png")}
    >
      {eventData ? (
        <div className="content flex  z-100 flex-col text-white gap-y-6 sm:gap-y-12 pt-40">
          <div className="text-6xl sm:text-9xl font-bold  font-poppins text-center lg:text-start ">
            {eventData.name ? eventData.name : ""}
          </div>
          <div className="text-2xl md:text-5xl font-poppins font-normal  text-center lg:text-start">
            {eventData.subHeading ? eventData.subHeading : ""}
          </div>
          <div className="flex justify-center lg:justify-start  ">
            <button
              className=" w-fit bg-white text-blue-700 text-base sm:text-xl  font-bold rounded-full px-4 py-2 shadow-none hover:shadow-2xl shadow-white   "
              onClick={() => {
                navigate(`/form/${eventData.slug}`);
              }}
            >
              Apply Now
            </button>
          </div>
        </div>
      ) : (
        <div className="w-screen h-screen flex flex-col justify-center items-center  p-20 gap-4 bg-gray-200">
          Loading
        </div>
      )}
    </article>
  );
}

export default Landing;
