import React from "react";
import "./highlight.css"

function Highlight() {
  let words =
    "Manifesting Dreams,Authenticity,Focus,Leadership Skills,Growing Your Influence,Self Awareness,Thinking Bolder,Entrepreneurial Success,Enhanced Intuition,Making a Bigger Impact,Bliss,Self-Love,Aging Better,Increasing Productivity,Forgiveness,Positivity,Developing Better Physique,Resilience to Stress,Achieving Goals,Deeper Sleep,Improving Love Relationships,Improving Compassion,Confidence,Growing Your Finances,Athletic Performance,Increasing Mobility,Healing from Trauma,Deeper Meditation,Immunity,Increased Energy,Improved Spiritual Life,Improved Overall Health,Clarity on Life Vision,Courage,Overcoming Fears & Anxiety,Grit,Emotional Balance,Better Habits,Longevity,Cardio and Circulatory Health,Getting Fit,Alternative Healing,Purpose,Gaining Muscle & Strength,Greater Sense of Abundance,Achieve More,Finding Your Passion,Being Well Rounded,Resilience to Overwhelm,Conflict Management,Better Growth Mindset,Feeling of Oneness,Energy Body Awareness,Better Meditation".split(
      ","
    );

  let highlightedWords =
    "Manifesting Dreams,Leadership Skills,Thinking Bolder,Making a Bigger Impact,Increasing Productivity,Positivity,Achieving Goals,Improving Love Relationships,Growing Your Finances,Immunity,Improved Overall Health,Courage,Alternative Healing,Purpose,Greater Sense of Abundance,Better Growth Mindset"
      .toLowerCase()
      .split(",");
  return (
    <div className="words-container container flex-row hidden md:flex py-10 px-10 md:px-20 flex-wrap justify-center ">
      {words.map((word) => {
        if (highlightedWords.indexOf(word.toLowerCase()) >= 0) {
          return (
            <label className="highlighted  text-primary border-primary border-2 font-semibold rounded-full text-xs lg:text-sm   ">
              {word}
            </label>
          );
        } else {
          return <label className=" rounded-full  font-poppins  text-xs lg:text-sm font-semibold ">{word}</label>;
        }
      })}
    </div>

   
    
  );
}

export default Highlight;
