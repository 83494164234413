import { useEffect, useState } from "react";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

const StoryTop = () => {
  const [data, setData] = useState(null);
  const bannerMyStoryPage = async (docId) => {
    try {
      const docRef = doc(db, "MyStoryPage", docId);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        setData(docSnapshot.data());
        console.log(docSnapshot.data());
      } else {
        console.log("Document not found");
      }
    } catch (error) {
      console.log("Error getting document:", error);
    }
  };

  useEffect(() => {
    bannerMyStoryPage("Banner");
  }, []);

  
  return (
    <article class="">
    
      <div class="mt-5 h-[100vh] w-[100%] ">
        <img
          src={data && data.image}
          // src={require("../../assets/story/landing.png")}
          alt=""
          class="h-full w-full object-cover"
        />
      </div>



      <div className=" sm:bg-contain bg-no-repeat">
        <div class=" container flex flex-col items-center justify-end">
          <div class="animheader">
            <div class="animheader__container ">
              <div class="animheader__container__text ">My</div>

              <ul class="animheader__container__list">
                <li class="animheader__container__list__item text-left">
                  Life.
                </li>
                <li class="animheader__container__list__item text-center">
                  Journey.
                </li>
                <li class="animheader__container__list__item text-left ">
                  Story.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default StoryTop;
