import { Button, Input } from "@material-tailwind/react";
import React, { useState, useEffect } from "react";
import { uploader } from "../test";
import { db } from "../../../firebase";
import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import { notify } from "../../Common/Notification";

function EditMyStoryBanner() {
  const [progress, setProgress] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [data, setData] = useState(null);
  const [reload, setReload] = useState(false);

  const getBannerMyStory = async () => {
    const querySnapshot = await getDocs(
      collection(db, "MyStoryPage", "Banner")
    );

    querySnapshot.forEach((doc) => {
      setData(doc.data());
      setImageUrl(doc.data().image);
      console.log(doc.data(), data);
    });
  };

  useEffect(() => {
    getBannerMyStory();
  }, [reload]);

  const handleSubmit = async () => {
    const docRef = doc(db, "MyStoryPage", "Banner");
    const docData = {
      image: imageUrl,
    };
    console.log(docData, "doc");
    await setDoc(docRef, docData, { merge: true })
      .then((doc) => {
        setReload((prev) => !prev);
        notify({ type: "success", message: "link Saved" });
        console.log(doc, "update");
      })
      .catch((error) => {
        console.log(error, "k");
        notify({ type: "error", message: error.message });
      });

    console.log("Document written with ID: ", docRef.id);
  };

  return (
    <div className="h-full bg-gray-200 flex flex-col py-20 sm:py-32 items-center  gap-4 w-full">
      <div className=" flex flex-col gap-4 items-center justify-center w-full px-10  max-w-md ">
        {" "}
        <div className="flex flex-col gap-4  items-center justify-center  p-2 w-full">
          <Input
            size="lg"
            label="Image"
            type="file"
            onChange={(e) => {
              const images = "myStoryImage/";
              uploader(e.target.files[0], setImageUrl, setProgress, images);
            }}
            name="image"
          />
          <div>Image Uploaded {progress} %</div>
        </div>
        <Button
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          Edit
        </Button>
      </div>
    </div>
  );
}

export default EditMyStoryBanner;
