import React from 'react'

function OvercomeAnxietyTop() {
  return (
    <div class="title-div">
        
        
    <div className=" text-3xl sm:text-6xl font-black pb-2 font-poppins">OVERCOME <br></br>ANXIETY</div>
    <p class="tagline ">
    Here is a guided process to reduce anxiety and to become more calm
    </p>
  </div>
  )
}

export default OvercomeAnxietyTop