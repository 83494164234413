import React, { useEffect } from "react";
import { useState } from "react";

function Mission({ data }) {
  const [indexClass, setIndexClass] = useState();
  const [headingClass, setHeadingClass] = useState();
  const [colorClass, setColorClass] = useState();

  useEffect(() => {
    if (data.index % 3 === 1) {
        setIndexClass("bg-[#3774FD]");
        setHeadingClass("text-[#3774FD]");
        setColorClass("blue");
    } else if (data.index % 3 === 2) {
        setIndexClass("bg-[#00B53F]");
        setHeadingClass("text-[#00B53F]");
        setColorClass("green");
    } else if (data.index % 3 === 0) {
        setIndexClass("bg-[#FFB700]");
        setHeadingClass("text-[#FFB700]");
        setColorClass("yellow");
    }
  }, [data.index]);
     
     

  return (
    <div className="mission-row relative mt-5 flex gap-10">
      <div className={"mission-content " + colorClass}>
        <div className="count-row flex gap-10">
          <div className="flex flex-col gap-4">
            <div className="flex flex-row gap-4 justify-center sm:justify-start">
              <div
                className={
                  "w-10 h-10 rounded-full  flex justify-center items-center text-white text-lg font-bold  " +
                  indexClass
                }
              >
                {data.index}
              </div>

              <div
                className={
                  `font-poppins text-xl lg:text-3xl font-bold text-center sm:text-left ${headingClass}`   
                }
              >
                {data.heading}
              </div>
            </div>
            <div className="text-base leading-tight text-center sm:text-left">
              {data.content}
            </div>
          </div>
        </div>
      </div>
      <img
        src={data.image}
        alt=""
        className="mission-img w-[400px]"
        data-aos="fade-up-right"
      />
    </div>
  );
}

export default Mission;
