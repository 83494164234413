import React from 'react'
import Highlight from '../HomePage/Highlight/Highlight'


function MembershipMid() {
  return (
    <article class="words-container container items-center flex-col md:px-10 py-10 hidden sm:block">
          <div className="text-5xl md:text-6xl text-center  font-poppins font-bold">Explore these categories</div>
          <div className="text-center text-xl md:text-2xl font-semibold mt-4 mb- font-poppins ">
            Select up to 3 areas of growth to see your
            <br /> personalised program recommendations
          </div>

          <Highlight></Highlight> 
          
          {/* <script>
            let words = 'Manifesting Dreams,Authenticity,Focus,Leadership Skills,Growing Your Influence,Self Awareness,Thinking Bolder,Entrepreneurial Success,Enhanced Intuition,Making a Bigger Impact,Bliss,Self-Love,Aging Better,Increasing Productivity,Forgiveness,Positivity,Developing Better Physique,Resilience to Stress,Achieving Goals,Deeper Sleep,Improving Love Relationships,Improving Compassion,Confidence,Growing Your Finances,Athletic Performance,Increasing Mobility,Healing from Trauma,Deeper Meditation,Immunity,Increased Energy,Improved Spiritual Life'.split(',');

            let highlightedWords = [];

            for (let word of words) {
                if (highlightedWords.indexOf(word.toLowerCase()) >= 0) {
                    document.write(`<label class='selectable highlighted'>${word}</label>`)
                } else {
                    document.write(`<label class='selectable'>${word}</label>`)
                }
            }

            $('.words-container > label.selectable').click(e => {
                const el = $(e.currentTarget);

                if (el.hasClass('highlighted')) {
                    el.removeClass('highlighted')
                    highlightedWords = highlightedWords.filter(x => x != el.text())
                } else {
                    if (highlightedWords.length >= 3) {
                        alert("Cannot select more than three categories.")
                    } else {
                        el.addClass('highlighted')
                        highlightedWords.push(el.text())
                    }
                }
            })

        </script> */}
        </article>
  )
}

export default MembershipMid