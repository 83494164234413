const TrainingTop = () => {
  return (
    <article class="first-article-child min-w-full relative flex  flex-col ">
      <div className=" flex z-100 flex-col bg-center bg-cover bg-image-2 ">
        <div class="h-[100vh] container flex flex-col items-center justify-end">
          <div className="text-2xl md:text-3xl lg:text-4xl text-center text-white font-poppins">
            "Live As If You Were To Die Tomorrow. Learn As If You Were To Live
            Forever."
          </div>
          <div class="mt-8 w-[50%] h-1 bg-white"></div>
          <div class="h-4"></div>
        </div>
      </div>
    </article>
  );
};

export default TrainingTop;
