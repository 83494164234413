import React from "react";
import { Button, Input, Textarea } from "@material-tailwind/react";
import { uploader } from "../test";
import { notify } from "../../Common/Notification";
import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { useState } from "react";
import { useEffect } from "react";


function EditSocialHome() {
    const [socials, setSocials] = useState([]);
    const [image, setImage] = useState("");
    const [mainHeading, setMainHeading] = useState("");
    const [index, setIndex] = useState("");
    const [platform, setPlatform] = useState("");
    const [link, setLink] = useState("");
    const [progress, setProgress] = useState(0);
    const [reload, setReload] = useState(false);

    const getSocials = async () => {
        const docRef = doc(db, "HomePage", "SocialMedia");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          if (docSnap.data().socials)
          {
            const sortedSocials = docSnap
              .data()
              .socials.slice()
              .sort((a, b) => a.index - b.index); // Sort by index
            setSocials(sortedSocials);
         }
        } else {
            console.log("No such document!");
        }
    };
    useEffect(() => {
        getSocials();
    }, [reload]);

    const handleSubmit = async (data) => {
        const docRef = doc(db, "HomePage", "SocialMedia");
        const docData = {
            socials: socials,
        };
        await setDoc(docRef, docData, { merge: true })
            .then((doc) => {
                setReload((prev) => !prev);
                notify({ type: "success", message: "success" });
            })
    };
    
    useEffect(() => {
        getSocials();
    }, [reload]);





  return (
    <div className="h-full bg-gray-200 flex flex-col py-20 sm:py-32 items-center  gap-4 w-full">
      <div className=" flex flex-col gap-4 items-center justify-center w-full px-10  max-w-md ">
        <div className="h-full bg-gray-200 flex flex-col  items-center  gap-4 w-full">
          <div className="border p-2 text-xl border-primary rounded-md bg-white">
            Edit Social Media Links Home Page
          </div>

          <div className="flex flex-col gap-4  items-center  justify-center  p-4 w-full">
            <Input
              size="lg"
              label="Main Heading"
              value={mainHeading}
              type="text"
              onChange={(e) => {
                setMainHeading(e.target.value);
              }}
              name="mainHeading"
            />
            <Input
              size="lg"
              label="Index"
              value={index}
              type="number"
              onChange={(e) => {
                setIndex(e.target.value);
              }}
              name="index"
            />

            <Input
              size="lg"
              label="Platform"
              value={platform}
              type="text"
              onChange={(e) => {
                setPlatform(e.target.value);
              }}
              name="platform"
            />
            <Input
              size="lg"
              label="Link"
              value={link}
              type="text"
              onChange={(e) => {
                setLink(e.target.value);
              }}
              name="link"
            />

            <Input
              size="lg"
              label="Image"
              type="file"
              onChange={(e) => {
                const images = "HomePage/SocialMedia/";
                if (e.target.files[0])
                  uploader(e.target.files[0], setImage, setProgress, images);
              }}
              name="image"
            />

            <div>Image Uploaded {progress} %</div>
            <Button
              onClick={() => {
                // console.log(mainHeading, content, heading, index);
                if (platform && link && index && image) {
                  setSocials((prev) => [
                    ...prev,
                    {
                      mainHeading: mainHeading,
                      index: index,
                      platform: platform,
                      image: image,
                      link: link,
                      
                    },
                  ]);
                  console.log(socials);
                  setImage("");
                  setIndex("");
                  setMainHeading("");
                  setPlatform("");
                  setLink("");
                } else {
                  notify({
                    type: "error",
                    message: "Please fill all the fields",
                  });
                }
              }}
            >
              Add Social Media Link
            </Button>
          </div>

          <div className="border-2 border-black">
            {socials && socials.length ? (
              <div className="grid grid-cols-2 w-[80vw]  gap-4 p-4 ">
                {socials.map((data, index) => {
                  return (
                    <div className="flex flex-col gap-2 border-2 border-blue-500 p-4">
                      <div className="font-semibold">Index : {data.index}</div>
                      <div className="flex font-semibold ">
                        Platform : <br></br>
                        {data.platform}
                      </div>
                      <div className="flex font-semibold ">
                        Link : <br></br>
                        {data.link}
                      </div>

                      <img src={data.image} alt="" />
                      <Button
                        onClick={() => {
                          setSocials((prev) =>
                            prev.filter((item) => item !== data)
                          );
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>

          <div className="my-6">
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleSubmit({
                  socials: socials,
                });
              }}
            >
              Submit{" "}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditSocialHome;
