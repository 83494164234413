import React from "react";
import Collapse from "../../../../Components/Common/Collapse";
import Bottom from "../Overview/Bottom";

function FrequentlyAsked({ eventData }) {
  return (
    <div>
      {eventData ? (
        eventData.faq && eventData.faq.length ? (
          <div id="faq" class="container">
            <div class="">
              <div class="text-center">
                <div className="text-2xl sm:text-3xl font-semibold font-poppins mb-5 text-center">
                  Frequently asked questions
                </div>
                <div className=" font-poppins  text-center">
                  Everything you need to know about upcoming {eventData.name}
                </div>
              </div>
            </div>

            {eventData.faq.map((data) => {
              return (
                <div class="expandable-list mt-5 sm:mt-10">
                  <Collapse
                    heading={data.question}
                    content={<div>{data.answer}</div>}
                  ></Collapse>
                </div>
              );
            })}
          </div>
        ) : null
      ) : (
        <div>Loading</div>
      )}

      <Bottom />
    </div>
  );
}

export default FrequentlyAsked;
