import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { Button, Input, Textarea } from "@material-tailwind/react";
import { uploader } from "./test";
import { notify } from "../Common/Notification";

function EditEventsFrontPage() {
  const [reload, setReload] = useState(false);
  const [route, setRoute] = useState();
  const [data, setData] = useState(null);
  const [heading, setHeading] = useState("");
  const [content, setContent] = useState();
  const [progress, setProgress] = useState();
  const [bottomText, setBottomText] = useState();
  const [images, setImages] = useState([]);
  const [currentImage, setCurrentImage] = useState();
  const getWelcomePage = async () => {
    const querySnapshot = await getDocs(collection(db, "eventsFrontPage"));

    querySnapshot.forEach((doc) => {
      setHeading(doc.data().heading);
      setData(doc.data());
      setContent(doc.data().content);
      setImages(doc.data().images);
      setBottomText(doc.data().bottomText);
      console.log(doc.data(), data);
    });
  };

  useEffect(() => {
    getWelcomePage();
  }, [reload]);

  const handleSubmit = async () => {
    const docRef = doc(db, "eventsFrontPage", "eventsFrontPage");
    const docData = {
      heading: heading,
      content: content,
      images: images,
      bottomText: bottomText,
    };
    console.log(docData, "doc");
    await setDoc(docRef, docData, { merge: true })
      .then((doc) => {
        setReload((prev) => !prev);
        notify({ type: "success", message: "events front page Updated" });
      })
      .catch((error) => {
        console.log(error, "k");
        notify({ type: "error", message: error.message });
      });

    console.log("Document written with ID: ", docRef.id);
  };
  return (
    <div className="h-full bg-gray-200 flex flex-col py-20 sm:py-32 items-center  gap-4 w-full">
      {
        <div className=" flex flex-col gap-4 items-center justify-center w-full px-10  max-w-md ">
          {" "}
          <Input
            size="lg"
            label="Heading"
            value={heading}
            onChange={(e) => {
              setHeading(e.target.value);
            }}
            name="heading"
          />
          <Textarea
            className="w-full h-60 border bg-gray-200 border-gray-500 rounded-md p-2 "
            size="lg"
            label="Description"
            value={content}
            onChange={(e) => {
              setContent(e.target.value);
            }}
          />
          <Input
            size="lg"
            label="Bottom Text"
            value={bottomText}
            onChange={(e) => {
              setBottomText(e.target.value);
            }}
            name="bottomText"
          />
          <div className="flex flex-col gap-2 justify-center items-center border border-black p-2">
            {images && images.length ? (
              <div className="flex flex-row flex-wrap gap-2 justify-center items-center">
                {images.map((data, index) => {
                  return (
                    <div className="flex flex-col border p-1 border-black rounded-md ">
                      <div className="text-center  p-1 border-black">
                        {index + 1}{" "}
                      </div>
                      <div>
                        <img
                          className="w-32"
                          src={data.image}
                          alt="error"
                        ></img>
                      </div>
                      <Button
                        onClick={() => {
                          setImages((prev) =>
                            prev.filter((item) => item !== data)
                          );
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  );
                })}
              </div>
            ) : null}
            <div className="p-3 border border-black text-center">
              <Input
                className=""
                size="lg"
                label="aboutPic"
                type="file"
                onChange={(e) => {
                  const images = "events/hotelInformation/";
                  if (e.target.files[0]) {
                    uploader(
                      e.target.files[0],
                      setCurrentImage,
                      setProgress,
                      images
                    );
                  }
                }}
                name="image"
              />
              <div>Image Uploaded {progress} %</div>
            </div>
            <Button
              onClick={() => {
                if (currentImage) {
                  setImages((prev) => [
                    ...prev,
                    {
                      image: currentImage,
                    },
                  ]);
                } else {
                  notify({
                    type: "error",
                    message: "please upload image and change text",
                  });
                }
              }}
            >
              Add Events Front Page Image{" "}
              <span className="text-xs text-black">(preferred 8)</span>
            </Button>
          </div>
          <Button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            Edit
          </Button>
        </div>
      }
    </div>
  );
}

export default EditEventsFrontPage;
