import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import { Button, Input, Textarea } from "@material-tailwind/react";
import { uploader } from "../test";
import { notify } from "../../Common/Notification";


function EditWelcomeHomePage() {
  const [reload, setReload] = useState(false);
  const [route, setRoute] = useState();
  const [data, setData] = useState(null);
  const [heading, setHeading] = useState("");

  const [content, setContent] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [progress, setProgress] = useState();
  const [buttonText, setButtonText] = useState();
  const getWelcomePage = async (docId) => {
    try {
      const docRef = doc(db, "HomePage", docId);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        setData(docSnapshot.data());
        setHeading(docSnapshot.data().heading);
        setContent(docSnapshot.data().content);
        setImageUrl(docSnapshot.data().image);
        setButtonText(docSnapshot.data().buttonText);
        setRoute(docSnapshot.data().route);
        console.log(docSnapshot.data(), data);
      } else {
        console.log("Document not found");
      }
    } catch (error) {
      console.log("Error getting document:", error);
    }
  };
  useEffect(() => {
    getWelcomePage("welcomePage");
  }, []);

  const handleSubmit = async () => {
    const docRef = doc(db, "HomePage", "welcomePage");
    const docData = {
      heading: heading,
      content: content,
      image: imageUrl,
      buttonText: buttonText,
      route: route,
    };
    console.log(docData, "doc");
    await setDoc(docRef, docData, { merge: true })
      .then((doc) => {
        setReload((prev) => !prev);
        notify({ type: "success", message: "link Saved" });
        console.log(doc, "update");
      })
      .catch((error) => {
        console.log(error, "k");
        notify({ type: "error", message: error.message });
      });

    console.log("Document written with ID: ", docRef.id);
  };
  return (
    <div className="h-full bg-gray-200 flex flex-col py-20 sm:py-32 items-center  gap-4 w-full">
        <div className="border p-2 text-xl border-primary rounded-md bg-white">
        Edit Welcome Home Page
      </div>
      {
        <div className=" flex flex-col gap-4 items-center justify-center w-full px-10  max-w-md ">
          {" "}
          <div className="flex flex-col gap-4  items-center justify-center  p-2 w-full">
            <Input
              size="lg"
              label="Image"
              type="file"
              onChange={(e) => {
                const images = "HomePage/welcomePage";
                if (e.target.files[0])
                  uploader(e.target.files[0], setImageUrl, setProgress, images);
              }}
              name="image"
            />
            <div>Image Uploaded {progress} %</div>
          </div>
          <Input
            size="lg"
            label="Heading"
            value={heading}
            onChange={(e) => {
              setHeading(e.target.value);
            }}
            name="heading"
          />
          <Textarea
            className="w-full h-60 border bg-gray-200 border-gray-500 rounded-md p-2 "
            size="lg"
            label="Description"
            value={content}
            onChange={(e) => {
              setContent(e.target.value);
            }}
          />
          <Input
            size="lg"
            label="Button Text"
            value={buttonText}
            onChange={(e) => {
              setButtonText(e.target.value);
            }}
            name="buttonText"
          />
          <Input
            size="lg"
            label="Route"
            value={route}
            onChange={(e) => {
              setRoute(e.target.value);
            }}
            name="route"
          />{" "}
          <Button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            Edit
          </Button>
        </div>
      }
    </div>
  );
}

export default EditWelcomeHomePage;
