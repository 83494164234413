import Button from "../Common/Button";

const Destiny = () => {
  return (
    <article class=" flex items-center flex-col container gap-10 py-10">
      <div className="event-info w-full grid grid-cols-2 gap-20 ">
        <div>
          <img src={require("../../assets/events/event-1.png.png")} alt="" />
        </div>
        <div>
          <div className="font-poppins text-3xl font-extrabold">
            Live Your Destiny
          </div>
          <div className=" font-poppins font-normal text-xl leading-7 py-1 ">
            Nicobar Islands
          </div>
          <label className="bg-gray-200 ">APRIL 19 - 22, 2022</label>
          <div className="text-base leading-tight text-center sm:text-justify   py-[2.5%] font-poppins ">
            Live Your Destiny is an all-in-one exotic immersive retreat for
            seekers of personal growth and the power of community. Gathering a
            robust community of like-minded men and women from around the world
            – entrepreneurs, artists, industry professionals and more – Live
            Your Destiny aims to revitalize and reform the way you think and
            behave. The program facilitates as a checkpoint for some and a start
            for many individuals in the journey of personal transformation.
            <br />
          </div>
          <div className="flex justify-center md:justify-start">
            <Button name="Know More" href="/live-destiny" />
          </div>
        </div>
      </div>
    </article>
  );
};

export default Destiny;
