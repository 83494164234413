import React from "react";

function OtherInformation() {
  return (
    <>
      <div className="flex flex-col w-[90vw] sm:w-[75vw] lg:w-[60vw] mx-auto my-8 gap-2">
        <div className="flex font-semibold gap-1">
          <span className="underline">Other Information</span>
          <span className="text-red-500">*</span>
        </div>
        <div className="flex flex-col gap-2 md:flex-row items-center">
          <label className="basis-1/2">
            Do you have any dietary restrictions or allergies we should be aware
            of? If yes, please provide details.
            <span className="text-red-500 px-1">*</span>
          </label>
          <textarea
            className=" p-2 sm:p-3 w-full h-32 rounded-xl  border-2 border-primary font-poppins  self-center "
            name="diet"
            placeholder=""
            type="text"
            required={true}
          />
        </div>

        <div className="flex flex-col gap-2 md:flex-row items-center">
          <label className="basis-1/2">
            Do you have any pre-existing medical conditions or physical
            limitations that we should know about? If yes, please provide
            details.
            <span className="text-red-500 px-1">*</span>
          </label>
          <input
            className=" p-2 sm:p-3 w-full h-32 rounded-xl  border-2 border-primary font-poppins  self-center "
            name="medicalCondition"
            placeholder=""
            type="text"
            required={true}
          />
        </div>

        <div className="flex flex-col gap-2 md:flex-row items-center">
          <label className=" basis-1/2">
            What are you looking to achieve through this program? Please briefly
            describe your motivation for attending the workshop and any specific
            goals or expectations you have.
            <span className="text-red-500 px-1">*</span>
          </label>
          <textarea
            className=" p-2 sm:p-3 w-full h-32 rounded-xl  border-2 border-primary font-poppins  self-center "
            name="achieve"
            placeholder=""
            type="text"
            required={true}
          />
        </div>

        <div className="flex flex-col gap-2 md:flex-row items-center">
          <label className="basis-1/2">
            Have you done any retreats or workshops before? If yes please
            mention
          <span className="text-red-500 px-1">*</span>
          </label>
          <textarea
            className=" p-2 sm:p-3 w-full rounded-xl h-32 border-2 border-primary font-poppins  self-center "
            name="workshopBefore"
            placeholder=""
            type="text"
            required={true}
          />
        </div>

        <div className="flex flex-col gap-2 md:flex-row items-center">
          <label className=" basis-1/2">
            Any additional information you want us to know?
            
          </label>
          <textarea
            className=" p-2 sm:p-3 w-full h-32 rounded-xl  border-2 border-primary font-poppins  self-center "
            name="additionalInfo"
            placeholder=""
            type="text"
            required={false}
          />
        </div>

        <div className="flex flex-col gap-2 md:flex-row items-center">
          <label className="flex basis-1/2">
            How did you learn about this program and us?
          </label>
          <textarea
            className=" p-2 sm:p-3 w-full h-32 rounded-xl  border-2 border-primary font-poppins  self-center "
            name="learn"
            placeholder=""
            type="text"
            required={true}
          />
        </div>
      </div>
    </>
  );
}

export default OtherInformation;
