const LiveYourDestinyPrinciples = () =>{
    return (
        <div>
            <div className="text-2xl sm:text-4xl font-poppins font-bold text-center my-5">The Three Principles</div>

            
          <div className="text-center lg:text-left text-xl sm:text-2xl font-poppins font-bold my-5">1. CATHARSIS (Break free)</div>
          <div className=" text-center lg:text-justify text-base font-poppins leading-tight  sm:leading-snug lg:leading-normal">
            To create the life you want to live, you need to unshackle yourself
            from the life you have already lived until now. Remove yourself from
            limiting beliefs, ideas and pathways that wear you down. The hidden
            emotional pain, the trauma and pent-up anger that rages within you.
            Relieve yourself from the effects of the ideas and goals you once
            held dear. It is time to move on. <br /><br />
            The process of catharsis will require a lot of mental strength to
            power through, but will ultimately be a rewarding experience for you
            once you’ve freed yourself from the things that hold you down in
            your life.
            
          </div>
          <div className="text-center lg:text-left text-xl sm:text-2xl font-poppins font-bold my-5">2. EKSTASIS (To stand outside oneself; ecstasy; peak state)</div>
          <div className="text-center lg:text-justify text-base font-poppins leading-tight  sm:leading-snug lg:leading-normal">
            <em>Ekstasis </em>is the transcendental state of self; the peak
            state or euphoric state of one’s breakthrough. This peak experience
            is necessary for mental development and creating a refreshed
            mindset. Perspective is important in recreating your future;
            <em>Ekstasis </em>provides the necessary space for creating a new
            perspective on your life as well as boosting your control over
            yourself. As a track athlete thrives on a runner’s high, as a
            bodybuilder lifts weights well over his body’s capacity, peak state
            is the ultimate goal of personal transformation. It simply activates
            growth.
            
          </div>
          <div className="text-center text-xl sm:text-2xl font-poppins font-bold lg:text-left my-5">3. COMMUNITAS (Community)</div>
          <div className="text-center lg:text-justify text-base font-poppins leading-tight  sm:leading-snug lg:leading-normal">
            What better environment is conducive to personal growth than a
            community of like-minded people to support you? The spirit and
            collective power of community cannot be understated. To be in
            communitas is to be in a community where all members are equal and
            share a common experience. When dealing with self-mastery, it is
            ideal to be around those who are in the process of self-growth, just
            like you. This breeds an atmosphere of motivation, friendship and
            mutual affection, resulting in steady progress and promising
            results.
          </div>
        </div>
    )
};

export default LiveYourDestinyPrinciples;