const PeopleCard = (props) => {
  return (
    <div className="border m-1 w-80 h-96  bg-white rounded-md   flex flex-col   md:my-0  text-center">
      <div className="h-12 w-12 self-end mr-8 mt-1">
        <img
          src={require("../../../IMG/IMG/right-quote.png")}
          alt=""
          className="w-12 h-12 "
        />
      </div>
      <div className="py-10 px-8">
        <div className="text-sm font-medium">{props.testimonial}</div>

        <div className="flex flex-row gap-8 py-8 ">
          <div className="rounded-full h-16 w-16  ">
            <img src={props.img} alt="" className="rounded-full h-16 w-16 object-cover" />
          </div>
          <div className="flex flex-col text-xs justify-center items-center">
            <div className="text-base font-semibold"> {props.name}</div>
            <div>{props.profession}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

{

}

export default PeopleCard;
