import Button from "../Common/Button";

const MembershipTop =() =>{
    return (
<article class="first-article-child relative flex flex-col py-28 bg-gray-200" style={{"min-height": "fit-content;"}}>
        <div class="container flex flex-row">
            <div class="flex-1">
                <div className="text-4xl md:text-5xl  my-10  font-poppins font-extrabold">Get full access with<br />
                    Innersky Membership</div>
                <div className="text-md md:text-lg pb-5 font-poppins font-normal ">
                    With Innersky Membership you get unlimited access to all
                    quests, meditations, live workshops, trainings, private social
                    network, and a lot more
                </div>
                <div >
        <Button name="Apply Now" href="/contact" />
      </div>
                
            </div>

            <div class="lg:flex-1">

            </div>
        </div>
    </article>
    )
    
};

export default MembershipTop;