import ReachOut from "../../Components/Footer/ReachOut";
import Links from "../../Components/Footer/Links";
import Foot from "../../Components/Footer/Foot";
import Training from "../../Components/TrainingPage/Training";
import TrainingTop from "../../Components/TrainingPage/TrainingTop";
import "./training.css";
const trainingPage = () => {
  return (
    <html lang="en">
      <body class="overflow-x-hidden max-w-[100vw]">
        <TrainingTop />

        <Training />

        <div>
          <ReachOut />
          <Links />
          <Foot />
        </div>
      </body>
    </html>
  );
};

export default trainingPage;
