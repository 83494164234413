import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { UserAuth } from "../../../context/AuthContext";
function SuperAdminProtected({ children }) {
  const { user, dbUser, superAdmin } = UserAuth();

  if (!user || !dbUser || !superAdmin || !dbUser.superAdmin) {
    return (
      <div className="h-screen w-screen m-20 flex items-center justify-center  text-2xl text-red-500 ">
        Not Authorized !!
      </div>
    );
  } else if (
    dbUser &&
    dbUser.superAdmin &&
    dbUser.superAdmin === true &&
    superAdmin &&
    superAdmin === true
  ) {
    return children;
  } else {
    console.log(dbUser, superAdmin, "return <Na");
  }
}

export default SuperAdminProtected;
