import React, { useEffect, useState } from "react";
import SwiperCore, { EffectCoverflow, Pagination, Autoplay } from "swiper";
import { Swiper } from "swiper/react";
import "swiper/css";
import _ from "underscore";
import { Navigation } from "swiper";
import "swiper/css/bundle";
import useWindowDimensions from "./Window";

SwiperCore.use([EffectCoverflow, Pagination, Autoplay]);

export default function CustomSwiper({ items, className, buildItems }) {
  const buildClassName = function (defaultClass) {
    const appClass = "w-full h-full ";
    var instanceClassName = appClass + " " + defaultClass;
    if (className) {
      instanceClassName += " " + className;
    }
    return instanceClassName;
  };
  const { height, width } = useWindowDimensions();
  const [number, setNumber] = useState(4);

  useEffect(() => {
    if (width < 596) {
      setNumber(1);
    } else if (width <= 700 && width > 596) {
      setNumber(2);
    } else if (width > 700 && width <= 1076) {
      setNumber(3);
    } else {
      setNumber(4);
    }
  }, [height, width]);

  return (
    <div className={buildClassName("")}>
      <Swiper
        grabCursor={true}
        autoplay={{
          delay: 10000,
          disableOnInteraction: true,
        }}
        navigation={true}
        modules={[Navigation]}
        effect={"coverflow"}
        loop={true}
        centeredSlides={true}
        slidesPerView={number}
        style={{
          backgroundColor: "#F5F5F5",
          "--swiper-navigation-color": "#C8C8C8",
          "--swiper-navigation-size": "30px",
        }}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,

          modifier: 1,
          slideShadows: false,
        }}
      >
        {buildItems(items)}
      </Swiper>
    </div>
  );
}
