import React from "react";
import Button from "../../../../Components/Common/Button";
import { useNavigate } from "react-router-dom";

function Program({ eventData }) {
  const navigate = useNavigate();
  return (
    <article class="min-w-full flex items-center justify-center flex-col container">
      <div class="text-3xl sm:text-4xl py-5 text-center  font-poppins font-bold">
        {eventData.programHeading ? eventData.programHeading : "The Program"}
      </div>
      <div class="mt-0 mb-4 font-poppins text-center leading-tight  sm:leading-snug lg:leading-normal text-base">
        {eventData.programDescription ? eventData.programDescription : ""}
      </div>
      <div class="w-full sm:program-container grid md:grid-cols-3 md:grid-rows-3  sm:gap-3 lg:gap-4">
        {eventData.programImages
          ? eventData.programImages.map((data) => {
              return (
                <div class="program-card">
                  <img
                    src={data.image}
                    alt=""
                    className="h-4/5 rounded-2xl  "
                  />
                  <label>{data.text}</label>
                </div>
              );
            })
          : null}
      </div>

      <div className="py-5">
        <Button
          name="Apply Now"
          onClick={() => {
            navigate(`/form/${eventData.slug}`);
          }}
        />
      </div>
    </article>
  );
}

export default Program;
