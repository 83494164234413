const EventTop = ({ frontData }) => {
  return (
    <article class="first-article-child relative flex  flex-col text-center pt-20 pb-10 fit ">
      <div class=" text-3xl md:text-6xl mt-5 font-poppins font-extrabold mb-10 text-center  mx-[1%]  ">
        {frontData ? frontData.heading : "Loading"}
      </div>
      <div class="text-2xl font-poppins font-bold px-[2%]">
        {frontData ? frontData.content : "Loading"}
      </div>
    </article>
  );
};

export default EventTop;
