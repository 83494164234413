const EventsMid = ({ frontData }) => {
  return (
    <article class="relative flex container flex-col ">
      {frontData ? (
        frontData.bottomText ? (
          <div class="px-[1%] md:px-[2%] text-base leading-tight font-poppins text-center  ">
            {frontData.bottomText}
          </div>
        ) : null
      ) : (
        <div>Loading</div>
      )}
    </article>
  );
};

export default EventsMid;
