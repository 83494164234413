const LifeCoachTherapist = () =>{
    return (
        <div class=" mb-10 w-full">
              <div class="text-center mb-5 ">
                <a href="/">
                  <div className=" text-2xl sm:text-3xl font-poppins text-center font-bold text-blue-700">LIFE COACH VS. THERAPIST</div>
                </a>
              </div>
              <div class="flex justify-center mx-6 lg:mx-0 lg:h-[34rem]  ">
                <img src={require("../../../IMG/IMG/bookLC.jpeg")} alt="" />
              </div>
              <div class="blog-card-content">
                {/* <div class="mx-5 text-lg">
                   Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Dignissimos quia vero quos empore, recusandae autem totam
                  et quod, nobis consectetur, eum velit. Iusto ratione
                  incidunt enim expedita dolore deleniti tempore fugiat
                  blanditiis, doloremque officia. Ad nam voluptatum ut?
                  Quaerat, deleniti! 
                </div> */}
              </div>
            </div>

    )
};

export default LifeCoachTherapist;