import "./highperf.css";
import ReachOut from "../../Components/Footer/ReachOut";
import Links from "../../Components/Footer/Links";
import Foot from "../../Components/Footer/Foot";
import GrowthResources from "../../Components/Trainings/Growth";
import Vid from "../../Components/Trainings/Vid";
import HighPerfTop from "../../Components/Trainings/HighPerformance/HighPerfTop";
import HighPerfVid from "../../Components/Trainings/HighPerformance/HighPerfVid";
import HighPerfDesc from "../../Components/Trainings/HighPerformance/HighPerfDesc";
import HighPerfPoints from "../../Components/Trainings/HighPerformance/HighPerfPoints";



const highPerformance = () =>{
    return (
        
<html lang="en">
  
  <body>
    
    <div class="wrapper">
      
      <HighPerfTop />

      <HighPerfVid />

      <div class="description-box">
        <div class="desc-para">
          
          <HighPerfDesc />
          <HighPerfPoints />
        </div>
        <div class="quick-bites">
          <div class="playlist__one">
            <div class="video-div-heading">
              
              <div className="text-2xl sm:text-4xl font-poppins font-extrabold text-center">More About High Performance</div>

            </div>
            <Vid />
            
          </div>
        </div>
      </div>
      <GrowthResources />
      <div>
          <ReachOut />
          <Links />
          <Foot />
          </div>
    </div>
    
    {/* <script>
      $(document).ready(function () {
        $(".menu-icon").on("click", function () {
          $("nav ul").toggleClass("showing");
        });
      });

      $(window).on("scroll", function () {
        if ($(window).scrollTop()) {
          $("nav").addClass("black");
        } else {
          $("nav").removeClass("black");
        }
      });
    </script> */}
  </body>
</html>

    )
};

export default highPerformance;