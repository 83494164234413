const OurExperience =() =>{
    return (
        <div>
            <div className=" text-2xl sm:text-4xl font-poppins font-bold text-center my-5">Our experience</div>
          <div className="text-center text-base font-poppins leading-tight  sm:leading-snug lg:leading-normal">
            Bodhie Vik conducts 5-6 Design Your Destiny retreats in a year,
            hosting the event all over the world, ranging from the Himalayas,
            Goa and Mumbai in India to international hotspots like Russia, Bali,
            Thailand and countries in Europe.<br /><br />
            We have completed 8 years of hosting Design Your Destiny, and our
            level of excitement can only get so high about being the catalyst in
            the process of personal transformation. We love what we do and we
            commit to each member who gives in to the process of revitalization
            and reformation. We understand the personal risk and strength it
            takes to acknowledge one’s shortcomings and willingness to learn and
            harness their true potential under a refreshed mindset.
            <br /><br />

            In closing, we encourage you to consider Design Your Destiny for
            personal transformation. Design your Destiny is the essential step
            up in your journey of personal growth for revitalizing your soul and
            realigning your life in the direction you want it to go.<br />
            So all we have to ask you is this:<br />
            Are you willing to take the next step with Bodhie Vik?
          </div>
        </div>
    )
};

export default OurExperience;