import React, { useEffect, useState } from "react";

import Bodhie from "./Bodhie";
import Landing from "./Landing";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase";
import { doc, getDoc } from "firebase/firestore";

function Welcome() {
  const [data, setData] = useState(null);
  const getWelcomePage = async (docId) => {
    try {
      const docRef = doc(db, "HomePage", docId);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        setData(docSnapshot.data());
        // console.log(data, "akakkak");
      } else {
        console.log("Document not found");
      }
    } catch (error) {
      console.log("Error getting document:", error);
    }
  };
  useEffect(() => {
    getWelcomePage("welcomePage");
  }, []);
  return (
    <article className="w-full article1-bg  lg:mt-16 sm:h-[900px] lg:h-[620px] first-article-child min-w-full relative flex md:items-center md:justify-center flex-col bg-no-repeat bg-cover  ">
      {data ? (
        <div className=" w-full content flex md:pr-[1%]  z-100 flex-col lg:flex-row sm:pt-20 lg:pt-8">
          <Landing data={data} />
          <Bodhie data={data} />
        </div>
      ) : (
        <div>Loading</div>
      )}
    </article>
  );
}

export default Welcome;
