import ReachOut from "../../Components/Footer/ReachOut";
import Links from "../../Components/Footer/Links";
import Foot from "../../Components/Footer/Foot";
import EventsMid from "../../Components/EventsPage/EventsMid";
import EventTop from "../../Components/EventsPage/EventsTop";
import Retreat from "../../Components/EventsPage/Retreat";
import Destiny from "../../Components/EventsPage/Destiny";
import EventImage from "../../Components/EventsPage/EventImage";
import NewEventPage from "./NewEventPage";
import { useEffect, useState } from "react";
import { collection, getDocs, where } from "firebase/firestore";
import { db } from "../../firebase";

const EventsPage = () => {
  const [frontData, setFrontData] = useState();
  const getWelcomePage = async () => {
    const querySnapshot = await getDocs(collection(db, "eventsFrontPage"));
    console.log(querySnapshot);
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      setFrontData(data);
    });
  };

  useEffect(() => {
    getWelcomePage();
  }, []);

  return (
    <html lang="en">
      <body class="overflow-x-hidden max-w-[100vw]">
        {frontData ? (
          <div>
            <EventTop frontData={frontData} />
            <EventImage frontData={frontData} />
            <EventsMid frontData={frontData} />
          </div>
        ) : (
          <div>Loading</div>
        )}
        <NewEventPage></NewEventPage>
        <div>
          <ReachOut />
          <Links />
          <Foot />
        </div>

        {/* <style>
        .mission-img {
            position: relative;
            transform: translateX(-200%) translateY(80px) scale(20%);
            opacity: 0;
            transition: 1s all ease;
        }

        .mission-img.active {
            transform: translateX(0) translateY(0);
            opacity: 1;
        }
    </style> */}
      </body>
    </html>
  );
};

export default EventsPage;
