import React from "react";

function About({ eventData }) {
  return (
    <article class="min-w-full flex items-center justify-center flex-col container">
      <div class="text-3xl sm:text-4xl py-5 text-center font-poppins font-bold">
        About {eventData.name ? eventData.name : null}
      </div>
      <div class="flex items-center justify-center flex-col">
        <img
          src={eventData.aboutPic ? eventData.aboutPic : ""}
          alt=""
          className="max-h-[80vh]"
        />
        <div className="py-10 max-w-fit font-poppins  text-base leading-tight  sm:leading-snug lg:leading-normal text-center sm:px-[5%]">
          {eventData.description1 ? eventData.description1 : ""}
          <br />
          <br />
          {eventData.description2 ? eventData.description2 : ""}
          <br />
          <br />
          {eventData.description3 ? eventData.description3 : ""}
        </div>
      </div>
    </article>
  );
}

export default About;
