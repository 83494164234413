import React, { useEffect, useState } from "react";
import { notify } from "../../Common/Notification";
import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { Button, Input, Textarea } from "@material-tailwind/react";
import { uploader } from "../test";

function EditEventsHomePage() {
  const [reload, setReload] = useState(false);
  const [events, setEvents] = useState([]);
  const [image, setImage] = useState("");
  const [heading, setHeading] = useState("");
  const [content, setContent] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [route, setRoute] = useState("");
  const [index, setIndex] = useState("");
  const [data, setData] = useState(null);
  const [progress, setProgress] = useState();

  const getEvents = async () => {
    const docRef = doc(db, "HomePage", "Events");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      if (docSnap.data().events) setEvents(docSnap.data().events);
    } else {
      console.log("No such document!");
    }
  };
  useEffect(() => {
    getEvents();
  }, [reload]);

  const handleSubmit = async (data) => {
    const docRef = doc(db, "HomePage", "Events");
    const docData = {
      events: events,
    };
    await setDoc(docRef, docData, { merge: true })
      .then((doc) => {
        setReload((prev) => !prev);
        notify({ type: "success", message: "success" });
      })
      .catch((error) => {
        console.error(error);
      });
    console.log("Document Written with ID : ", docRef.id);

  };

  useEffect(() => {
    getEvents();
  }, [reload]);


  return (
    <div className="flex flex-col justify-center  h-full bg-gray-200  items-center  gap-4 w-full ">
      <div className="h-full bg-gray-200 flex flex-col py-20 sm:py-32 items-center  gap-4 w-full">
        <div className="border p-2 text-xl border-primary rounded-md bg-white">
          Edit Events Home Page
        </div>
        <div className=" flex flex-col gap-4 items-center justify-center w-full px-10  max-w-md ">
          {" "}
          <div className="flex flex-col gap-4  items-center justify-center  p-2 w-full">
            <Input
              size="lg"
              label="Image"
              type="file"
              onChange={(e) => {
                const images = "eventsHomePage/";
                uploader(e.target.files[0], setImage, setProgress, images);
              }}
              name="Image"
            />
            <div>Image Uploaded {progress} %</div>
          </div>
          <Input
            size="lg"
            label="Index"
            type="number"
            value={index}
            onChange={(e) => {
              setIndex(e.target.value);
            }}
            name="Heading"
          />
          <Input
            size="lg"
            label="Heading"
            value={heading}
            onChange={(e) => {
              setHeading(e.target.value);
            }}
            name="Heading"
          />
          <Textarea
            className="w-full h-96  border bg-gray-200 border-gray-500 rounded-md p-2 "
            size="lg"
            label="Description"
            value={content}
            onChange={(e) => {
              setContent(e.target.value);
            }}
          />
          <Input
            size="lg"
            label="Button Text"
            value={buttonText}
            onChange={(e) => {
              setButtonText(e.target.value);
            }}
            name="buttonText"
          />
          <Input
            size="lg"
            label="Route"
            value={route}
            onChange={(e) => {
              setRoute(e.target.value);
            }}
          />{" "}
          <Button
            type="submit"
            onClick={() => {
              if (index && heading && content && image && buttonText && route) {
                setEvents((prev) => [
                  ...prev,
                  {
                    index: index,
                    heading: heading,
                    content: content,
                    image: image,
                    buttonText: buttonText,
                    route: route,
                  },
                ]);
                console.log(events);
                setHeading("");
                setContent("");
                setImage("");
                setButtonText("");
                setRoute("");
                setIndex("");
                setProgress(0);
              } else {
                notify({
                  type: "error",
                  message: "Please fill all the fields",
                });
              }
            }}
          >
            Add Event
          </Button>
        </div>

        <div className="border-2 border-black">
          {events && events.length ? (
            <div className="grid grid-cols-2 w-[80vw]  gap-4 p-4 ">
              {events.map((data, index) => {
                return (
                  <div className="flex flex-col gap-2 border-2 border-blue-500 p-4">
                    <div className="font-semibold">Index : {data.index}</div>
                    <div className="flex font-semibold ">
                      Heading : <br></br>
                      {data.heading}
                    </div>
                    <div>
                      <span className="font-semibold">Description : </span>{" "}
                      <br></br>
                      {data.content}
                    </div>
                    <img src={data.image} alt="" />
                    <Button
                      onClick={() => {
                        setEvents((prev) =>
                          prev.filter((item) => item !== data)
                        );
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>

        <div className="my-6">
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleSubmit({
                events: events,
              });
            }}
          >
            Submit{" "}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default EditEventsHomePage;
