import React from 'react'

function YogaPoints() {
  return (
    <div className="description-box ">
        <div className="desc-para  ">
          <div className="mb-10 text-base text-center  font-poppins leading-tight  sm:leading-snug lg:leading-normal">
            <span  className="font-bold text-base sm:text-lg  font-poppins"> Kundalini yoga to awaken the 10 bodies </span> <br />
            <br />
            <span className="text-center text-base font-poppins leading-tight  sm:leading-snug lg:leading-normal ">
            Here is powerful kunfalinAs yoga practitioners, we learn that in
            addition to the Western way of looking at anatomy, there is also a
            more subtle, energetic anatomy to awaken to. One of the ways we can
            understand our existence and enhance our potential is to learn about
            and work with our Ten Bodies. In Kundalini Yoga, we each have three
            mental bodies and six energy bodies, in addition to our physical
            body. My teacher, Yogi Bhajan, said, “If you understand that you are
            Ten Bodies, and you are aware of those Ten Bodies, and you keep them
            in balance, the whole universe will be in balance with you.” Here’s
            the lowdown on all 10 bodies, plus a sequence to awaken them.</span> <br />
            <br />

            <span className="font-bold text-base sm:text-lg  font-poppins"> Understanding the Ten Bodies </span> <br />
            <br />
            <span className=" font-bold text-base sm:text-lg  font-poppins"> 1. Soul Body </span> <br />
            <br />
            Our first body is our Soul Body. Our Soul Body is our flow of
            spirit, our connection to infinity and quite literally our Soul.
            This body is our foundational and true self, providing us with the
            ability to live from our heart. The Soul Body responds to all heart
            work and the raising of our Kundalini (the life force energy that
            rises up our spine). <br />
            <br />
            <span className=" font-bold text-base sm:text-lg  font-poppins">2. Negative Mind</span> <br />
            <br />
            Our second body is our Negative Mind. And before you go all
            “negative,” consider this: our Negative Mind is our first and often
            our strongest “body,” constantly working to assess our environment
            and situations for danger or negative potential. Our Negative Mind
            keeps us safe and alive and also gifts us with, as Yogi Bhajan said,
            “a longing to belong.” The Negative Mind is balanced with discipline
            and integrity. <br />
            <br />
            <span className=" font-bold text-base sm:text-lg  font-poppins">3. Positive Mind</span> <br />
            <br />
            Our third body is our Positive Mind. Our Positive Mind works to
            assess what is beneficial, positive, and affirming. This “body” sees
            opportunity and allows us to identify resources. The Positive Mind
            gives us our willpower as well as our playfulness. Everything we do
            to strengthen our navel point (think strong core) and to increase
            our self-esteem is beneficial for this body. <br />
            <br />
            <span className=" font-bold text-base sm:text-lg  font-poppins">4. Neutral Mind</span> <br />
            <br />
            Our fourth body is our Neutral Mind. Our Neutral Mind absorbs and
            evaluates the assessments from the Negative and the Positive Minds
            and delivers guidance to us. Our Neutral Mind is compassionate,
            intuitive, and gives us the ability to recognize
            polarities. Meditation is great for balancing this body. <br />
            <br />
            <span className=" font-bold text-base sm:text-lg  font-poppins">5. Physical Body</span> <br />
            <br />
            Our fifth body is our Physical Body. This body is the temple where
            all the bodies dwell in some way, shape, or form. The Physical Body
            gifts us with the ability to balance ourselves and our lives as well
            as the capacity to sacrifice for our hopes, our dreams, and the
            greater community. This body also holds the energy of the Teacher.
            The Physical Body loves regular exercise and sharing what has been
            learned. <br />
            <br />
            <span className=" font-bold text-base sm:text-lg  font-poppins">6. Arcline</span> <br />
            <br />
            Our sixth body is our Arcline. You can visualize your Arcline Body
            as a halo, extending from earlobe to earlobe and encompassing the
            hairline and brow. Women have a second Arcline across the breast
            line. Our Arcline Body gives us the ability to both project as well
            as intuit. This body grants us the ability to focus as well as
            meditate. The Arcline responds well to pituitary gland sequences as
            well as  drishti  (gaze) to the third eye. <br />
            <br />
            <span className=" font-bold text-base sm:text-lg  font-poppins">7. Aura</span> <br />
            <br />
            Our seventh body is our Aura. This body is our electromagnetic field
            of energy that surrounds our Physical Body. Ironically, I often feel
            that I am straying into the fanciful zone when talking about the
            Aura, and yet the Aura is scientifically measurable as well as
            scientifically validated! Our Aura acts as a container for our
            prana, our life force energy. This body also acts as a shield of
            protection and allows us to elevate ourselves—energetically as well
            as consciously. Meditation is beneficial, as well as wearing natural
            fibers. The color white, which holds all the colors of the spectrum,
            is believed to expand and magnify our Aura. <br />
            <br />
            <span className=" font-bold text-base sm:text-lg  font-poppins">8. Pranic Body</span> <br />
            <br />
            Our eighth body is our Pranic Body. By breathing, we are constantly
            working with our Pranic Body to bring in life force. This body gives
            us the gift of energy for action and accomplishment. All pranayama
            will have a positive impact on our Pranic Body. <br />
            <br />
            <span className=" font-bold text-base sm:text-lg  font-poppins">9. Subtle Body</span> <br />
            <br />
            Our ninth body is our Subtle Body. This body allows us to see beyond
            what is in front of us to the universal play of everything. Our
            Subtle Body is deeply woven with our Soul Body. When we die, our
            Subtle Body carries our Soul. Many great teachers continue to
            influence us through their Subtle Body, beyond the death of their
            Physical Body. Our capacity for mastery is held in this body. In
            keeping with mastery, doing a Kundalini practice for 1,000 days in a
            row is a way to balance the Subtle Body. <br />
            <br />
            <span className=" font-bold text-base sm:text-lg  font-poppins">10. Radiant Body</span> <br />
            <br />
            Our tenth body is our Radiant Body. This body is exactly as it
            sounds—it gives us our radiance as well as our courage, our
            nobility. People that are magnetic and/or charismatic are great
            examples of well-developed and balanced Radiant Bodies. The greatest
            thing we can do for our Radiant Body is to have commitment. To be
            committed to our practice, to kindness, truth, and excellence in
            life is a great way to amplify our radiance. In our understanding of
            the Ten Body system, we also have an Eleven. Bonus! Eleven is
            embodiment, the state where we are beyond duality and in the flow of
            truth, balance, and the divine. All Ten Bodies are balanced. Eleven
            also represents the sound current (which represents the
            beginning/the source of all that follows in Kundalini) and infinity,
            as well as the source of all mantra. All Kundalini Yoga works with
            the balance and strength of the Ten Bodies. Some practices
            concentrate on a specific body, while others work with the whole
            system. A kriya is a series of postures, breath, and sounds that
            work toward a specific outcome. Practicing a kriya initiates a
            sequence of physical and mental changes that affect the body, mind,
            and spirit simultaneously. A great place to start your exploration
            of the Ten Bodies is with the following kriya, “Awakening to the Ten
            Bodies.”
          </div>
        </div>
      </div>
  )
}

export default YogaPoints