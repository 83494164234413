const HighPerfTop = () =>{
    return (
        <div class="title-div">
  
        <div className="text-4xl sm:text-6xl font-black pb-2 font-poppins  ">HIGH PERFORMANCE</div>
        <p class="tagline ">
          Victories aren't born on the field. You create them during practice.
        </p>
      </div>
    )
};

export default HighPerfTop;