import React, { useState } from "react";
import NavLinks from "./NavLinks";
import { SlMenu } from "react-icons/sl";
import { GrClose } from "react-icons/gr";
import { UserAuth } from "../../context/AuthContext";
function Nav() {
  const { user } = UserAuth();
  const [sidebar, setSidebar] = useState(false);
  const navData = [
    {
      name: "Home",
      href: "/",
    },
    {
      name: "My Story",
      href: "/my-story",
    },
    {
      name: "Events",
      href: "/events",
    },
    {
      name: "Videos",
      href: "/videos",
    },
    {
      name: "Training",
      href: "/training",
    },
    {
      name: "Membership",
      href: "/membership",
    },
    {
      name: "Contact",
      href: "/contact",
    },
    {
      name: ` ${user ? "Logout" : "Login"}`,
      href: "/login",
    },
  ];
  return (
    <nav className="bg-white w-[100vw] h-14 sm:h-16 md:h-20 ">
      <div className=" max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 ">
        <div className=" mx-auto px-1 sm:px-6 lg:px-8">
          <div className="  flex items-center justify-between md:pt-6 ">
            <div
              className=" absolute inset-y-0 right-0 flex items-center sm:hidden "
              onClick={() => {
                setSidebar((prev) => !prev);
              }}
            >
              {/* // Mobile menu button */}
              <button
                id=""
                type="button"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                {/* // Icon when menu is closed.  */}
                {sidebar === false ? (
                  <div className="pr-4 sm:pr-6">
                    <SlMenu size="20"></SlMenu>
                  </div>
                ) : (
                  <div className="pr-4 sm:pr-6   ">
                    <GrClose size="20"></GrClose>
                  </div>
                )}

                {/* //              Icon when menu is open.             */}
              </button>
            </div>
            <div className="flex-1 flex items-center justify-between w-full  ">
              <div className="flex-shrink-0 flex items-center   ">
                <a href="/">
                  <div className=" text-primary  font-[verdana] font-semibold text-2xl sm:text-3xl mt-4 sm:mt-0 mb-2 ">
                    Bodhie Vik
                  </div>
                </a>
              </div>
              <div className="hidden sm:block sm:ml-6">
                <div className="nav-links flex items-center  space-x-4 ">
                  {/* // Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white"  */}
                  {navData &&
                    navData.map((data) => {
                      return (
                        <NavLinks name={data.name} href={data.href}></NavLinks>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* // Mobile menu, show/hide based on menu state.  */}
        {sidebar === true && (
          <div
            className=" pb-4 absolute right-0  w-full flex justify-start pl-4  sm:hidden  shadow-lg bg-white   "
            id="mobile-menu"
          >
            <div
              className=" mt-2 flex flex-col text-md  font-[verdana] "
              onClick={() => {
                setSidebar(false);
              }}
            >
              {/* // Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
              {navData &&
                navData.map((data, index) => {
                  return (
                    <div key={index} className="pl-2  my-4">
                      <NavLinks name={data.name} href={data.href}></NavLinks>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
}

export default Nav;
