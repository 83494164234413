import React from "react";
import { useParams } from "react-router-dom";

function Logo() {
  const { slug } = useParams();
  return (
    <div className="w-[90vw] sm:w-[40vw] mx-auto flex flex-col sm:flex-row gap-2 my-2">
      <div className="w-full flex justify-center items-center ">
        <img
          src={require("../../assets/form/form image.png")}
          className="object-cover md:rounded-3xl w-76 h-76"
          alt=""
        />
      </div>
      <div className="flex flex-col justify-center w-full ">
        <div className="font-poppins font-semibold flex justify-center items-center text-center capitalize w-full">
          {slug}
        </div>
      </div>
    </div>
  );
}

export default Logo;
