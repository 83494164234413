import React, { useEffect, useState } from "react";
import PeopleCard from "./PeopleCard";
import "react-multi-carousel/lib/styles.css";
import Carousel from "../../Common/Carousel";
import PeopleCardInfo from "./PeopleCardInfo";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase";

function WhatPeopleSay() {
  const [testimonials, setTestimonials] = useState([]);
  const [editState, setEditState] = useState(false);

  const getTestimonials = async () => {
    const querySnapshot = await getDocs(collection(db, "Testimonials"));
    const array = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      array.push(data);
      // console.log(doc.data(), "doc");
    });
    array.map((data, index) => {
      setTestimonials((prev) => {
        return [
          ...prev,
          {
            index: index + 1,
            div: (
              <div className="flex justify-center text-center ">
                <PeopleCard
                  testimonial={data.testimonial}
                  name={data.name}
                  profession={data.profession}
                  img={data.image}
                />
              </div>
            ),
          },
        ];
      });
    });
  };
  useEffect(() => {
    getTestimonials();
  }, []);

  return (
    <div className="">
      <div className="flex justify-center  font-poppins text-2xl sm:text-4xl  font-bold m-10 text-center  ">
        What People Say
      </div>
      {testimonials && testimonials.length > 0 ? (
        <Carousel items={testimonials}></Carousel>
      ) : (
        <div>Loading</div>
      )}
    </div>
  );
}

export default WhatPeopleSay;
