const HighPerfDesc =()=>{
    return (
        
            <div className="text-center text-base font-poppins leading-tight  sm:leading-snug lg:leading-normal font-medium ">
            High Performance or Peak Performance, as it is known to others, is
            the state of mind where an individual’s complete potential and range
            of productivity is accessible and driven to the highest extent.
            <br /><br />
            It is the optimum mental state of productivity, the ultimate
            realization of one’s potential. <br /><br />
            High Performance was conceived from the scientific phenomenon of
            flow psychology, first discovered by Mihaly Csikszentmihalyi, a
            Hungarian-American scientist. He discovered the Flow state to be a
            state of mind where the individual is fully immersed in his work,
            having advanced focus towards the work at hand and retaining a sense
            of enjoyment from the work he/she is doing. <br /><br />
          </div>
        
    )
};

export default HighPerfDesc;