import "./life-coaching.css"

import ReachOut from "../../Components/Footer/ReachOut";
import Links from "../../Components/Footer/Links";
import Foot from "../../Components/Footer/Foot";
import GrowthResources from "../../Components/Trainings/Growth";
import Vid from "../../Components/Trainings/Vid";
import LifeCoachingTop from "../../Components/Trainings/LifeCoaching/LifeCoachingTop";
import LifeCoachingDesc from "../../Components/Trainings/LifeCoaching/LifeCoachingDesc";
import LifeCoachingPoints1 from "../../Components/Trainings/LifeCoaching/LifeCoachingPoints1";
import LifeCoachingPoints2 from "../../Components/Trainings/LifeCoaching/LifeCoachingPoints2";
// import Results from "../../Components/Trainings/LifeCoaching/Results";
import LifeCoachTherapist from "../../Components/Trainings/LifeCoaching/LifeCoachTherapist";
import Destiny from "../../Components/EventsPage/Destiny";


const lifeCoaching = () => {
  return (

    <html lang="en">

      <body>
        
        <div class="">
          
          <LifeCoachingTop />
          
          <div class="description-box ">
            <div class="desc-para">
              <LifeCoachingDesc />

              <LifeCoachingPoints1 />
              <LifeCoachingPoints2 />

            </div>
            {/* <!-- Videos --> */}
            
              
                
                  <div className="text-2xl sm:text-4xl font-poppins font-extrabold text-center">More About Life Coaching</div>
                

                <Vid />
              
            

            
          </div>

                
          
            <LifeCoachTherapist />
          
          <GrowthResources />

          <Destiny></Destiny>
          
          
          
          <div>
          <ReachOut />
          <Links />
          <Foot />
          </div>
      </div>

      
        
        
        


      </body>
      {/* <script>
      $(document).ready(function () {
        $(".menu-icon").on("click", function () {
          $("nav ul").toggleClass("showing");
        });
      });

      $(window).on("scroll", function () {
        if ($(window).scrollTop()) {
          $("nav").addClass("black");
        } else {
          $("nav").removeClass("black");
        }
      });
    </script>
    <script type="text/javascript" src="venobox/venobox.min.js"></script>
    <script>
      $(document).ready(function () {
        $(".venobox").venobox({
          spinner: "cube-grid",
          spinColor: "#5cdb95",
          closeColor: "#5cdb95",
          arrowsColor: "#5cdb95",
        });
      });

      $(".video").slice(0, 8).show();
      $(".loadMore").on("click", function () {
        $(".video:hidden").slice(0, 8).show();

        if ($(".video:hidden").length == 0) {
          $(".loadMore").fadeOut("slow");
        }
      });
    </script> */}

      </html>

    



    )
};




export default lifeCoaching;