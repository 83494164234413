import React from "react";
import Button from "../../Common/Button";

function Design({ data }) {
  return (
    <div className="event-left  flex  flex-col-reverse gap-2 lg:flex-row lg:gap-5 md:border-none   lg:px-5  m-5 md:m-0 justify-center items-center md:w-full">
      <div className="flex flex-col gap-4" data-aos="fade-up-right">
        <div className="text-4xl font-bold font-poppins text-center">
          {data.destinyHeading}
        </div>
        <div className="text-base lg:text-lg text-center font-poppins">
          {data.destinyContent}
        </div>
        <div className="pb-4 self-center ">
          <Button name={data.destinyButtonText} href={data.destinyRoute} />
        </div>
      </div>
      <img
        // src={require("../../../assets/home/events/liveYourDestiny.JPG")}
        src={data.destinyImageUrl}
        data-aos="fade-up-left"
        className="object-contain flex-1 flex-shrink w-full lg:w-[50%] md:p-2"
        alt=""
      />
    </div>
  );
}

export default Design;
