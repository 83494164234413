
import React, { useEffect, useState } from 'react'
import Button from '../../Common/Button';

function HomeEvent({ data }) {
    const [indexClass, setIndexClass] = useState();
    const [aosImage, setAosImage] = useState();
    const [aosText, setAosText] = useState();

    useEffect(() => {
        if (data.index % 2 == 0) {
            setIndexClass("lg:flex-row-reverse")
            setAosImage('fade-up-right');
            setAosText('fade-up-left');
        }
        else {
            setIndexClass("lg:flex-row")
            setAosImage('fade-up-left');
            setAosText('fade-up-right')
        }
    })

  return (
    <div className={`event-left  flex  flex-col-reverse gap-2 lg:flex-row lg:gap-5 md:border-none   lg:px-5  m-5 md:m-0 justify-center items-center md:w-full ${indexClass}`}>
      <div className="flex flex-col gap-4" data-aos={`${aosText}`}>
        <div className="text-4xl font-bold font-poppins text-center">
          {data.heading}
        </div>
        <div className="text-base lg:text-lg text-center font-poppins break-words ">
          {data.content}
        </div>
        <div className="pb-4 self-center ">
          <Button name={data.buttonText} href={data.route} />
        </div>
      </div>
      <img
        // src={require("../../../assets/home/events/liveYourDestiny.JPG")}
        src={data.image}
        data-aos={`${aosImage}`}
        className="object-contain flex-1 flex-shrink w-full lg:w-[50%] md:p-2"
        alt=""
      />
    </div>
  );
}

export default HomeEvent