import React from 'react'

function DeepSleepDesc() {
  return (
    <div class="description-box">
        <div className="desc-para text-center  text-base font-poppins leading-tight  sm:leading-snug lg:leading-normal  ">
          <div>
            This guided Meditation trains us to be less in our head and more
            aware of present moment. The mind’s tendency to get caught up in
            thoughts is perhaps strongest at bedtime when we suddenly stop and
            be still. Meditation for sleep is a specific, guided experience that
            offers a natural sleep aid all on its own, allowing us to let go of
            the day—everything that’s happened and everything that’s been said —
            so that we can rest the mind while simultaneously resting the body.
            Healthy sleep has more to do with the quality of rest than the
            number of hours. Sleep meditations help create the inner conditions
            needed for a truly restful night. Because when we settle the mind,
            we rest the body — and that restfulness is what makes it easier to
            wind down and drift off.
          </div>
        </div>
      </div>
  )
}

export default DeepSleepDesc