import React from 'react'

function OvercomeAnxietyExplore() {
  return (
    <div class="explore">
    <div className="explore-title"><h3>Explore More</h3></div>
    <div class="categories">
      <a
        href="https://www.youtube.com/playlist?list=PL7LUnQUF-8uth4BMx9K9VOuUyod8kzOJs"
        target="_blank"
        rel="noopener noreferrer"
        class="exp-btn"
        >Mind And Body</a>
      
    </div>
  </div>
  )
}

export default OvercomeAnxietyExplore