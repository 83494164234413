import Foot from "../../../Components/Footer/Foot";
import "./lyd.css";
import React, { useState } from "react";
import Apply from "./Apply/Apply";
import Overview from "./Overview/Overview";
import FrequentlyAsked from "./FAQ/FrequentlyAsked";
import Landing from "./Landing";

function LiveDestiny({ eventData }) {
  const [data, setData] = useState("overview");
  return (
    <html lang="en">
      <body class="overflow-x-hidden ">
        {eventData !== null ? (
          <div>
            <Landing eventData={eventData} />
            <tabs>
              <tab
                class=""
                onClick={() => {
                  setData("overview");
                }}
              >
                <dot
                  className={`${data === "overview" ? "bg-primary" : ""}`}
                ></dot>
                <line></line>
                <label>Overview</label>
              </tab>
              <tab
                onClick={() => {
                  setData("faq");
                }}
              >
                <dot className={`${data === "faq" ? "bg-primary" : ""}`}></dot>
                <line></line>
                <label>FAQ</label>
              </tab>
              <tab
                onClick={() => {
                  setData("apply");
                }}
              >
                <dot
                  className={`${data === "apply" ? "bg-primary" : ""}`}
                ></dot>
                <line></line>
                <label>Apply</label>
              </tab>
            </tabs>

            {data === "overview" && <Overview eventData={eventData}></Overview>}
            {data === "faq" && (
              <FrequentlyAsked eventData={eventData}></FrequentlyAsked>
            )}
            {data === "apply" && <Apply eventData={eventData}></Apply>}

            <Foot eventData={eventData} />
          </div>
        ) : (
          <div className="w-screen h-screen flex flex-col justify-center items-center  p-20 gap-4 bg-gray-200">
            Loading
          </div>
        )}
      </body>
    </html>
  );
}

export default LiveDestiny;
