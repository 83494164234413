import ReachOut from "../../Components/Footer/ReachOut";
import Links from "../../Components/Footer/Links";
import Foot from "../../Components/Footer/Foot";
import VideosMid from "../../Components/VideosPage/VIdeosMid";
import VideosTop from "../../Components/VideosPage/VideosTop";
import VideoExplore from "../../Components/VideosPage/VideoExplore";
import MidVideo from "../../Components/VideosPage/MidVideo";

const VideosPage = () => {
  return (
    <html lang="en">
      <body class="overflow-x-hidden max-w-[100vw]">
        <VideosTop />
        <VideosMid carousel={false} />
        {/* <MidVideo carousel={false}/> */}
        <VideoExplore></VideoExplore>

        <div>
          <ReachOut />
          <Links />
          <Foot />
        </div>
      </body>
    </html>
  );
};

export default VideosPage;
