import React from "react";
import WhatPeopleSay from "../../../../Components/HomePage/WhatPeopleSay/WhatPeopleSay";
import About from "./About";
import Program from "./Program";
import Experience from "./Experience";
import Bottom from "./Bottom";
import Tribe from "./Tribe";
import Hotel from "./Hotel";
import EventImage2 from "../../../../Components/EventsPage/EventImage2";

function Overview({ eventData }) {
  return (
    <div id="">
      <About eventData={eventData} />

      <Program eventData={eventData} />

      <Experience eventData={eventData} />

      <Hotel eventData={eventData} />

      <Tribe eventData={eventData} />

      <EventImage2 ></EventImage2>

      <WhatPeopleSay eventData={eventData}></WhatPeopleSay>

      <Bottom eventData={eventData} />
    </div>
  );
}

export default Overview;
