import Links from "../../Components/Footer/Links";
import Foot from "../../Components/Footer/Foot";
import ContactUs from "../../Components/Footer/ContactUs";
import ContactForm from "./ContactForm";

const ContactPage = () => {
  return (
    <div>
      <ContactUs />

      <ContactForm></ContactForm>
      <Foot />
    </div>
  );
};

export default ContactPage;
