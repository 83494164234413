import "./selfmastery.css";
import ReachOut from "../../Components/Footer/ReachOut";
import Links from "../../Components/Footer/Links";
import Foot from "../../Components/Footer/Foot";
import GrowthResources from "../../Components/Trainings/Growth";
import Vid from "../../Components/Trainings/Vid";
import SelfMasteryTop from "../../Components/Trainings/SelfMastery/SelfMasteryTop";
import SelfMasteryImg from "../../Components/Trainings/SelfMastery/SelfMasteryImg";
import SelfMasteryDesc from "../../Components/Trainings/SelfMastery/SelfMasteryDesc";
import SelfMasteryEquip from "../../Components/Trainings/SelfMastery/SelfMasteryEquip";

const selfMastery = () =>{
    return (
        
<html lang="en">
  
  <body>
    
    <div class="wrapper">
      
      <SelfMasteryTop />
      <SelfMasteryImg />
      
      <div class="description-box">
        <div class="desc-para">
          <SelfMasteryDesc />
          <SelfMasteryEquip />
        </div>
        
        <div class="quick-bites">
          <div class="playlist__one">
            <div class="video-div-heading">
              <div className="text-2xl sm:text-4xl font-poppins font-extrabold text-center">More About Self Mastery</div>
            </div>

            <Vid />
          </div>
        </div>
      </div>
      <GrowthResources />
      <div>
          <ReachOut />
          <Links />
          <Foot />
          </div>
    </div>
    
  
    {/* <script>
      $(document).ready(function () {
        $(".menu-icon").on("click", function () {
          $("nav ul").toggleClass("showing");
        });
      });

      $(window).on("scroll", function () {
        if ($(window).scrollTop()) {
          $("nav").addClass("black");
        } else {
          $("nav").removeClass("black");
        }
      });
    </script> */}
  </body>
</html>


    )
};

export default selfMastery;