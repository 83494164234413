import React from 'react'

function Explore() {
  return (
    <div class="explore">
        <div class="explore-title">
          <h3>Explore More</h3>
        </div>
        <div class="categories">
          <a
            href="https://www.youtube.com/playlist?list=PL7LUnQUF-8utywzHzdkkNiJ-x64l5E86w
                "
            target="_blank"
            rel="noopener noreferrer"
            class="exp-btn"
            >Meditation</a>
          
        </div>
      </div>
  )
}

export default Explore