// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAseJBYHTnT0miU4FWah1ATljjXBKZx1JU",
  authDomain: "bodhievik-d52e9.firebaseapp.com",
  projectId: "bodhievik-d52e9",
  storageBucket: "bodhievik-d52e9.appspot.com",
  messagingSenderId: "630868002500",
  appId: "1:630868002500:web:fd45356d8f631989252b91",
  measurementId: "G-3BV498WE9S",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const storage = getStorage(app, "gs://bodhievik-d52e9.appspot.com");
