import React from "react";
import ExperiencePoint from "./ExperiencePoint";

function Experience({ eventData }) {
  return (
    <article class="min-w-full flex items-center justify-center flex-col container mt-5">
      <div class="text-3xl sm:text-4xl font-poppins font-bold text-center my-5">
        {eventData.experienceHeading
          ? eventData.experienceHeading
          : "experience heading"}
      </div>
      <div class="p-0 m-0 w-full sm:w-3/4 text-center ">
        {eventData.experienceArray
          ? eventData.experienceArray.map((data) => {
              return (
                <ExperiencePoint
                  heading={data.heading}
                  description={data.description}
                />
              );
            })
          : null}
      </div>
    </article>
  );
}

export default Experience;
