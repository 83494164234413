import React from "react";
import NavLinks from "../Nav/NavLinks";
import { useNavigate } from "react-router-dom";

function Links() {
  const navigate = useNavigate();
  const navData = [
    {
      name: "Home",
      href: "/",
    },
    {
      name: "My Story",
      href: "/my-story",
    },
    {
      name: "Events",
      href: "/events",
    },
    {
      name: "Videos",
      href: "/videos",
    },
    {
      name: "Training",
      href: "/training",
    },
    {
      name: "Programs",
      href: "/programs",
    },
  ];

  return (
    <article className="lg:px-[10%] sm:px-[0%]  flex sm:my-16 my-1 flex-col space-y-2 md:space-y-0 md:flex-row  items-start  justify-between whitespace-nowrap w-full sm:text-base text-md  ">
      
      
      <div className="w-full ">
        <div className="font-semibold mb-1 text-primary text-center lg:text-left">
          Quick Links
        </div>
        <div className="sm:flex sm:justify-center lg:justify-start">
          <div className="grid w-full  md:w-fit grid-cols-2 md:grid-cols-1 md:grid-rows-none gap-x-2 gap-y-0 sm:gap-5 md:gap-2   mx-[6%] sm:ml-0 sm:justify-items-center lg:justify-items-start">
            <div><a href="/" className="font-medium hover:text-blue-500">Home</a></div>
            <div><a href="/my-story" className="font-medium hover:text-blue-500">My Story</a></div>
            <div><a href="/events" className="font-medium hover:text-blue-500">Events</a></div>
            <div><a href="/videos" className="font-medium hover:text-blue-500">Videos</a></div>
            <div><a href="/training" className="font-medium hover:text-blue-500">Training</a></div>
            <div><a href="/programs" className="font-medium hover:text-blue-500">Programs</a></div>

          </div>


        </div>
      </div>


      <div className="w-full">
        <div className="font-semibold mb-1 text-primary text-center lg:text-left ">
          Training
        </div>
        <div className="sm:flex sm:justify-center">
          <div className="grid w-full  grid-cols-2 md:grid-cols-1 md:grid-rows-none gap-x-2 gap-y-0 sm:gap-5 md:gap-2   mx-[6%] sm:ml-0 sm:justify-items-center lg:justify-items-start ">
            <div><a href="/live-your-destiny" className="font-medium hover:text-blue-500">Live Your Destiny</a></div>
            <div><a href="/self-mastery" className="font-medium hover:text-blue-500">Self Mastery</a></div>
            <div><a href="/high-performance" className="font-medium hover:text-blue-500">High Performance</a></div>
            <div><a href="/life-coaching" className="font-medium hover:text-blue-500">Life Coaching</a></div>
            

          </div>


        </div>
      </div>

      <div className="w-full">
        <div className="font-semibold mb-1 text-primary text-center lg:text-left  ">
          Programs
        </div>
        <div className="flex justify-center ">
          <div className="grid w-full  grid-cols-2 md:grid-cols-1 md:grid-rows-none gap-x-2 gap-y-0 sm:gap- md:gap-2   mx-[6%] sm:ml-0 sm:justify-items-center lg:justify-items-start  ">
            <div><a href="/deep-sleep" className="font-medium hover:text-blue-500">Deep Sleep</a></div>
            <div><a href="/overcome-anxiety" className="font-medium hover:text-blue-500">Overcome Anxiety</a></div>
            <div><a href="/yoga" className="font-medium hover:text-blue-500">Yoga</a></div>
            

          </div>


        </div>
      </div>


      <div className="w-full">
        <div className="font-semibold mb-1 text-primary text-center  lg:text-left">
          Social Media
        </div>
        <div className="grid w-full  grid-cols-2 md:grid-cols-1 md:grid-rows-none gap-x-2 gap-y-0 sm:gap-5 md:gap-2  mx-[6%]  text-md sm:ml-0 font-poppins font-medium sm:justify-items-center  lg:justify-items-start  ">
          <a href="https://www.youtube.com/channel/UCSlFzYGuJkm594OYNBQUPSw" target="__blank" class="font-poppins text-md  hover:text-blue-500">
            Youtube
          </a>
        
          <a
            href="https://www.instagram.com/bodhievik/"
            target="__blank"
            class="font-poppins text-md  hover:text-blue-500"
          >
            Instagram
          </a>
          <a
            href="https://www.facebook.com/bodhievik/"
            target="__blank"
            class="font-poppins text-md   hover:text-blue-500"
          >
            Facebook
          </a>
          <a
            href="https://www.linkedin.com/in/bodhie-vik-25b8b8208/"
            target="__blank"
            class="font-poppins text-md  hover:text-blue-500"
          >
            LinkedIn
          </a>
          <a
            href="https://twitter.com/BodhieVik"
            target="__blank"
            class="font-poppins text-md  hover:text-blue-500 "
          >
            Twitter
          </a>
        </div>
      </div>



      <div className="w-full">
        <div className="font-semibold mb-1 text-primary text-center lg:text-left ">
          Contact Us
        </div>
        <div className="grid w-full   grid-cols-2 sm:grid-cols-1 sm:grid-rows-none gap-x-2 gap-y-0 sm:gap-5 md:gap-2  mx-[6%] sm:ml-0 font-medium sm:justify-items-center  lg:justify-items-start     ">
          <a
            href="/contact"
            target="__blank"
            class="font-poppins text-md    hover:text-blue-500"
          >
            Support
          </a>
          <a
            href="/contact"
            target="__blank"
            class="font-poppins text-md   hover:text-blue-500"
          >
            Contact Us
          </a>
          <a
            href="/contact"
            target="__blank"
            class="font-poppins text-md    hover:text-blue-500"
          >
            Partnerships
          </a>
        </div>
      </div>
    </article>
  );
}

export default Links;
