import VedioPlayer from "../Common/VedioPlayer";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import VedioPlayer2 from "../Common/2";
import HomeVideoMob from "./HomeVideoMob";
import HomeVideoTab from "./HomeVideoTab";
import HomeVideoLap from "./HomeVideoLap";

import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

const VideosMid = ({ carousel }) => {
  const [ytlinks, setYtLinks] = useState([]);
  const getLinks = async () => {
    const querySnapshot = await getDocs(collection(db, "ytLinks"));
    const array = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      array.push(data);
    });
    setYtLinks(
      array.sort((a, b) => {
        const numA = parseFloat(a.title);
        const numB = parseFloat(b.title);
        return numA - numB;
      })
    );
  };
  function hvm(link) {
    return (
      <div className="flex items-center justify-center hover:scale-105">
        <HomeVideoMob url={link.yturl} />
      </div>
    );
  }
  useEffect(() => {
    getLinks();
  }, []);

  function hvt(link) {
    return (
      <div className="flex items-center justify-center hover:scale-105">
        <HomeVideoTab url={link.yturl} />
      </div>
    );
  }

  function hvl(link) {
    return (
      <div className="flex items-center justify-center hover:scale-105">
        <HomeVideoLap url={link.yturl} />
      </div>
    );
  }

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1023 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1023, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <article class="  w-full">
      {/* home page mobile  */}
      {carousel === true && (
        <div className="w-full sm:hidden pb-10">
          <Carousel
            responsive={responsive}
            showDots={true}
            autoPlay={true}
            infinite={true}
            swipeable={true}
            draggable={true}
            autoPlaySpeed={10000}
            containerClass="py-10 "
            itemClass="  "
          >
            {ytlinks.map(hvm)}
          </Carousel>
        </div>
      )}

      {/* home page tablet  */}

      {carousel === true && (
        <div class="w-full hidden sm:block md:block lg:hidden ">
          <Carousel
            responsive={responsive}
            showDots={true}
            autoPlay={true}
            infinite={true}
            swipeable={true}
            draggable={true}
            autoPlaySpeed={10000}
            containerClass="py-10 "
            itemClass="  "
          >
            {ytlinks.map(hvt)}
          </Carousel>
        </div>
      )}

      {/* home page laptop  */}

      {carousel === true && (
        <div class="w-full hidden lg:block grid grid-cols-3 flex justify-center ">
          <Carousel
            responsive={responsive}
            showDots={true}
            autoPlay={true}
            infinite={true}
            swipeable={true}
            draggable={true}
            autoPlaySpeed={10000}
            containerClass="py-10 "
            itemClass="  "
          >
            {ytlinks.map(hvl)}
          </Carousel>
        </div>
      )}

      {/* videos page  mobile */}
      {carousel === false && (
        <div class="sm:hidden grid grid-col-1 gap-4 flex-wrap justify-center w-full py-5 ">
          {ytlinks.map(hvm)}
        </div>
      )}

      {/*  video page tablet   */}
      {carousel === false && (
        <div className="      hidden lg:hidden sm:grid sm:grid-cols-2  gap-x-0 gap-4  justify-center   items-center  w-full py-5">
          {ytlinks.map(hvt)}
        </div>
      )}

      {/* videos page laptop */}
      {carousel === false && (
        <div className="hidden lg:grid grid-cols-3 grid-rows-3  gap-y-8  flex-wrap justify-center w-full items-center  py-10">
          {ytlinks.map(hvl)}
        </div>
      )}
    </article>
  );
};

export default VideosMid;
