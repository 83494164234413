import React, { useEffect, useState } from "react";
import LiveDestiny from "./liveDestiny/liveDestiny";
import { useLocation, useParams } from "react-router-dom";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

function CommonEventPage() {
  const [eventData, setEventData] = useState(null);
  const params = useParams();
  const eventSlug = params.eventSlug || "";
  const getEvent = async () => {
    const docRef = doc(db, "events", eventSlug);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setEventData(docSnap.data());
      console.log(docSnap.data(), "k");
    } else {
      console.log("No such document!");
    }
  };
  useEffect(() => {
    console.log(eventSlug, "location");
    getEvent();
  }, []);

  return (
    <div className="">
      <LiveDestiny eventData={eventData}></LiveDestiny>
    </div>
  );
}

export default CommonEventPage;
