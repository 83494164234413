const LiveYourDestinyDesc = () =>{
    return (
        <div>
            <div className="text-2xl sm:text-4xl font-poppins font-bold text-center mb-5">Live your Destiny</div>
          <div className=" text-center lg:text-justify text-base font-medium font-poppins leading-tight  sm:leading-snug lg:leading-normal">
            Design Your Destiny is an all-in-one exotic immersive retreat for
            seekers of personal growth and the power of community. Gathering a
            robust community of like-minded men and women from around the world
            – entrepreneurs, artists, industry professionals and more – Design
            Your Destiny aims to revitalize and reform the way you think and
            behave. The program facilitates as a checkpoint for some and a start
            of a new life for many individuals in the journey of personal
            transformation.
            
          </div>
          <div className="text-2xl sm:text-4xl font-poppins font-bold text-center my-5 ">Understanding Live your Destiny</div>
          <div className=" text-center lg:text-justify text-base font-medium font-poppins leading-tight  sm:leading-snug lg:leading-normal">
            Meticulously curated and led by Bodhie Vik, this retreat aims to put
            you in sync with nature. Disconnected from a fast-moving world,
            among people like yourselves, Design Your Destiny is fertile ground
            for developing friendships and fostering community growth over the
            course of one week while taking part in activities and sessions that
            focus on developing mindfulness and achieving personal breakthroughs
            in your journey of transformation. <br /><br />
            But what does it mean to design your destiny? Bodhie Vik operates on
            three fundamental principles.
          </div>
          

         
        </div>
        
    )
};

export default LiveYourDestinyDesc;