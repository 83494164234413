import React from 'react'

function OvercomeAnxietyVideo() {
  return (
    <div class="video-div">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/YbGQbaSbjf0"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
  )
}

export default OvercomeAnxietyVideo