import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function NavLinks({ name, href }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (location.pathname === href) {
      setActive(true);
    } else setActive(false);
  }, [location]);

  return (
    <div
      className={`nav-link leading-3  cursor-pointer active   sm:font-poppins  hover:text-blue-500 text-md lg:text-lg h-[5px] sm:h-auto font-medium   ${
        active === true ? " text-primary" : "text-[0F131A] "
      } ${
        name === "Contact"
          ? "bg-primary  leading-normal py-1 px-3 rounded-full hover:text-white text-white text-center h-auto"
          : ""
      }`}
      aria-current="page"
      onClick={() => {
        navigate(href);
      }}
    >
      {name}
    </div>
  );
}

export default NavLinks;
