import React from "react";
import VediosMid from "../../VideosPage/VIdeosMid";
import MidVideo from "../../VideosPage/MidVideo";

function VediosYoutube({ carousel }) {
  return (
    <div className="flex flex-col w-full overflow-hidden justify-center items-center min-h-[300px] sm:pb-10">
      <div className="text-2xl sm:text-4xl  font-bold font-poppins mt-2">
        Videos
      </div>

      <div className=" w-full">
        <VediosMid carousel={carousel}></VediosMid>
        {/* <MidVideo carousel={true}/> */}
      </div>
    </div>
  );
}

export default VediosYoutube;
