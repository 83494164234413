import Button from "../../Common/Button";
const AboutText = ({data}) => {
  return (
    <div className="flex-1 flex items-center  text-center md:text-left   md:mt-0 justify-center flex-col py-2">
      <div className="text-4xl px-2 font-poppins font-semibold   ">
        {data.heading}
      </div>
      <div className=" text-base lg:text-lg text-center font-poppins py-2">
        {data.content}
      </div>
      <div className="px-2">
        <Button name={data.buttonText} href={data.route} />
      </div>
    </div>
  );
};

export default AboutText;
