import React, { useEffect, useState } from "react";

import AboutText from "./AboutText";
import AboutImage from "./AboutImage";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase";
import { doc, getDoc } from "firebase/firestore";

function AboutMe() {
  const [data, setData] = useState(null);
  const getAboutMeHomePage = async (docId) => {
    try {
      const docRef = doc(db, "HomePage", docId);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        setData(docSnapshot.data());
        console.log(data, "akakkak");
      } else {
        console.log("Document not found");
      }
    } catch (error) {
      console.log("Error getting document:", error);
    }
  };
  useEffect(() => {
    getAboutMeHomePage("aboutMe");
  }, []);
  return (
    <article className="flex items-center flex-col pt-5 sm:pt-10 text-justify w-full md:px-[5%]" >
      {/* // <div className="container md:m-10 flex md:pr-10 md:py-10 z-100 flex-col-reverse md:flex-row">  */}
      {data ? (<div className="container flex z-100 flex-col-reverse lg:flex-row  md:gap-4 w-full">
        <AboutText data={data} />
        <AboutImage data={data} />
      </div>) : (
          <div>Loading</div>
      )}

      
    </article>
  );
}

export default AboutMe;
