import React from "react";
import "./services.css";
import Slideshow from "./Slideshow";

function Services() {
  return (
    <div>
      <div className="text-center mt-10 mb-2  ">
        <div className="text-2xl sm:text-3xl font-bold font-poppins">
          We make you better with
        </div>
        <div className="text-2xl sm:text-6xl font-bold font-poppins">
          Some of our services
        </div>
      </div>

      <Slideshow />

      
    </div>
  );
}

export default Services;
