import React, { useEffect, useState } from "react";
import { uploader } from "../test";
import { Button, Input, Textarea } from "@material-tailwind/react";
import { db } from "../../../firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { getDocs, collection } from "firebase/firestore";
import { notify } from "../../Common/Notification";

function EditPointsMyStory() {
  const [data, setData] = useState();
  const [index, setIndex] = useState();
  const [points, setPoints] = useState([]);
  const [progress, setProgress] = useState();
  const [image1, setImage1] = useState();
  const [description, setDescription] = useState();
  const [heading, setHeading] = useState();
  const [reload, setReload] = useState(false);

  const getPoints = async () => {
    const docRef = doc(db, "MyStoryPage", "Points");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      console.log(docSnap.data(), "kkk");

      
      if (docSnap.data().points) setPoints(docSnap.data().points.sort((a, b) => {
          const numA = parseFloat(a.index);
          const numB = parseFloat(b.index);
          return numA - numB;
        })
      );
    } else {
      console.log("No such document!");
    }
  };
  useEffect(() => {
    getPoints();
  }, [reload]);

  const handleSubmit = async (data) => {
    const docRef = doc(db, "MyStoryPage", "Points");
    const docData = {
      points: points,
    };
    await setDoc(docRef, docData, { merge: true })
      .then((doc) => {
        setReload((prev) => !prev);
        notify({ type: "success", message: "success" });
      })
      .catch((error) => {
        console.error(error);
      });
    console.log("Document Written with ID : ", docRef.id);
  };

  useEffect(() => {
    getPoints();
  }, [reload]);

  return (
    <div className="h-full bg-gray-200 flex flex-col py-20 sm:py-32 items-center  gap-4 w-full">
      <div className="border p-2 text-xl border-primary rounded-md bg-white">
        Edit Points My Story Page
      </div>
      <div className=" flex flex-col gap-4 items-center justify-center w-full px-10  max-w-md ">
        <div className="flex flex-col gap-4  items-center justify-center  p-2 w-full">
          <Input
            size="lg"
            label="Index"
            value={index}
            type="number"
            onChange={(e) => {
              setHeading(e.target.value);
            }}
            name="index"
          />
          <Input
            size="lg"
            label="Heading"
            value={heading}
            type="text"
            onChange={(e) => {
              setHeading(e.target.value);
            }}
            name="heading"
          />

          <Textarea
            size="lg"
            label="Description"
            value={description}
            type="text"
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            name="description"
          />

          <Input
            size="lg"
            label="Image"
            type="file"
            onChange={(e) => {
              const images = "myStoryImage/points/";
              uploader(e.target.files[0], setImage1, setProgress, images);
            }}
            name="image"
          />

          <div>Image Uploaded {progress} %</div>

          <div>Image Uploaded {progress} %</div>
          <Button
            onClick={() => {
              // console.log(mainHeading, content, heading, index);
              if (heading && description && image1) {
                setPoints((prev) => [
                  ...prev,
                  {
                    heading: heading,
                    descriptioin: description,
                    image1: image1,
                  },
                ]);
                console.log(points);
                setHeading("");
                setDescription("");
                setImage1("");
              } else {
                notify({
                  type: "error",
                  message: "Please fill all the fields",
                });
              }
            }}
          >
            Add Point
          </Button>
        </div>
        <div className="border-2 border-black p-4 ">
          {points && points.length ? (
            <div className="grid grid-cols-3 w-[80vw]  gap-4 p-4 ">
              {points.map((data, index) => {
                return (
                  <div className="flex flex-col gap-2 border-2 border-blue-500 p-4">
                    <div className="font-semibold">Index : {data.index}</div>
                    <div className="flex font-semibold ">Heading : <br></br>{data.heading}</div>
                    <div><span className="font-semibold">Description : </span> <br></br>{data.descriptioin}</div>
                    <img src={data.image1} alt="" />
                    <Button
                      onClick={() => {
                        setPoints((prev) =>
                          prev.filter((item) => item !== data)
                        );
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>

        <div className="my-6">
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleSubmit({
                points: points,
              });
            }}
          >
            Submit{" "}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default EditPointsMyStory;
