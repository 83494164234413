import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  setDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { Button, Input } from "@material-tailwind/react";
import { AiOutlineDelete } from "react-icons/ai";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { notify } from "../Common/Notification";
import ViedoPreview from "./ViedoPreview";

function YtLinksEdit() {
  const [links, setLinks] = useState([]);
  const [yturl, setUrl] = useState();
  const [editLink, setEditLink] = useState(false);
  const [title, setTitle] = useState();
  const [reload, setReload] = useState(false);

  const getLinks = async () => {
    const querySnapshot = await getDocs(collection(db, "ytLinks"));
    const array = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      array.push(data);
      console.log(doc.data(), "doc");
    });
    setLinks(
      array.sort((a, b) => {
        const numA = parseFloat(a.title);
        const numB = parseFloat(b.title);
        return numA - numB;
      })
    );
  };

  useEffect(() => {
    getLinks();
  }, [reload]);

  const deleteLink = async (id) => {
    await deleteDoc(doc(db, "ytLinks", id));
    setReload((prev) => !prev);
  };

  const handleSubmit = async (data) => {
    let id = uuidv4();

    if (data.id) {
      id = data.id;
    }
    // const docRef = doc(db, "ytLinks");
    const docRef = doc(db, "ytLinks", id);
    const docData = {
      yturl: yturl,
      title: title,
      id: id,
    };
    console.log(docData, "doc");
    await setDoc(docRef, docData, { merge: true })
      .then((doc) => {
        setReload((prev) => !prev);
        notify({ type: "success", message: "link Saved" });
        setTitle("");
        setUrl("");
        console.log(doc, "update");
      })
      .catch((error) => {
        console.log(error, "k");
        notify({ type: "error", message: error.message });
      });

    console.log("Document written with ID: ", docRef.id);

    /* const querySnapshot = await getDocs(collection(db, "users"));
    console.log(querySnapshot, "quer");
    querySnapshot.forEach((doc) => {
      console.log(doc, "doc");
      console.log(doc.data(), "doc");
    }); */
  };
  return (
    <div className="w-full flex flex-col py-16 px-4 sm:py-32 sm:px-6 items-center  gap-4 bg-gray-200 ">
      <div className="border p-6 sm:p-8  border-primary">
        <form className="flex flex-col justify-center items-center gap-2 ">
          <div className="font-semibold text-xl">Add Link</div>
          <Input
            size="sm"
            label="Title"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            name="title"
          />
          <Input
            size="sm"
            label="Link"
            value={yturl}
            onChange={(e) => {
              setUrl(e.target.value);
            }}
            name="link"
          />

          <Button
            typ="submit"
            onClick={(e) => {
              e.preventDefault();
              console.log(yturl, title);
              handleSubmit({ yturl: yturl, title: title });
            }}
          >
            Submit
          </Button>
        </form>
      </div>
      <div className="flex flex-col  md:grid md:grid-cols-2  gap-2 ">
        {links &&
          links.length > 0 &&
          links.map((data) => {
            console.log(data);
            return (
              <div
                className="border border-black p-5 flex flex-col relative items-center  "
                key={data.id}
              >
                <div className="text-center w-full">{data.title}</div>

                <div className="">
                  <div className="flex flex-col justify-between gap-3 items-center">
                    {editLink !== data.id ? (
                      <a
                        className="break-words text-center w-full flex justify-center"
                        href={data.yturl}
                      >
                        {data.yturl}
                      </a>
                    ) : (
                      <div className="flex flex-col gap-1 justify-center items-center w-full">
                        <Input
                          size="sm"
                          label="Title"
                          value={title}
                          onChange={(e) => {
                            setTitle(e.target.value);
                          }}
                          name="title"
                        />
                        <Input
                          size="sm"
                          label="Link"
                          value={yturl}
                          onChange={(e) => {
                            setUrl(e.target.value);
                          }}
                          name="link"
                        />
                        <Button
                          typ="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            console.log(yturl, title);
                            handleSubmit({
                              yturl: yturl,
                              title: title,
                              id: data.id,
                            });
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                    )}

                    <div
                      className="text-primary cursor-pointer"
                      onClick={() => {
                        if (editLink !== data.id) {
                          setEditLink(data.id);
                        } else {
                          setEditLink(null);
                        }
                      }}
                    >
                      {editLink === data.id ? "Cancel" : "Edit"}
                    </div>
                  </div>

                  <ViedoPreview url={data.yturl}></ViedoPreview>
                </div>

                <div
                  className="absolute top-0 right-0 cursor-pointer p-2"
                  onClick={() => deleteLink(data.id)}
                >
                  <AiOutlineDelete size={"30"}></AiOutlineDelete>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default YtLinksEdit;
