import React from "react";
import { useNavigate } from "react-router-dom";

function AdminMain() {
  const navigate = useNavigate();
  return (
    <div
      className="w-screen h-screen flex flex-col justify-center items-start  p-20 gap-4 bg-gray-200 
    
    "
    >
      <div className="flex gap-4 ">
        <div className="flex items-center justify-center font-bold w-32 ">
          Super Admin
        </div>
        <div
          className="flex items-center justify-center border border-primary p-3 hover:border-2"
          onClick={() => {
            navigate("/admin/users");
          }}
        >
          Users
        </div>
      </div>

      <div className="flex gap-4   ">
        <div className="flex items-center justify-center font-bold w-32">
          Home Page
        </div>
        <div
          className="flex items-center justify-center border border-primary p-3 hover:border-2"
          onClick={() => {
            navigate("/admin/home/welcome");
          }}
        >
          Edit Welcome Home Page
        </div>
        <div
          className="flex items-center justify-center border border-primary p-3 hover:border-2"
          onClick={() => {
            navigate("/admin/home/about-me");
          }}
        >
          Edit About Me Home Page
        </div>
        <div
          className="flex items-center justify-center border border-primary p-3 hover:border-2"
          onClick={() => {
            navigate("/admin/home/events");
          }}
        >
          Edit Events Home Page
        </div>

        <div
          className="flex items-center justify-center border border-primary p-3 hover:border-2"
          onClick={() => {
            navigate("/admin/testimonials");
          }}
        >
          Edit Testimonial Card
        </div>
        <div
          className="flex items-center justify-center border border-primary p-3 hover:border-2"
          onClick={() => {
            navigate("/admin/home/book-me-for");
          }}
        >
          Edit Book Me For
        </div>

        <div
          className="flex items-center justify-center border border-primary p-3 hover:border-2"
          onClick={() => {
            navigate("/admin/home/social-media");
          }}
        >
          Edit Social Media Links
        </div>
      </div>
      <div className="flex gap-4   ">
        <div className="flex items-center justify-center font-bold w-32">
          Videos Page
        </div>
        <div
          className="flex items-center justify-center border border-primary p-3 hover:border-2 "
          onClick={() => {
            navigate("/admin/videos/banner");
          }}
        >
          Edit Video Page Banner
        </div>
        <div
          className="flex items-center justify-center border border-primary p-3 hover:border-2 "
          onClick={() => {
            navigate("/admin/youtube-links");
          }}
        >
          Edit Youtube Links
        </div>
      </div>

      <div className="flex gap-4   ">
        <div className="flex items-center justify-center font-bold w-32">
          Events Page
        </div>
        <div
          className="flex items-center justify-center border border-primary p-3 hover:border-2"
          onClick={() => {
            navigate("/admin/events/events-main");
          }}
        >
          Events Main Page
        </div>
        <div
          className="flex items-center justify-center border border-primary p-3 hover:border-2"
          onClick={() => {
            navigate("/admin/events/events-front");
          }}
        >
          Events Front Page
        </div>
        <div
          className="flex items-center justify-center border border-primary p-3 hover:border-2"
          onClick={() => {
            navigate("/admin/events/about-coach");
          }}
        >
          Edit About Coach
        </div>
      </div>
      <div className="flex gap-4   ">
        <div className="flex items-center justify-center font-bold w-32">
          My Story Page
        </div>
        <div
          className="flex items-center justify-center border border-primary p-3 hover:border-2"
          onClick={() => {
            navigate("/admin/my-story/banner");
          }}
        >
          Edit Banner My Story Page
        </div>
        <div
          className="flex items-center justify-center border border-primary p-3 hover:border-2"
          onClick={() => {
            navigate("/admin/my-story/about-me");
          }}
        >
          Edit About Me MyStoryPage
        </div>
        <div
          className="flex items-center justify-center border border-primary p-3 hover:border-2"
          onClick={() => {
            navigate("/admin/my-story/who-am-i");
          }}
        >
          Edit Who Am I MyStoryPage
        </div>
        <div
          className="flex items-center justify-center border border-primary p-3 hover:border-2"
          onClick={() => {
            navigate("/admin/my-story/points");
          }}
        >
          Edit Points MyStoryPage
        </div>
        <div
          className="flex items-center justify-center border border-primary p-3 hover:border-2"
          onClick={() => {
            navigate("/admin/my-story/mission");
          }}
        >
          Edit Mission MyStoryPage
        </div>
      </div>
    </div>
  );
}

export default AdminMain;
