const Soul =() =>{
    return (
        <article class="min-h-screen flex items-center flex-col ">
        <div class="container">
            <div className="text-3xl sm:text-4xl  font-bold  font-poppins">Soul</div>
            <div className="text-sm sm:text-base md:text-lg mt-2 font-poppins">Explore your inner world. Reconnect with the essence of you. Access infinite
                inspiration, intuition, peace, and clarity.</div>

                

            <div class="grid grid-cols-1 md:grid-cols-3 gap-5 mt-8">
                <div class="training-card">
                    <img className="rounded-lg" src={require("../../../assets/home/events/lifeCoaching.JPG")} alt=""/>
                    <div className="font-poppins font-semibold text-md md:text-lg text-center sm:text-left ">Life Coaching</div>
                </div>
                <div class="training-card">
                    <img className="rounded-lg" src={require("../../../assets/home/events/selfMastery.jpg")} alt=""/>
                    <div className="font-poppins font-semibold text-md md:text-lg text-center sm:text-left ">Self Mastery</div>
                </div>
                <div class="training-card">
                    <img className="rounded-lg" src={require("../../../assets/home/events/liveYourDestiny.JPG")} alt=""/>
                    <div className="font-poppins font-semibold text-md md:text-lg text-center sm:text-left">Design Your Destiny</div>
                </div>
                <div class="training-card">
                    <img className="rounded-lg" src={require("../../../assets/home/events/highPerformance.jpg")} alt=""/>
                    <div className="font-poppins font-semibold text-md md:text-lg text-center sm:text-left ">High Performance</div>
                </div>
                <div class="training-card">
                    <img className="rounded-lg" src={require("../../../assets/home/events/lifeCoaching.JPG")} alt=""/>
                    <div className="font-poppins font-semibold text-md md:text-lg text-center sm:text-left ">Life Coaching</div>
                </div>
                <div class="training-card">
                    <img className="rounded-lg" src={require("../../../assets/home/events/selfMastery.jpg")} alt=""/>
                    <div className="font-poppins font-semibold text-md md:text-lg text-center sm:text-left ">Self Mastery</div>
                </div>
                
                
            </div>
        </div>
    </article>

    )
};

export default Soul;