import React, { useEffect, useState } from "react";
import ReachOut from "../../Components/Footer/ReachOut";
import Links from "../../Components/Footer/Links";
import Foot from "../../Components/Footer/Foot";
import "./mystory.css";

import AOS from "aos";
import "aos/dist/aos.css";
import MyStoryAnimation from "../../Components/MyStory/MyStoryAnimation";
import WhoAmI from "../../Components/MyStory/WhoAmI";
import WhoWasI from "../../Components/MyStory/WhoWasI";
import StoryTop from "../../Components/MyStory/StoryTop";

import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import MyStoryPoint from "../../Components/MyStory/MyStoryPoint";

function MyStoryPage() {


  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="overflow-x-hidden max-w-[100%] text-justify">
      <StoryTop />
      <WhoWasI />

      <article class="style-h1 min-h-screen flex items-center  flex-col gap-12 sm:gap-20 lg:px-0 py-10">
        
        <MyStoryPoint />
      </article>

      <WhoAmI />
      <MyStoryAnimation></MyStoryAnimation>

      <div>
        <ReachOut />
        <Links />
        <Foot />
      </div>
    </div>
  );
}

export default MyStoryPage;
