import React from 'react'

function YogaExplore() {
  return (
    <div class="explore">
    <div className="explore-title"><h3>Explore More</h3></div>
    <div class="categories">
      <a
        href="https://www.youtube.com/playlist?list=PL7LUnQUF-8uvR-ESNq02wJ6ehPtow24fz
            "
        target="_blank"
        rel="noopener noreferrer"
        class="exp-btn"
        >Yoga</a>
      
    </div>
  </div>
  )
}

export default YogaExplore